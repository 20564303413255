<template>
	<div class="h-100 p-relative">
		<div class="qf-search-filters px-2 pt-1 pb-2">
			<div class="card m-0">
				<validation-observer :ref="`locationValidation`+locationId">
					<b-form-group label="Sides" class="pb-1">
						<label for="attachment">File (.doc, .pdf)</label>
						<validation-provider
							v-slot="{ errors }"
							name="attachment"
							rules="required"
						>
							<b-form-file
								v-model="form.attachment"
								id="attachment"
								accept=".doc, .pdf"
								:state="errors.length > 0 ? false:null"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
				</validation-observer>

				<b-button variant="primary" class="mb-1" @click="submitFile()">SAVE FILE</b-button>
				<b-button
					variant="outline-primary"
					class="mb-1"
					@click="cancelModal()"
				>CANCEL</b-button>

			</div>
		</div>

	</div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import {required,} from '@validations';
import {
	BFormGroup,
	BButton,
	BFormFile,
} from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Parse from 'parse';
import store from "@/store";
export default {
	name: "ModalAddSides",
	components: {
		BFormGroup,
		BButton,
		BFormFile,
		ValidationProvider,
		ValidationObserver,
	},
	data() {
		return {
			required,
            locationId: 0,
			form: {
				attachment: null,
			},
		}
	},

	mounted() {},
	computed: {
		currentRole() {
			return store.getters['app/currentRole']
		}
	},
	methods: {

		cancelModal(){
			if(this.fileId){
				this.$root.$emit('bv::toggle::collapse', 'cu-addEditSides');
			}else{
				this.$root.$emit('bv::toggle::collapse', 'cu-addEditSides');
			}
		},
		submitFile(){
			let self = this;
			this.$refs[`locationValidation` + this.locationId].validate().then(success => {
				if (success) {
					console.log('success');
					self.saveFile();
				}
			}).catch(function (err) {
				self.$toast({
					component: ToastificationContent,
					props: {
						title: 'Check all fields for valid data!',
						icon: 'EditIcon',
						variant: 'danger',
					},
				});
				console.log('validation errors', err);
			});
		},
		saveFile(){
			let self = this;
			let mediaObject = new Parse.Object.extend('Media');
			let media = new mediaObject();
			const rolePointer = {"__type": "Pointer", "className": "ActingRole", "objectId": this.currentRole.id};
			media.set('relatedRole', rolePointer);

			if(this.form.attachment){
				let sideFile = this.form.attachment;
        let nameStart = sideFile.name;

        const nameRegex = /[\][()!&$^#%{}*?<>:|"'\\]/gm;
        let name = nameStart.replaceAll(nameRegex, '_');

        let parseFile = new Parse.File(name, sideFile, sideFile.type);
        media.set('attachment', parseFile)
			}

			media.set('mediaType', 'side');

			media.save().then(function (newMedia) {
				console.log("Side saved: ", newMedia);
				store.commit('app/ADD_SIDE', newMedia);

				self.$toast({
					component: ToastificationContent,
					props: {
						title: 'Side saved!',
						icon: 'EditIcon',
						variant: 'success',
					},
				});
				self.cancelModal();
				self.form.attachment = null;
			}).catch(function (err) {
				self.$toast({
					component: ToastificationContent,
					props: {
						title: 'Check the file name for invalid characters!',
						icon: 'EditIcon',
						variant: 'danger',
					},
				});
				console.log('Validation errors', err);
			});

		},
	},
}
</script>

<style scoped>

</style>
