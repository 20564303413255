<template>
    <div class="h-100 p-relative">
        <div class="qf-search-filters">
            <div class="card px-2 m-0">
                <label class="pt-1">Find Google Location</label>
                <vue-google-autocomplete
                        id="map"
                        ref="googleAddress"
                        classname="form-control"
                        placeholder="Please type your address"
                        v-on:placechanged="getAddressData"
                        v-model="googleDefault"
                        types="geocode"
                >
                </vue-google-autocomplete>
                <validation-observer :ref="`locationValidation`+locationId">
                    
                    <b-form-group class="pb-1">
                        <b-row>
                            <b-col>
                                <label for="country" class="pt-1">Country <sup class="text-danger">*</sup></label>
                                <validation-provider
                                        v-slot="{ errors }"
                                        name="country"
                                        rules="required"
                                >
                                    <b-form-input name="country"
                                                  id="country"
                                                  v-model="form.country"
                                                  :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-col>
                            <b-col>
                                <label for="city" class="pt-1">City <sup class="text-danger">*</sup></label>
                                <validation-provider
                                        v-slot="{ errors }"
                                        name="city"
                                        rules="required"
                                >
                                    <b-form-input name="city"
                                                  id="city"
                                                  v-model="form.city"
                                                  :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-col>
                        </b-row>
                        <div>
                            <label for="state" class="pt-1">State</label>
                            <b-form-input name="state"
                                          id="state"
                                          v-model="form.state"
                            />
                        </div>
                        <div>
                            <label for="address" class="pt-1">Place / Address</label>
                            <b-form-input name="address"
                                          id="address"
                                          v-model="form.address"
                            />
                        </div>
                        <b-row>
                            <b-col>
                                <label for="Longitude" class="pt-1">Longitude</label>
                                <b-form-input name="Longitude"
                                              id="Longitude"
                                              v-model="form.longitude"
                                />
                            </b-col>
                            <b-col>
                                <label for="latitude" class="pt-1">Latitude</label>
                                <b-form-input name="Latitude"
                                              id="latitude"
                                              v-model="form.latitude"
                                />
                            </b-col>
                        </b-row>
                    </b-form-group>
                    
                    <b-form-group label="Date" class="pb-1">
                        <b-row>
                            <b-col>
                                <label for="startDate" class="pt-1">Start Date</label>
                                <div class="d-flex justify-content-lg-between align-items-center">
                                    <b-form-datepicker
                                            id="startDate"
                                            name="startDate"
                                            v-model="form.startDate"
                                            :min="new Date()"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            start-weekday="1"
                                    />
                                </div>
                            </b-col>
                            <b-col>
                                <label for="endDate" class="pt-1">End Date</label>
                                <div class="d-flex justify-content-lg-between align-items-center">
                                    <b-form-datepicker
                                            id="endDate"
                                            name="endDate"
                                            v-model="form.endDate"
                                            :min="form.startDate"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            start-weekday="1"
                                    />
                                </div>
                            </b-col>
                        </b-row>
                    </b-form-group>
                
                </validation-observer>
                
                <b-button variant="primary" class="mb-1" @click="submitLocation()">{{ saveLabel }}</b-button>
                <b-button
                        variant="outline-primary"
                        class="mb-1"
                        @click="cancelModal()"
                >CANCEL
                </b-button>
            
            </div>
        </div>
    </div>
</template>

<script>
    import {ValidationProvider, ValidationObserver} from 'vee-validate';
    import {required,} from '@validations';
    import VueGoogleAutocomplete from 'vue-google-autocomplete';
    import {
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BButton,
        BFormDatepicker,
    } from 'bootstrap-vue'
    import ToastificationContent from "@core/components/toastification/ToastificationContent";
    import Parse from 'parse';
    import store from "@/store";

    export default {
        name: "ModalAddEditShootingLocation",
        components: {
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BFormDatepicker,
            BButton,
            ValidationProvider,
            ValidationObserver,
            VueGoogleAutocomplete,
        },
        props: {
            locationToEdit: {
                type: Object,
                required: false,
                default: function () {
                    return {}
                }
            }
        },
        data() {
            return {
                googleDefault: '',
                googleAddress: '',
                required,
                locationId: 0,
                form: {
                    country: null,
                    city: null,
                    state: null,
                    address: null,
                    startDate: null,
                    endDate: null,
                    longitude: null,
                    latitude: null
                },
            }
        },

        mounted() {
            if(this.locationToEdit && this.locationToEdit.attributes){
                this.form = JSON.parse(JSON.stringify(this.locationToEdit.attributes));
                this.locationId = this.locationToEdit.id;
                console.log("Location to edit: ", this.locationToEdit);
            }
        },
        computed: {
            saveLabel() {
                if (this.locationToEdit.id) {
                    return "SAVE CHANGES";
                } else {
                    return "CREATE LOCATION"
                }
            },
            currentRole() {
                return store.getters['app/currentRole']
            }
        },
        methods: {
            getAddressData(addressData) {
                if(addressData && addressData.administrative_area_level_1){
                    this.form.state = addressData.administrative_area_level_1;
                }
                if(addressData.country){
                    this.form.country = addressData.country;
                }
                if(addressData.locality){
                    this.form.city = addressData.locality;
                }
                if(addressData.route){
                    this.form.address = addressData.route;
                }
                if(addressData.longitude){
                    this.form.longitude = addressData.longitude;
                }
                if(addressData.latitude){
                    this.form.latitude = addressData.latitude;
                }
            },
            cancelModal() {
                this.$root.$emit('bv::toggle::collapse', 'cu-addEditShootingLocation');
            },
            submitLocation() {
                let self = this;
                this.$refs[`locationValidation` + this.locationId].validate().then(success => {
                    if (success) {
                        console.log('success');
                        self.saveLocation();
                    }
                }).catch(function (err) {
                    self.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Check all fields for valid data!',
                            icon: 'EditIcon',
                            variant: 'danger',
                        },
                    });
                    console.log('validation errors', err);
                });
            },
            saveLocation() {
                let self = this;
                let locObject = new Parse.Object.extend('ShootingLocation');
                let location = new locObject();
                const rolePointer = {"__type": "Pointer", "className": "ActingRole", "objectId": this.currentRole.id};

                if (this.locationId) {
                    location.set('objectId', this.locationId);
                }
                
                let geoCoordinates = new Parse.GeoPoint(this.form.latitude, this.form.longitude);
                
                location.set('address', this.form.address);
                location.set('city', this.form.city);
                location.set('state', this.form.state);
                location.set('country', this.form.country);
                location.set('startDate', new Date(this.form.startDate));
                location.set('endDate', new Date(this.form.endDate));
                location.set('coordinates', geoCoordinates);
                location.set('relatedRole', rolePointer);

                location.save().then(function (newLocation) {
                        console.log("Location saved: ", newLocation);
                        if (!self.locationToEdit.id) {
                            store.commit('app/ADD_LOCATION', newLocation);
                            store.commit('app/UPDATE_LOCATIONS', newLocation);
                        }
                        else {
                            store.commit('app/UPDATE_LOCATIONS', newLocation);
                        }
                        self.cancelModal();
                        self.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Shooting location saved!',
                                icon: 'EditIcon',
                                variant: 'success',
                            },
                        });
                    }
                ).catch(function (err) {
                    self.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Check all fields for valid data!',
                            icon: 'EditIcon',
                            variant: 'danger',
                        },
                    });
                    console.log('Validation errors', err);
                });

            },
        },
        watch: {
            locationToEdit: {
                immediate: true,
                handler(newLocationToEdit){
                    if (newLocationToEdit && newLocationToEdit.attributes) {
                        this.locationId = newLocationToEdit.id;
                        this.form = JSON.parse(JSON.stringify(newLocationToEdit.attributes));
                    }else{
                        this.locationId = null;
                        this.form = {
                            country: null,
                            city: null,
                            state: null,
                            address: null,
                            startDate: null,
                            endDate: null,
                        }
                    }
                }
            },
        }
    }
</script>

<style>

</style>
