<template>
    <div>
        <b-row>
            <b-col md="12">
                <!--          Dessy Character Details-->
                <b-tabs v-model="tabIndex" nav-class="#nav-right" nav-wrapper-class="nav-horizontal"
                        v-on:activate-tab="checkDisabled">

                    <b-tab title="Character Details" @click="changeTab('details')">
                        <b-row>
                            <b-col lg="6" md="12">
                                <div
                                    class="d-flex justify-content-between align-items-center mb-1"
                                >
                                    <h3 class="mb-0">Character</h3>
                                    <feather-icon
                                        v-b-toggle.cu-singleRole
                                        class="align-text-center"
                                        data-cy="editRoleIcon"
                                        icon="EditIcon"
                                        size="20"
                                    />
                                    <b-sidebar
                                        id="cu-singleRole"
                                        backdrop
                                        bg-variant="white"
                                        right
                                        shadow
                                        sidebar-class="sidebar-lg"
                                        width="508px"
                                    >
                                        <template #header="{ hide }">
                                            <div
                                                class="
                                                      d-flex
                                                      justify-content-between
                                                      align-items-center
                                                      content-sidebar-header
                                                      w-100
                                                    "
                                            >
                                                <h5 class="mb-0">Role Basics</h5>
                                                <div>
                                                    <feather-icon
                                                        class="ml-1 cursor-pointer"
                                                        icon="XIcon"
                                                        size="16"
                                                        @click="hide"
                                                    />
                                                </div>
                                            </div>
                                        </template>
                                        <modal-edit-role></modal-edit-role>
                                    </b-sidebar>
                                </div>
                                <b-card class="overflow-hidden p-2" no-body>
                                    <b-row>
                                        <b-col md="3">
                                            <b-card-img
                                                :src="curRoleIllustration"
                                                alt="Image"
                                                class="rounded-0"
                                            ></b-card-img>

                                            <div class="cs-pro-badge" v-if="activeRole.attributes.proCasting">
                                                <b-badge v-b-tooltip="`Casting is restricted to Cineself Pro Talent`" variant="gradient-red">PRO</b-badge>
                                            </div>
                                        </b-col>
                                        <b-col md="9">
                                            <b-row no-gutters>
                                                <b-col class="mb-2" md="12">
                                                    <h4 class="mb-0">{{ activeRole.attributes.name }}</h4>
                                                    <h6 class="mb-2">
                                                        {{ roleTypeLabel(activeRole.attributes.roleType) }}
                                                    </h6>
                                                    <p v-if="activeRole.attributes.castBefore">
                                                        <strong>CAST BEFORE: </strong>{{
                                                            niceDate(activeRole.attributes.castBefore)
                                                        }}
                                                    </p>
                                                    <p>{{ activeRole.attributes.synopsis }}</p>
                                                </b-col>
                                                <b-col md="12">
                                                    <h4 class="mb-2">Role requirements:</h4>
                                                    <div v-if="!hasAppearanceReqs && !hasSkillReqs">
                                                        No requirements for this role have been set yet.
                                                        <span v-b-toggle.cu-singleRole class="text-primary"
                                                        >Click here to define them.</span
                                                        >
                                                    </div>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col v-if="hasAppearanceReqs" md="12">
                                                    <h5>Appearance</h5>
                                                    <hr class="qf-separator mb-0"/>
                                                </b-col>
                                                <b-col
                                                    v-if="activeRole && activeRole.attributes.ethnicity"
                                                    class="pt-1"
                                                    md="6"
                                                >
                                                    <h6>Ethnicity</h6>
                                                    <span>{{
                                                            dictLabel(
                                                                activeRole.attributes.ethnicity,
                                                                "ethnicities"
                                                            )
                                                        }}</span>
                                                </b-col>
                                                <b-col
                                                    v-if="activeRole && activeRole.attributes.bodyType"
                                                    class="pt-1"
                                                    md="6"
                                                >
                                                    <h6>Body Type</h6>
                                                    <span>{{
                                                            dictLabel(
                                                                activeRole.attributes.bodyType,
                                                                "bodyTypes"
                                                            )
                                                        }}</span>
                                                </b-col>
                                                <b-col
                                                    v-if="activeRole && activeRole.attributes.eyeColor"
                                                    class="pt-1"
                                                    md="6"
                                                >
                                                    <h6>Eye Color</h6>
                                                    <span>{{
                                                            dictLabel(
                                                                activeRole.attributes.eyeColor,
                                                                "eyeColors"
                                                            )
                                                        }}</span>
                                                </b-col>
                                                <b-col
                                                    v-if="activeRole && activeRole.attributes.hairColor"
                                                    class="pt-1"
                                                    md="6"
                                                >
                                                    <h6>Hair Color</h6>
                                                    <span>{{
                                                            dictLabel(
                                                                activeRole.attributes.hairColor,
                                                                "hairColors"
                                                            )
                                                        }}</span>
                                                </b-col>
                                                <b-col
                                                    v-if=" activeRole && (activeRole.attributes.ageMin || activeRole.attributes.ageMax)"
                                                    class="pt-1"
                                                    md="6"
                                                >
                                                    <h6>Age Range</h6>
                                                    <span>{{ activeRole.attributes.ageMin }} - {{ activeRole.attributes.ageMax }} yrs</span>
                                                </b-col>
                                                <b-col
                                                    v-if="activeRole && (activeRole.attributes.heightMax ||activeRole.attributes.heightMin)"
                                                    class="pt-1"
                                                    md="6"
                                                >
                                                    <h6>Height range</h6>
                                                    <span>{{ activeRole.attributes.heightMin }} - {{ activeRole.attributes.heightMax }} cm</span>
                                                </b-col>
                                                <b-col v-if="hasSkillReqs" class="pt-1" md="12">
                                                    <h5>Skills and Languages</h5>
                                                    <hr class="qf-separator mb-0"/>
                                                </b-col>
                                                <b-col
                                                    v-if="activeRole.attributes.languages && activeRole.attributes.languages.length"
                                                    class="pt-1"
                                                    md="12"
                                                >
                                                    <h6>Languages</h6>
                                                    <b-badge
                                                        v-for="(lang, index) in activeRole.attributes
                              .languages"
                                                        :key="index"
                                                        class="mr-1"
                                                        variant="secondary"
                                                    >{{ dictLabel(lang, "languages") }}
                                                    </b-badge>
                                                </b-col>
                                                <b-col
                                                    v-if="
                                                        activeRole.attributes.skills &&
                                                        activeRole.attributes.skills.length
                                                      "
                                                    class="pt-1"
                                                    md="12"
                                                >
                                                    <h6>Skills</h6>
                                                    <b-badge
                                                        v-for="(skill, index) in activeRole.attributes.skills"
                                                        :key="index"
                                                        class="mr-1"
                                                        variant="secondary"
                                                    >{{ dictLabel(skill, "skills") }}
                                                    </b-badge>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                </b-card>
                            </b-col>
                            <b-col lg="6" md="12">
                                <b-row>
                                    <!-- WORK DAYS -->
                                    <b-col md="12">
                                        <div class="d-flex
                                                 justify-content-between
                                                 align-items-center
                                                 mb-1">
                                            <h3 class="mb-0">Day out of Days <span v-if="countDays"> ({{countDays}})</span></h3>
                                            <feather-icon
                                                v-b-toggle.cu-addDates
                                                class="align-text-center"
                                                icon="EditIcon"
                                                size="20"
                                            />
                                            <b-sidebar
                                                id="cu-addDates"
                                                backdrop
                                                bg-variant="white"
                                                right
                                                shadow
                                                sidebar-class="sidebar-lg"
                                                width="508px"
                                            >
                                                <template #header="{ hide }">
                                                    <div
                                                        class="
                                                                d-flex
                                                                justify-content-between
                                                                align-items-center
                                                                content-sidebar-header
                                                                w-100
                                                              "
                                                    >
                                                        <h5 class="mb-0">Add Working Dates</h5>
                                                        <div>
                                                            <feather-icon
                                                                class="ml-1 cursor-pointer"
                                                                icon="XIcon"
                                                                size="16"
                                                                @click="hide"
                                                            />
                                                        </div>
                                                    </div>
                                                </template>
                                                <modal-add-dates
                                                    :role-to-edit="curRole"
                                                    @reload="reloadRole"
                                                ></modal-add-dates>
                                            </b-sidebar>
                                        </div>
                                        <b-card class="d-flex p-2" no-body>

                                            <div v-if="curRole && (curRole.attributes.workDays || curRole.attributes.startWork || curRole.attributes.endWork)">
                                                <div v-if="curRole && curRole.attributes.startWork && !sameDay"
                                                     class="d-flex align-items-center qf-sides"
                                                >
                                                    <div>
                                                        <feather-icon
                                                            class="mr-1"
                                                            icon="SunriseIcon"
                                                            size="16"
                                                        />
                                                        {{ niceDate(curRole.attributes.startWork) }}
                                                    </div>

                                                    <strong class="ml-1"> Start Work</strong>
                                                </div>
                                                <div
                                                    v-for="oneDate in curRole.attributes.workDays"
                                                    :key="oneDate"
                                                    class="d-flex align-items-lelft qf-sides"
                                                >
                                                    <div>
                                                        <feather-icon
                                                            class="mr-1"
                                                            icon="BriefcaseIcon"
                                                            size="16"
                                                        />
                                                        {{ niceDate(oneDate) }}
                                                    </div>
                                                    <span class="ml-1"> Work Day </span>
                                                </div>
                                                <div v-if="curRole && curRole.attributes.endWork && !sameDay"
                                                     class="d-flex align-items-left qf-sides"
                                                >
                                                    <div>
                                                        <feather-icon
                                                            class="mr-1"
                                                            icon="MoonIcon"
                                                            size="16"
                                                        />
                                                        {{ niceDate(curRole.attributes.endWork) }}
                                                    </div>

                                                    <strong class="ml-1"> Work Finish</strong>
                                                </div>

                                                <div v-if="sameDay"
                                                     class="d-flex align-items-left qf-sides"
                                                >
                                                    <div>
                                                        <feather-icon
                                                            class="mr-1"
                                                            icon="SunIcon"
                                                            size="16"
                                                        />
                                                        {{ niceDate(curRole.attributes.startWork) }}
                                                    </div>

                                                    <strong class="ml-1"> Start & Work Finish (SWF)</strong>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <div
                                                    class="d-flex justify-content-between align-items-center qf-sides"
                                                >
                                                    Configure working dates
                                                </div>
                                            </div>
                                        </b-card>
                                    </b-col>

                                    <!-- SIDES -->
                                    <b-col md="12">
                                        <div
                                            class="
                                                d-flex
                                                justify-content-between
                                                align-items-center
                                                mb-1
                                              "
                                        >
                                            <h3 class="mb-0">Sides</h3>
                                            <feather-icon
                                                v-b-toggle.cu-addEditSides
                                                class="align-text-center"
                                                icon="EditIcon"
                                                size="20"
                                            />
                                            <b-sidebar
                                                id="cu-addEditSides"
                                                backdrop
                                                bg-variant="white"
                                                right
                                                shadow
                                                sidebar-class="sidebar-lg"
                                                width="508px"
                                            >
                                                <template #header="{ hide }">
                                                    <div
                                                        class="
                                                              d-flex
                                                              justify-content-between
                                                              align-items-center
                                                              content-sidebar-header
                                                              w-100
                                                            "
                                                    >
                                                        <h5 class="mb-0">Add Sides</h5>
                                                        <div>
                                                            <feather-icon
                                                                class="ml-1 cursor-pointer"
                                                                icon="XIcon"
                                                                size="16"
                                                                @click="hide"
                                                            />
                                                        </div>
                                                    </div>
                                                </template>
                                                <modal-add-sides></modal-add-sides>
                                            </b-sidebar>
                                        </div>
                                        <b-card class="d-flex p-2" no-body>
                                            <div v-if="sides.length">
                                                <div
                                                    v-for="oneSide in sides"
                                                    :key="oneSide.id"
                                                    class="
                                                        d-flex
                                                        justify-content-between
                                                        align-items-center
                                                        qf-sides
                                                      "
                                                >
                                                    <div>
                                                        <feather-icon
                                                            class="mr-1"
                                                            icon="FileIcon"
                                                            size="16"
                                                        />
                                                        <a
                                                            :href="oneSide.attributes.attachment._url"
                                                            target="_blank"
                                                        >{{
                                                                niceFileName(
                                                                    oneSide.attributes.attachment._name
                                                                )
                                                            }}</a
                                                        >
                                                    </div>
                                                    <b-dropdown
                                                        class="dropdown-icon-wrapper"
                                                        right
                                                        size="sm"
                                                        variant="flat-primary"
                                                    >
                                                        <template #button-content>
                                                            <feather-icon
                                                                class="text-success"
                                                                icon="MoreVerticalIcon"
                                                                size="18"
                                                            />
                                                        </template>
                                                        <b-dropdown-item
                                                            v-b-modal.delete-modal
                                                            variant="outline-outline-{success}"
                                                            @click="openModal(oneSide)"
                                                        >
                                                            Delete Side
                                                        </b-dropdown-item>
                                                    </b-dropdown>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <div
                                                    class="
                            d-flex
                            justify-content-between
                            align-items-center
                            qf-sides
                          "
                                                >
                                                    Upload sides for this role to allow talent auditions
                                                </div>
                                            </div>
                                        </b-card>
                                    </b-col>

                                    <!-- Casting Location -->
                                    <b-col>
                                        <div
                                            class="
                                                d-flex
                                                justify-content-between
                                                align-items-center
                                                mb-1
                                              "
                                        >
                                            <h3 class="mb-0">Casting Location</h3>
                                            <feather-icon
                                                v-b-toggle.cu-editCastingLocation
                                                class="align-text-center"
                                                icon="EditIcon"
                                                size="20"
                                                @click="setCastingLocationToEdit({})"
                                            />
                                        </div>
                                        <b-card
                                            v-if="activeRole.attributes.castingLocation"
                                            class="p-2"
                                            no-body
                                        >
                                            <div
                                                v-if="activeRole.attributes.castingLocation"
                                                class="d-flex justify-content-between shooting-location"
                                            >
                                                <div class="d-flex">
                                                    <feather-icon
                                                        class="mr-1"
                                                        icon="MapPinIcon"
                                                        size="16"
                                                    />
                                                    <div>
                                                        {{
                                                            activeRole.attributes.castingLocation.attributes
                                                                .address
                                                        }}<br/>{{
                                                            activeRole.attributes.castingLocation.attributes
                                                                .city
                                                        }},
                                                        {{
                                                            activeRole.attributes.castingLocation.attributes
                                                                .country
                                                        }}
                                                    </div>
                                                </div>
                                                <div class="d-flex">
                                                    <div v-if="activeRole.attributes.castingLocation">
                                                        <div>
                                                            {{
                                                                niceDate(
                                                                    activeRole.attributes.castingLocation
                                                                        .attributes.startDate
                                                                )
                                                            }}
                                                            -
                                                            {{
                                                                niceDate(
                                                                    activeRole.attributes.castingLocation
                                                                        .attributes.endDate
                                                                )
                                                            }}
                                                        </div>
                                                        <div
                                                            v-if="
                                activeRole.attributes.castingLocation.attributes
                                  .coordinates
                              "
                                                            class="text-right"
                                                        >
                                                            <a
                                                                :href="
                                                                  mapLink(
                                                                    activeRole.attributes.castingLocation
                                                                      .attributes.coordinates._latitude,
                                                                    activeRole.attributes.castingLocation
                                                                      .attributes.coordinates._longitude
                                                                  )
                                                                "
                                                                target="_blank"
                                                            >Open in Map</a
                                                            >
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-card>
                                        <b-card v-else>
                                            <div>
                                                <p>
                                                    <strong>CAST BEFORE: </strong>
                                                    {{ niceDate(activeRole.attributes.castBefore) }}
                                                </p>
                                            </div>
                                            <div
                                                class="d-flex justify-content-between shooting-location"
                                            >
                                                <p>
                                                    No Casting Locations set.
                                                    <a
                                                        v-b-toggle.cu-editCastingLocation
                                                        @click="setCastingLocationToEdit({})"
                                                    >Click here to Setup Now!</a
                                                    >
                                                </p>
                                            </div>
                                        </b-card>
                                    </b-col>
                                    <!-- Shooting Locations -->
                                    <b-col md="12">
                                        <div
                                            class="
                                            d-flex
                                            justify-content-between
                                            align-items-center
                                            mb-1
                                          "
                                        >
                                            <h3 class="mb-0">Shooting Locations</h3>
                                            <feather-icon
                                                v-b-toggle.cu-addEditShootingLocation
                                                class="align-text-center"
                                                icon="EditIcon"
                                                size="20"
                                                @click="setLocationToEdit({})"
                                            />
                                        </div>
                                        <b-card
                                            v-if="shootingLocations.length > 0"
                                            class="p-2"
                                            no-body
                                        >
                                            <div
                                                v-for="oneLocation in shootingLocations"
                                                :key="oneLocation.id"
                                                class="d-flex justify-content-between shooting-location"
                                            >
                                                <div class="d-flex">
                                                    <feather-icon
                                                        class="mr-1"
                                                        icon="MapPinIcon"
                                                        size="16"
                                                    />
                                                    <div>
                                                        {{ oneLocation.attributes.address }}<br/>{{
                                                            oneLocation.attributes.city
                                                        }}, {{ oneLocation.attributes.country }}
                                                    </div>
                                                </div>
                                                <div class="d-flex">
                                                    <div>
                                                        <div>
                                                            {{ niceDate(oneLocation.attributes.startDate) }} -
                                                            {{ niceDate(oneLocation.attributes.endDate) }}
                                                        </div>
                                                        <div
                                                            v-if="oneLocation.attributes.coordinates"
                                                            class="text-right"
                                                        >
                                                            <a
                                                                :href="
                                                                  mapLink(
                                                                    oneLocation.attributes.coordinates
                                                                      ._latitude,
                                                                    oneLocation.attributes.coordinates
                                                                      ._longitude
                                                                  )
                                                                "
                                                                target="_blank"
                                                            >Open in Map</a
                                                            >
                                                        </div>
                                                    </div>
                                                    <div class="ml-1">
                                                        <b-dropdown
                                                            class="dropdown-icon-wrapper"
                                                            right
                                                            size="sm"
                                                            variant="flat-primary"
                                                        >
                                                            <template #button-content>
                                                                <feather-icon
                                                                    class="text-success"
                                                                    icon="MoreVerticalIcon"
                                                                    size="18"
                                                                />
                                                            </template>
                                                            <b-dropdown-item
                                                                v-b-toggle.cu-addEditShootingLocation
                                                                variant="outline-outline-{success}"
                                                                @click="setLocationToEdit(oneLocation)"
                                                            >
                                                                Edit Location
                                                            </b-dropdown-item>
                                                            <b-dropdown-item
                                                                v-b-modal.delete-location-modal
                                                                variant="outline-outline-{success}"
                                                                @click="openLocationModal(oneLocation)"
                                                            >
                                                                Delete Location
                                                            </b-dropdown-item>
                                                        </b-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-card>
                                        <b-card v-else class="p-2" no-body>
                                            <div
                                                class="d-flex justify-content-between shooting-location"
                                            >
                                                <p>
                                                    No Shooting Locations set.
                                                    <a
                                                        v-b-toggle.cu-addEditShootingLocation
                                                        @click="setLocationToEdit({})"
                                                    >Click here to Setup Now!</a
                                                    >
                                                </p>
                                            </div>
                                        </b-card>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>

                        <!-- START MODALS -->
                        <!-- DELETE ROLE MODAL -->
                        <b-modal
                            id="delete-modal"
                            button-size="sm"
                            cancel-title="Close"
                            cancel-variant="outline-secondary"
                            centered
                            modal-class="modal-danger"
                            ok-title="Delete"
                            ok-variant="danger"
                            size="sm"
                            title="Delete this Member"
                            @ok="deleteSide(sidesToDelete)"
                        >
                            <b-card-text>
                                Are you sure you want to delete
                                {{ niceFileName(sidesToDelete.attributes.attachment._name) }}?
                            </b-card-text>
                        </b-modal>

                        <!-- DELETE LOCATION MODAL -->
                        <b-modal
                            id="delete-location-modal"
                            button-size="sm"
                            cancel-title="Close"
                            cancel-variant="outline-secondary"
                            centered
                            modal-class="modal-danger"
                            ok-title="Delete"
                            ok-variant="danger"
                            title="Delete this Member"
                            @ok="deleteLocation(locationToDelete)"
                        >
                            <b-card-text>
                                Are you sure you want to delete this location:
                                {{ locationToDelete.attributes.address }} in
                                {{ locationToDelete.attributes.city }},
                                {{ locationToDelete.attributes.country }}?
                            </b-card-text>
                        </b-modal>
                    </b-tab>
                    <!--    END TAB : CHARACTER DETAILS-->

                    <!--    START TAB : TALENT -->
                    <b-tab :title-link-class="checkCompany ? 'myDisabled' : ''" title="Browse Talent "
                           @click="changeTab('browse-talents')">
                        <b-row>
                            <b-col class="justify-content-end align-items-center d-flex">
                                <b-button class="float-right" v-b-modal.modal-invite-talent data-cy="addCompanyButton" variant="primary" size="sm">
                                    <feather-icon icon="PlusIcon" />
                                    INVITE TALENT
                                </b-button>
                            </b-col>

                        </b-row>
                        <b-row>
                            <b-col class="d-flex" cols="3">
                                <b-button
                                    v-b-toggle.searchTalent
                                    class="d-flex align-items-center"
                                    size="sm"
                                    variant="flat"
                                >
                                    Detailed filter
                                    <feather-icon
                                        class="ml-1 text-primary cursor-pointer"
                                        icon="FilterIcon"
                                        size="22"
                                    />
                                </b-button>
                            </b-col>
                            <b-col class="my-1" cols="7">
                                <b-form-group class="mb-0">
                                    <b-input-group size="sm">
                                        <b-form-input
                                            id="filterInput"
                                            v-model="filter"
                                            placeholder="Type to Search"
                                            type="search"
                                        />
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">
                                                Clear
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col
                                class="my-1 justify-content-end align-items-center"
                                cols="2"
                            >
                                <b-form-group class="mb-0">
                                    <b-form-select
                                        id="perPageSelect"
                                        v-model="perPage"
                                        :options="pageOptions"
                                        size="sm"
                                    ></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12">
                                <b-table
                                    :current-page="currentPage"
                                    :fields="fields"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    :items="talentResults"
                                    :per-page="perPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :sort-direction="sortDirection"
                                    hover
                                    responsive
                                    striped
                                    @filtered="onFiltered"
                                >
                                    <template #cell(full_name)="data">
                                        <div
                                            v-b-toggle.cu-viewProfile
                                            class="qf-user-details d-flex align-items-center"
                                            @click="viewProfile(data.item)"
                                        >

                                            <b-avatar v-if="data.item.isVerified"
                                                      v-b-tooltip.hover
                                                      :src="data.item.avatar"
                                                      badge-offset="-0.5em"
                                                      badge-top
                                                      badge-variant=""
                                                      class="mr-1"
                                                      size="56px" title="Verified">
                                                <template #badge>
                                                    <div class="verified-mark">
                                                        <feather-icon icon="CheckIcon"/>
                                                    </div>
                                                </template>
                                            </b-avatar>

                                            <b-avatar v-else-if="data.item.isProspect"
                                                      v-b-tooltip.hover
                                                      :src="data.item.avatar"
                                                      badge-offset="-0.5em"
                                                      badge-top
                                                      badge-variant=""
                                                      class="mr-1"
                                                      size="56px" title="User not on platform yet">
                                                <template #badge>
                                                    <div class="pending-mark">
                                                        <feather-icon icon="HelpCircleIcon"/>
                                                    </div>
                                                </template>
                                            </b-avatar>

                                            <b-avatar v-else-if="data.item.subscriptionPlan"
                                                      v-b-tooltip.hover
                                                      :src="data.item.avatar"
                                                      badge-offset="-0.5em"
                                                      badge-top
                                                      badge-variant=""
                                                      class="mr-1"
                                                      size="56px" title="This talent has PRO subscription">
                                                    <template #badge>
                                                        <b-badge  class="list-badge" variant="gradient-red">PRO</b-badge>
                                                    </template>
                                            </b-avatar>

                                            <b-avatar
                                                v-else
                                                :src="data.item.avatar"
                                                class="mr-1"
                                                size="56"
                                            />
                                            <div>
                                                <h5 class="mb-0">{{ data.value }}</h5>
                                                <div class="d-flex">
                                                    <template v-if="data.item.age" class="mb-0"
                                                    >{{ data.item.age }} yrs |
                                                    </template>
                                                    <template v-if="data.item.gender" class="mb-0"
                                                    >{{ data.item.gender }}
                                                        |
                                                    </template>
                                                    <template v-if="data.item.height" class="mb-0"
                                                    >{{ data.item.height }}
                                                        cm
                                                    </template>
                                                </div>
                                                <div class="d-flex">
                                                    <template v-if="data.item.city"
                                                    >{{ data.item.city }},
                                                    </template
                                                    >
                                                    <template v-if="data.item.country"
                                                    >{{ data.item.country }},
                                                    </template
                                                    >
                                                    <template v-if="data.item.distance"
                                                    >{{ data.item.distance }}
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template #cell(languages)="data">
                                        <!--                                        <p v-for="(oneLang, index) in data.item.languages" :key="index">{{oneLang}}</p>-->
                                        <b-badge
                                            v-for="(oneLang, index) in data.item.languages"
                                            :key="index"
                                            class="mr-1"
                                            variant="secondary"
                                        >{{ oneLang }}
                                        </b-badge>
                                    </template>
                                    <template #cell(video)="data">
                                        <div
                                            v-if="data.item.video"
                                            class="qf-aplicant-video d-flex align-items-center"
                                        >
                                            <qf-video
                                                :avatar-url="data.item.avatar"
                                                :image-url="data.item.videoThumbnail"
                                                :user-names="data.item.full_name"
                                                :video-url="data.item.video"
                                                size="small"
                                            />
                                        </div>
                                    </template>

                                    <!--                  <template #cell(status)="data">-->
                                    <!--                    <b-badge :variant="status[1][data.value]">-->
                                    <!--                      {{ status[0][data.value] }}-->
                                    <!--                    </b-badge>-->
                                    <!--                  </template>-->

                                    <template #cell(icons)="data">
                                        <div
                                            v-if="!data.item.invited"
                                            v-b-tooltip.hover
                                            class="d-flex align-items-center justify-content-center"
                                            title="Invite to cast for role"
                                        >
                                            <feather-icon
                                                class="text-primary cursor-pointer"
                                                icon="SendIcon"
                                                size="18"
                                                @click="inviteUser(data.item)"
                                            />
                                        </div>
                                        <div
                                            v-else
                                            class="d-flex align-items-center justify-content-center"
                                        >
                                            <span>Invited to audition!</span>
                                        </div>
                                    </template>
                                </b-table>
                            </b-col>

                            <b-col cols="12">
                                <b-pagination
                                    v-model="currentPage"
                                    :per-page="perPage"
                                    :total-rows="totalRows"
                                    align="ceperPagenter"
                                    class="my-0"
                                    size="sm"
                                />
                            </b-col>
                        </b-row>
                    </b-tab>
                    <!--    END TAB : TALENT-->

                    <!--    START TAB : AUDITIONS -->
                    <b-tab :title-link-class="checkCompany ? 'myDisabled' : ''" title="Auditions"
                           @click="changeTab('auditions')">
                        <b-row>
                            <b-col lg="3" md="12" v-if="invitations.length">
                                <div
                                    class="d-flex justify-content-between align-items-center mb-1"
                                >
                                    <h3 class="mb-0">
                                        Pending invites ({{ invitations.length }})
                                    </h3>
                                </div>
                                <b-card class="overflow-hidden px-1" no-body>
                                    <div
                                        v-for="oneInvite in invitations"
                                        :key="oneInvite.id"
                                        class="align-items-center
                                               d-flex
                                               qf-user-wrap
                                               justify-content-between
                                               py-1
                                               "
                                    >
                                        <div
                                            v-b-toggle.cu-viewProfile
                                            class="d-flex align-items-center"
                                            @click="viewProfile(oneInvite.attributes.invitee)"
                                        >
                                            <b-avatar
                                                :src="avatar(oneInvite.attributes.invitee)"
                                                :text="initials(oneInvite.attributes.invitee)"
                                                class="mr-1"
                                                size="56px"
                                            >
                                            </b-avatar>
                                            <div class="qf-user-details">
                                                <h5 class="mb-0">
                                                    {{
                                                        oneInvite.attributes.invitee.attributes.firstName
                                                    }}
                                                    {{ oneInvite.attributes.invitee.attributes.lastName }}
                                                </h5>
                                                <!--                                                <p class="mb-0">35 yrs | Male | 176 cm</p>-->
                                                <!--                                                <p class="mb-0">Plovdiv, Bulgaria (153km)</p>-->
                                            </div>
                                        </div>
                                        <div class="qf-controls ml-1 d-flex justify-content-end">
                                            <feather-icon
                                                v-b-modal.sidebar-recastInvitee
                                                v-b-tooltip.hover
                                                class="text-primary mr-1 cursor-pointer"
                                                icon="RepeatIcon"
                                                size="18"
                                                title="Recast for another role"
                                                @click="openRecastModal(oneInvite)"
                                            />
                                            <feather-icon
                                                v-b-modal.delete-invite-modal
                                                class="text-primary mr-0 cursor-pointer"
                                                icon="XIcon"
                                                size="18"
                                                @click="openInviteModal(oneInvite)"
                                            />
                                        </div>
                                    </div>
                                </b-card>
                            </b-col>
                            <b-modal
                                id="delete-invite-modal"
                                button-size="sm"
                                cancel-title="Close"
                                cancel-variant="outline-secondary"
                                centered
                                modal-class="modal-danger"
                                ok-title="Delete"
                                ok-variant="danger"
                                title="Delete this Invite"
                                @ok="cancelInvite(canceledInvitation)"
                            >
                                <b-card-text>
                                    Are you sure you want to delete
                                    {{
                                        canceledInvitation.attributes.invitee.attributes.firstName
                                    }}
                                    {{
                                        canceledInvitation.attributes.invitee.attributes.lastName
                                    }}'s invitation?
                                </b-card-text>
                            </b-modal>
                            <b-col :lg="invitations.length ? 9 : 12" md="12">
                                <div
                                    class="d-flex justify-content-between align-items-center mb-1"
                                >
                                    <h3 class="mb-0">
                                        Submitted auditions ({{ applicants.length }})
                                    </h3>
                                    <div class="d-flex align-items-center">
                                        <div class="custom-control custom-switch">
                                            <label class="m-0 mr-1">Show shortlisted only</label>
                                            <input
                                                id="shortlistedOnly"
                                                v-model="shortlistedOnly"
                                                class="custom-control-input"
                                                type="checkbox"
                                            />
                                            <label
                                                class="custom-control-label"
                                                for="shortlistedOnly"
                                            ></label>
                                        </div>
                                        <div class="custom-control custom-switch">
                                            <label class="m-0 mr-1">Show archived</label>
                                            <input
                                                id="archivedOnly"
                                                v-model="showArchived"
                                                class="custom-control-input"
                                                type="checkbox"
                                            />
                                            <label
                                                class="custom-control-label"
                                                for="archivedOnly"
                                            ></label>
                                        </div>
                                    </div>
                                </div>
                                <b-row>
                                    <b-col>
                                        <div class="d-flex align-items-start mb-1">
                                            <div class="mb-0 mr-3">
                                                Zoom integration: {{ zoomIntegration ? 'On' : 'Off' }}
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <div class="custom-control custom-switch">
                                                    <b-button
                                                        v-b-toggle.cu-zoomIntegration
                                                        class="m-0 mr-1" size="sm" variant="primary">
                                                        <!--                                                @click="openZoomModal"-->
                                                        {{   zoomIntegration ? 'Edit' : 'Set up' }}
                                                    </b-button>
                                                </div>
                                            </div>
                                        </div>
                                    </b-col>
                                    <b-col class="text-right">
                                        <b-button
                                            v-b-toggle.cu-addAudition
                                            class="m-0 mr-1" size="sm" variant="primary">
                                            <feather-icon icon="PlusIcon" size="12"></feather-icon>
                                            Upload audition
                                        </b-button>
                                    </b-col>
                                </b-row>

                                <audition-listings
                                    :applicants="applicants"
                                    :list-mode="!gridView"
                                    :shortlisted-only="shortlistedOnly"
                                    :show-archived="showArchived"
                                    @reload="reloadAuditions"
                                ></audition-listings>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <!--          Tsetso Applicants End-->
                    <!--          Dessy Shortlisted Start-->
                    <b-tab :title-link-class="checkCompany ? 'myDisabled' : ''" title="Live Auditions"
                           @click="changeTab('live-auditions')">
                        <div class="d-flex justify-content-between align-items-center mb-1">
                            <h3 class="mb-0">Live Auditions</h3>
                            <div class="d-none">
                                <feather-icon
                                    class="align-text-center"
                                    icon="ListIcon"
                                    size="20"
                                    @click="changeView('list')"
                                />
                            </div>
                        </div>
                        <shortlisted
                            :shortlistedApplicants="shortlistedApplicants"
                        ></shortlisted>
                    </b-tab>
                    <!--          Dessy Shortlisted End-->
                </b-tabs>
            </b-col>
        </b-row>

        <b-sidebar
            id="cu-addAudition"
            backdrop
            bg-variant="white"
            right
            shadow
            sidebar-class="sidebar-lg"
            width="508px"
        >
            <template #header="{ hide }">
                <div
                    class="
                      d-flex
                      justify-content-between
                      align-items-center
                      content-sidebar-header
                      w-100
                    "
                >
                    <h5 class="mb-0">Add Audition Manually</h5>
                    <div>
                        <feather-icon
                            class="ml-1 cursor-pointer"
                            icon="XIcon"
                            size="16"
                            @click="hide"
                        />
                    </div>
                </div>
            </template>
            <modal-add-audition
                v-if="curRole && curProduction"
                :current-role="curRole"
                :current-production="curProduction"
                :sides="sides"
                @reload="reloadAuditions"
            ></modal-add-audition>
        </b-sidebar>


        <modal-invite-talent
            v-if="curRole && curProduction"
            :current-role="curRole"
            :current-production="curProduction ? curProduction : {}"
            :sides="sides"></modal-invite-talent>

        <b-modal
            id="sidebar-recastInvitee"
            ref="recast-modal"
            ok-title="Recast"
            title="Recast For Another Role"
            @ok="saveNewRole"
        >
            <div>
                <label>Select from the roles below:</label>
                <b-form-select
                    id="recastRole"
                    v-model="newRoleId"
                    :options="allRoles"
                    name="recastRole"
                >
                    <template #first>
                        <b-form-select-option disabled value="null"
                        >Pick one
                        </b-form-select-option
                        >
                    </template>
                </b-form-select>
            </div>
        </b-modal>
        <b-sidebar
            id="cu-viewProfile"
            backdrop
            bg-variant="white"
            right
            shadow
            sidebar-class="sidebar-lg"
            width="450px"
        >
            <template #header="{ hide }">
                <div
                    class="
                    d-flex
                    justify-content-between
                    align-items-center
                    content-sidebar-header
                    w-100
                  "
                >
                    <div>
                        <feather-icon
                            class="ml-1 cursor-pointer"
                            icon="XIcon"
                            size="16"
                            @click="hide"
                        />
                    </div>
                </div>
            </template>
            <modal-view-profile :profileId="profileId"></modal-view-profile>
        </b-sidebar>
        <b-sidebar
            id="cu-addEditShootingLocation"
            ref="editLocation"
            backdrop
            bg-variant="white"
            right
            shadow
            sidebar-class="sidebar-lg"
            width="508px"
        >
            <template #header="{ hide }">
                <div
                    class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            w-100
          "
                >
                    <h5 class="mb-0">Add / Edit Shooting Location</h5>
                    <div>
                        <feather-icon
                            class="ml-1 cursor-pointer"
                            icon="XIcon"
                            size="16"
                            @click="hide"
                        />
                    </div>
                </div>
            </template>
            <modal-add-edit-shooting-location
                v-if="oneLocation && modalMode === 'editLocation'"
                :location-to-edit="oneLocation"
            ></modal-add-edit-shooting-location>
        </b-sidebar>
        <b-sidebar
            id="cu-zoomIntegration"
            ref="zoomIntegration"
            backdrop
            bg-variant="white"
            right
            shadow
            sidebar-class="sidebar-lg"
            width="508px"
        >
            <template #header="{ hide }">
                <div
                    class="d-flex
                          justify-content-between
                          align-items-center
                          content-sidebar-header
                          w-100"
                >
                    <h5 class="mb-0">{{ zoomIntegration ? 'Edit' : 'Set up' }} Zoom Integration</h5>
                    <div>
                        <feather-icon
                            class="ml-1 cursor-pointer"
                            icon="XIcon"
                            size="16"
                            @click="hide"
                        />
                    </div>
                </div>
            </template>
            <modal-zoom-integration
                :apiKey="apiKey"
                :secretKey="secretKey"
                :webhookUrl="webhookUrl"
            ></modal-zoom-integration>

        </b-sidebar>

        <validation-observer ref="zoomIntegrationValidation" v-slot="{ passes }">
            <b-modal
                id="sidebar-zoomIntegration"
                ref="zoom-modal"
                data-cy="submitInvitation"
                ok-title="Save"
                title="Set Up Zoom"
                @ok="submitZoom"
                @ok.prevent="passes()"
            >
                <div>
                    <b-row class="pb-1">
                        <b-col cols="12">
                            <b-form-group label="Zoom API Key:">
                                <validation-provider
                                    v-slot="{ errors }"
                                    name="api key"
                                    rules="required"
                                >
                                    <b-form-input
                                        id="idZoomApiKey"
                                        v-model="integrationForm.zoomApiKey"
                                        name="intzoomApiKey"
                                        placeholder="Zoom API Key"
                                        type="text"
                                    />
                                    <a href="https://community.zoom.com/t5/Marketplace/How-do-I-get-API-Key-amp-API-Secret/m-p/28307#M405"
                                       target="_blank">Need help?</a>

                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                            <b-form-group label="Zoom Secret Key:">

                                <validation-provider
                                    v-slot="{ errors }"
                                    name="secret key"
                                    rules="required"
                                >
                                    <div class="d-flex justify-content-lg-between align-items-center">
                                        <b-form-input
                                            id="idZoomSecretKey"
                                            v-model="integrationForm.zoomSecretKey"
                                            name="zoomSecretKey"
                                            placeholder="Zoom Secret Key"
                                            type="text"
                                        />
                                    </div>
                                    <a href="https://community.zoom.com/t5/Marketplace/How-do-I-get-API-Key-amp-API-Secret/m-p/28307#M405"
                                       target="_blank">Need help?</a>

                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            After generating the credentials above you can set up your webhook:
                            <ol>
                                <li>Go to <a class="font-weight-bold" href="https://marketplace.zoom.us/"
                                             target="_blank">https://marketplace.zoom.us/</a> and sign in
                                </li>
                                <li>After you are signed in go to <a class="font-weight-bold"
                                                                     href="https://marketplace.zoom.us/user/build"
                                                                     target="_blank">https://marketplace.zoom.us/user/build</a>
                                </li>
                                <li>Choose the billing company for the current production</li>
                                <li>Click on "Feature" in the left vertical menu</li>
                                <li>Turn on "Event Subscriptions"</li>
                                <li>Click on "+ Add Event Subscription"</li>
                                <li>In the input field "Event notification endpoint URL" add: "{{ webhookUrl }}"</li>
                                <li>Then click on "+ Add Events" -> "Recording" -> "All Recordings have completed" ->
                                    "Done"
                                </li>
                                <li>Click on "Save"</li>
                            </ol>
                        </b-col>
                    </b-row>
                </div>
            </b-modal>
        </validation-observer>

        <b-sidebar
            id="cu-editCastingLocation"
            ref="editCastingLocation"
            backdrop
            bg-variant="white"
            right
            shadow
            sidebar-class="sidebar-lg"
            width="508px"
        >
            <template #header="{ hide }">
                <div
                    class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            w-100
          "
                >
                    <h5 class="mb-0">Add / Edit Casting Location</h5>
                    <div>
                        <feather-icon
                            class="ml-1 cursor-pointer"
                            icon="XIcon"
                            size="16"
                            @click="hide"
                        />
                    </div>
                </div>
            </template>
            <modal-edit-casting-location
                v-if="oneCastingLocation && modalCastingMode === 'editCastingLocation'"
                :location-to-edit="oneCastingLocation"
            ></modal-edit-casting-location>

        </b-sidebar>
        <b-sidebar
            id="searchTalent"
            ref="searchTalent"
            backdrop
            bg-variant="white"
            right
            shadow
            sidebar-class="sidebar-lg"
            width="480px"
        >
            <template #header="{ hide }">
                <div
                    class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            w-100
          "
                >
                    <h5 class="mb-0">Detailed filter</h5>
                    <div>
                        <feather-icon
                            class="ml-1 cursor-pointer"
                            icon="XIcon"
                            size="16"
                            @click="hide"
                        />
                    </div>
                </div>
            </template>
            <modal-search-talent></modal-search-talent>
        </b-sidebar>
    </div>
</template>

<script>
import {
    BCard,
    BCardText,
    BCardImg,
    BBadge,
    BCol,
    BRow,
    BTab,
    BTabs,
    BPagination,
    BAvatar,
    BButton,
    BTable,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BSidebar,
    BDropdown,
    BDropdownItem,
    VBToggle,
    VBModal,
    VBTooltip,
} from "bootstrap-vue";
import Parse from "parse";
import AuditionListings from "@/views/AuditionListings";
import Shortlisted from "./LiveAuditions";
import QfVideo from "../layouts/components/QfVideo";
import store from "@/store";
import ModalEditRole from "@/modals/ModalEditRole";
import ModalAddEditShootingLocation from "@/modals/ModalAddEditShootingLocation";
import ModalEditCastingLocation from "@/modals/ModalEditCastingLocation";
import ModalAddSides from "@/modals/ModalAddSides";
import ModalAddAudition from "@/modals/ModalAddAudition";
import ModalAddDates from "@/modals/ModalAddDates";
import ModalViewProfile from "@/modals/ModalViewProfile";
import ModalZoomIntegration from "@/modals/ModalZoomIntegration";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import ModalSearchTalent from "@/modals/ModalSearchTalent";
import ModalInviteTalent from "@/modals/ModalInviteTalent";
//import Api from "@/services/api";
import Api from "@/services/api";
import Ripple from "vue-ripple-directive";
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        //ModalReviewAudition,
        ModalAddDates,
        ModalInviteTalent,
        ModalSearchTalent,
        AuditionListings,
        Shortlisted,
        BCard,
        BCardText,
        BCardImg,
        BBadge,
        BCol,
        BRow,
        BTab,
        BTabs,
        BPagination,
        BAvatar,
        BButton,
        BTable,
        BFormGroup,
        BFormSelect,
        BFormSelectOption,
        BFormInput,
        BInputGroupAppend,
        BInputGroup,
        BSidebar,
        BDropdown,
        BDropdownItem,
        QfVideo,
        ModalEditRole,
        ModalViewProfile,
        ModalAddEditShootingLocation,
        ModalEditCastingLocation,
        ModalAddSides,
        ModalAddAudition,
        ModalZoomIntegration,
    },
    directives: {
        "b-toggle": VBToggle,
        "b-modal": VBModal,
        "b-tooltip": VBTooltip,
        Ripple,
    },
    data() {
        return {
            nextReload: 0,
            showArchived: false,
            modalMode: "",
            modalCastingMode: "",
            oneLocation: {},
            //oneCastingLocation: {},
            profileId: null,
            gridView: false,
            shortlistedOnly: false,
            //applicants: [],
            invitees: [],
            roleInviteeIds: [],
            shortlistedApplicants: [],
            roleId: "",
            newRoleId: "",
            dicts: {},
            activeRole: {
                attributes: {
                    name: "",
                },
            },
            perPage: 10,
            pageOptions: [3, 5, 10, 20],
            //totalRows: 1,
            currentPage: 1,
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filter: null,
            filterOn: [],
            infoModal: {
                id: "info-modal",
                title: "",
                content: "",
            },
            fields: [
                {
                    key: "full_name",
                    label: "Full Name",
                    sortable: true,
                    tdClass: "avatar-fullname",
                },
                {
                    key: "languages",
                    label: "Languages",
                    sortable: false,
                    tdClass: "lang",
                    thClass: "center",
                },
                {
                    key: "video",
                    label: "Showreel",
                    sortable: false,
                    tdClass: "small-video",
                    thClass: "center",
                },
                {
                    key: "nationality",
                    label: "Nationality",
                    sortable: true,
                    tdClass: "biography",
                },
                {
                    key: "icons",
                    label: "Actions",
                    sortable: false,
                    tdClass: "actions",
                    thClass: "center",
                },
            ],
            talentResults: [],
            items: [],
            sidesToDelete: {
                attributes: {
                    attachment: {
                        name: "",
                    },
                },
            },
            locationToDelete: {
                attributes: {},
            },
            canceledInvitation: {
                attributes: {
                    invitee: {
                        attributes: {},
                    },
                },
            },
            tabs: ['details', 'browse-talents', 'auditions', 'live-auditions',],
            tabIndex: 1,
            integrationForm: {
                zoomApiKey: '',
                zoomSecretKey: '',
            },
            webhookUrl: process.env.VUE_APP_API_URL + "/zoom",
        };
    },
    computed: {

        sameDay(){
            if(this.curRole && this.curRole.id && this.curRole.attributes.startWork && this.curRole.attributes.endWork
                && (new Date(this.curRole.attributes.startWork).getDate() === new Date(this.curRole.attributes.endWork).getDate()))
            {
                return true;
            }
            else {
                return false;
            }
        },
        countDays(){
            if(this.curRole && (this.curRole.attributes.workDays || this.curRole.attributes.startDate || this.curRole.attributes.endDate)){
                let count = 0;
                if(this.curRole.attributes.workDays){
                    count += this.curRole.attributes.workDays.length;
                }
                if(this.curRole.attributes.startDate){
                    count += 1;
                }
                if(this.curRole.attributes.endDate && this.curRole.attributes.endDate !== this.curRole.attributes.startDate){
                    count += 1;
                }
                return count;

            } else {
                return 0;
            }
        },

        curProduction() {
            return store.getters["app/currentProduction"];
        },
        zoomSecretKey() {
            if (this.curProduction && this.curProduction.attributes.billingCompany && this.curProduction.attributes.billingCompany.attributes) {
                return this.curProduction.attributes.billingCompany.attributes.zoomSecretKey;
            } else {
                return '';
            }
        },
        zoomApiKey() {
            if (this.curProduction && this.curProduction.attributes.billingCompany && this.curProduction.attributes.billingCompany.attributes) {
                return this.curProduction.attributes.billingCompany.attributes.zoomApiKey;
            } else {
                return '';
            }
        },
        zoomIntegration() {
            return this.zoomApiKey && this.zoomSecretKey;
        },
        oneCastingLocation() {
            if (this.curRole.attributes.castingLocation) {
                return this.curRole.attributes.castingLocation;
            } else {
                return {};
            }
        },
        allRoles() {
            let roles = store.getters["app/allRoles"];
            let prodRoles = [];
            if (roles) {
                roles.forEach(function (prodRole) {
                    prodRoles.push({
                        value: prodRole.id,
                        text: prodRole.attributes.name,
                    });
                });
            }
            return prodRoles;
        },
        hasAppearanceReqs() {
            if (
                this.curRole &&
                (this.curRole.attributes.minHeight ||
                    this.curRole.attributes.maxHeight ||
                    this.curRole.attributes.ageMin ||
                    this.curRole.attributes.ageMax ||
                    this.curRole.attributes.hairColor ||
                    this.curRole.attributes.eyeColor ||
                    this.curRole.attributes.bodyType ||
                    this.curRole.attributes.ethnicity)
            ) {
                return true;
            } else {
                return false;
            }
        },
        hasSkillReqs() {
            if (
                this.curRole &&
                ((this.curRole.attributes.languages &&
                        this.curRole.attributes.languages.length) ||
                    (this.curRole.attributes.skills &&
                        this.curRole.attributes.skills.length))
            ) {
                return true;
            } else {
                return false;
            }
        },
        curRoleIllustration() {
            if (this.curRole) {
                return this.roleIllustration(this.curRole);
            } else {
                return this.defaultLogo("png");
            }
        },
        applicants() {
            let self = this;
            let applicants = store.getters["app/allApplicants"];
            if(applicants){
                let ETAs = [];
                let Diffs = []
                applicants.forEach(function (applicant) {
                    if(applicant.attributes && applicant.attributes.ETA && moment(applicant.attributes.ETA).isAfter(moment())) {
                        ETAs.push(applicant.attributes.ETA);
                        let duration = moment.duration(moment(applicant.attributes.ETA).diff(moment()));
                        Diffs.push( parseInt(duration.asSeconds()) );
                    }
                });
                //console.log("ETAs", ETAs);
                //console.log("Diffs", Diffs);
                Diffs.sort(function(a, b){return a-b});
                if(Diffs[0]){
                    self.nextReload = Diffs[0];
                    console.log("Next reload in", Diffs[0]);
                }else {
                    self.nextReload = 0;
                }

            }
            return applicants;
        },
        totalRows() {
            return this.talentResults.length;
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter((f) => f.sortable)
                .map((f) => ({text: f.label, value: f.key}));
        },
        curRole() {
            return store.getters["app/currentRole"];
        },
        shootingLocations() {
            return store.getters["app/shootingLocations"];
        },
        sides() {
            return store.getters["app/sides"];
        },
        invitations() {
            return store.getters["app/invitations"];
        },
        curInvitation() {
            return store.getters["app/currentInvitation"];
        },
        searchResults() {
            return store.getters["app/talentSearchResults"];
        },
        checkCompany() {
            if (
                this.curProduction &&
                this.curProduction.attributes &&
                !this.curProduction.attributes.billingCompany
            ) {
                return true;
            } else {
                return false;
            }
        },
        secretKey(){
            if (this.zoomIntegration) {
                return this.curProduction.attributes.billingCompany.attributes.zoomSecretKey;
            }else{
                return '';
            }
        },
        apiKey(){
            if (this.zoomIntegration) {
                return this.curProduction.attributes.billingCompany.attributes.zoomApiKey;
            }else{
                return '';
            }
        },
    },
    beforeMount() {
        this.tabIndex = this.tabs.findIndex(tab => tab === this.$route.params.tab);
    },
    mounted() {
        // Set the initial number of items
        //this.totalRows = this.items.length;
        this.roleId = this.$route.params.roleId;
        store.commit("app/SET_LOCATIONS", []);
        this.getApplications(this.roleId);
        this.getRole(this.roleId);
        this.refreshTalent(this.roleId);
        //this.getTalent();
        this.dicts = store.getters["dict/getRoleDicts"];
        this.getSootingLocations(this.roleId);
        this.getSides(this.roleId);
    },
    methods: {
        reloadRole(){
          this.getRole(this.$route.params.roleId);
        },
        reloadAuditions(){
            this.getApplications();
            this.refreshTalent(this.roleId);
        },
        // openZoomModal() {
        //     if (this.zoomIntegration) {
        //         this.integrationForm.zoomSecretKey = this.curProduction.attributes.billingCompany.attributes.zoomSecretKey;
        //         this.integrationForm.zoomApiKey = this.curProduction.attributes.billingCompany.attributes.zoomApiKey;
        //     }
        //     this.$refs['modal-zoom-integration'].show();
        // },
        submitZoom() {
            let self = this;
            this.$refs.zoomIntegrationValidation
                .validate()
                .then((success) => {
                    if (success) {
                        self.curProduction.attributes.billingCompany.set('zoomApiKey', self.integrationForm.zoomApiKey);
                        self.curProduction.attributes.billingCompany.set('zoomSecretKey', self.integrationForm.zoomSecretKey);
                        self.curProduction.attributes.billingCompany.save().then(function () {
                            self.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Zoom credentials have been saved successfully!",
                                    icon: "EditIcon",
                                    variant: "success",
                                },
                            });
                        });
                        // TODO change zoom integration status
                        this.$store.commit('app/SET_PRODUCTION', self.curProduction);
                        this.$refs['zoom-modal'].hide();
                    }
                })
                .catch(function (err) {
                    self.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Check all fields for valid data!",
                            icon: "EditIcon",
                            variant: "danger",
                        },
                    });
                    console.log("validation errors", err);
                });
        },
        checkDisabled(newTabIndex, oldTabIndex, event) {
            if (this.checkCompany && newTabIndex > 0) {
                event.preventDefault();
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "You should add billing company to your production to access this feature!",
                        icon: "DangerIcon",
                        variant: "danger",
                        duration: 5000,
                    },
                });
            }
        },
        changeTab(tab) {
            if (!this.checkCompany || tab === 'details') {
                this.tabIndex = this.tabs.findIndex(t => t === tab);
                this.$router.push({name: 'roleDetails', params: {roleId: this.roleId, tab: tab}});
            }
        },
        openRecastModal(oneInvite) {
            store.commit("app/SET_ROLE_INVITATION", oneInvite);
        },
        saveNewRole() {
            let self = this;
            const newRolePointer = {
                __type: "Pointer",
                className: "ActingRole",
                objectId: this.newRoleId,
            };

            this.curInvitation.set("relatedRole", newRolePointer);

            this.curInvitation
                .save()
                .then(function (updatedInvitation) {
                    store.commit("app/SET_ROLE_INVITATION", updatedInvitation);
                    store.commit("app/REMOVE_ROLE_INVITE", updatedInvitation);

                    self.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "The actor has been recast successfully!",
                            icon: "EditIcon",
                            variant: "success",
                            duration: 5000,
                        },
                    });
                })
                .catch(function (err) {
                    console.log("err on save", err);
                });
        },
        openInviteModal(oneInvite) {
            this.canceledInvitation = oneInvite;
        },
        openLocationModal(oneLocation) {
            this.locationToDelete = oneLocation;
        },
        openModal(oneSide) {
            this.sidesToDelete = oneSide;
        },
        roleIllustration(role) {
            let productionImg = this.defaultLogo("png");
            if (role.attributes.characterIllustration) {
                productionImg = role.attributes.characterIllustration._url;
            }
            return productionImg;
        },
        setLocationToEdit(locationObj) {
            this.oneLocation = locationObj;
            this.modalMode = "editLocation";
        },
        setCastingLocationToEdit() {
            store.commit("app/SET_CASTING_LOCATION", this.oneCastingLocation);
            this.modalCastingMode = "editCastingLocation";
        },
        mapLink(lat, long) {
            return (
                "https://www.google.com/maps/search/?api=1&query=" + lat + "," + long
            );
        },
        cancelInvite(invitatiobObj) {
            this.canceledInvitation = invitatiobObj;
            store.commit("app/REMOVE_ROLE_INVITE", invitatiobObj);
            invitatiobObj.destroy();
        },
        initials(user) {
            if (user) {
                return (
                    user.attributes.firstName.substring(0, 1) +
                    user.attributes.lastName.substring(0, 1)
                );
            } else {
                return "";
            }
        },
        avatar(user) {
            if (user && user.attributes && user.attributes.avatar && user.attributes.avatar._url) {
                return user.attributes.avatar._url;
            } else {
                return null;
            }
        },
        getSootingLocations(roleId) {
            let shootingLocation = Parse.Object.extend("ShootingLocation");
            let LocationQuery = new Parse.Query(shootingLocation);
            store.commit("app/SET_LOCATIONS", []);

            const rolePointer = {
                __type: "Pointer",
                className: "ActingRole",
                objectId: roleId,
            };

            LocationQuery.equalTo("relatedRole", rolePointer);
            LocationQuery.find()
                .then(function (results) {
                    store.commit("app/SET_LOCATIONS", results);
                })
                .catch(function (err) {
                    console.log("Parse error", err.message);
                });
        },
        deleteLocation(shootingLocation) {
            shootingLocation
                .destroy()
                .then(function () {
                    store.commit("app/REMOVE_LOCATION", shootingLocation);
                })
                .catch(function (err) {
                    console.log("Parse error", err.message);
                });
        },
        deleteSide(side) {
            side
                .destroy()
                .then(function () {
                    store.commit("app/REMOVE_SIDE", side);
                })
                .catch(function (err) {
                    console.log("Parse error", err.message);
                });
        },
        getSides(roleId) {
            let media = Parse.Object.extend("Media");
            let sidesQuery = new Parse.Query(media);

            store.commit('app/SET_SIDES', []);
            const rolePointer = {
                __type: "Pointer",
                className: "ActingRole",
                objectId: roleId,
            };

            sidesQuery.equalTo("relatedRole", rolePointer);
            sidesQuery.equalTo("mediaType", "side");

            sidesQuery
                .find()
                .then(function (results) {
                    store.commit("app/SET_SIDES", results);
                })
                .catch(function (err) {
                    console.log("Parse error", err.message);
                });
        },
        prepareTalentData(results, invitations) {
            let self = this;
            self.talentResults = [];
            self.invitees = [];
            if (results.length) {
                results.forEach(function (item) {
                    let talentEntry = {
                        fullEntry: item,
                        id: item.id,
                        username:item.attributes.username,
                        //avatar: require('@/assets/images/avatars/10-small.png'),
                        full_name: item.attributes.firstName + " " + item.attributes.lastName,
                    };

                    talentEntry.isProspect = item.attributes.userType === "talentProspect";

                    if (item.attributes && item.attributes.relatedMeta) {
                        let theMeta = item.attributes.relatedMeta.attributes;

                        talentEntry.languages = theMeta.language
                            ? self.mapLanguages(theMeta.language)
                            : [];

                        talentEntry.city = theMeta.city;
                        talentEntry.country = theMeta.country;
                        talentEntry.distance = "";

                        talentEntry.age = theMeta.age;
                        talentEntry.gender = theMeta.gender;
                        talentEntry.height = theMeta.height;
                        talentEntry.isVerified = theMeta.isVerified;
                        talentEntry.biography = theMeta.bio;
                        talentEntry.nationality = theMeta.nationality;
                        talentEntry.sourceID = item.attributes.sourceID;
                        talentEntry.subscriptionPlan = item.attributes.subscriptionPlan;
                        if(item.attributes.relatedSource){
                            talentEntry.relatedSourceName = item.attributes.relatedSource.attributes.name;
                        }
                        if (theMeta.primaryShowreel && theMeta.primaryShowreel.attributes.attachmentThumb){
                            talentEntry.video =
                                theMeta.primaryShowreel.attributes.attachment._url;
                            talentEntry.videoThumbnail =
                                theMeta.primaryShowreel.attributes.attachmentThumb._url;
                        }
                    }
                    if (invitations && invitations.includes(item.id)) {
                        talentEntry.invited = true;
                        self.invitees.push(item);
                    }

                    if (item.attributes && item.attributes.avatar) {
                        talentEntry.avatar = item.attributes.avatar._url;
                    }

                    self.talentResults.push(talentEntry);
                    store.commit('app/SET_TALENT_LIST_RESULTS', self.talentResults);
                });
            }
        },
        async collectInviteeIds(invitationResults) {
            let self = this;
            let allInviteeIds = [];
            if (invitationResults.length) {
                invitationResults.forEach(function (oneInvite) {
                    if (oneInvite.attributes.invitee) {
                        allInviteeIds.push(oneInvite.attributes.invitee.id);
                    }
                });
            }
            self.roleInviteeIds = allInviteeIds;
            return allInviteeIds;
        },
        refreshTalent(roleId) {
            let self = this;
            let curUser = Parse.User.current();

            let InviteObj = Parse.Object.extend("Invitation");
            let invQuery = new Parse.Query(InviteObj);

            const rolePointer = {
                __type: "Pointer",
                className: "ActingRole",
                objectId: roleId,
            };
            invQuery.equalTo("relatedRole", rolePointer);
            invQuery.include("invitee.relatedMeta");

            let parseUsers = Parse.User;
            let talentQueryStandard = new Parse.Query(parseUsers);
            let talentQueryPrivate = new Parse.Query(parseUsers);
            let talentQueryProspects = new Parse.Query(parseUsers);

            talentQueryPrivate.equalTo("userType", "talent");

            //Comment out?
            talentQueryPrivate.equalTo("isPrivate", true);
            talentQueryPrivate.containedIn("whitelisted", [curUser.id]);

            talentQueryStandard.equalTo("userType", "talent");
            talentQueryStandard.equalTo("isPrivate", false);

            talentQueryProspects.equalTo("userType", "talentProspect");

            let compoundQuery = Parse.Query.or(
                talentQueryStandard,
                talentQueryPrivate,
                talentQueryProspects
            );
            compoundQuery.include("relatedSocialMedia");
            compoundQuery.include("subscriptionPlan");
            compoundQuery.include("relatedMeta.primaryShowreel");
            compoundQuery.descending("createdAt");
            compoundQuery.limit(1000);

            Promise.all([invQuery.find(), compoundQuery.find()])
                .then(async function ([invitationResults, talentResults]) {
                    store.commit("app/SET_ROLE_INVITATIONS", invitationResults);
                    let invitees = await self.collectInviteeIds(invitationResults);
                    self.prepareTalentData(talentResults, invitees);
                })
                .catch(function (err) {
                    self.$toast({
                        component: ToastificationContent,
                        props: {
                            title: err.message,
                            icon: "XIcon",
                            variant: "danger",
                        },
                    });
                });
        },
        inviteUser(userObj) {
            let self = this;
            const userPointer = {
                __type: "Pointer",
                className: "_User",
                objectId: userObj.id,
            };
            const rolePointer = {
                __type: "Pointer",
                className: "ActingRole",
                objectId: this.roleId,
            };

            let InviteObj = Parse.Object.extend("Invitation");
            let invitation = new InviteObj();

            invitation.set("invitee", userPointer);
            invitation.set("relatedRole", rolePointer);

            invitation
                .save()
                .then(function () {
                    let formData = new FormData();
                    let mailData = {
                        user: userObj,
                        role: store.getters["app/currentRole"],
                    };
                    formData.append("action", "sendNormalInvite");
                    formData.append("data", JSON.stringify(mailData));
                    //debugger;
                    Api()
                        .post("helpers/api.php", formData)
                        .then(function () {
                            self.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "User invited successfully",
                                    icon: "CheckIcon",
                                    variant: "primary",
                                },
                            });
                        });
                    self.refreshTalent(self.roleId);
                })
                .catch(function (err) {
                    self.$toast({
                        component: ToastificationContent,
                        props: {
                            title: err.message,
                            icon: "XIcon",
                            variant: "danger",
                        },
                    });
                });
        },
        dictLabel(input, dict, short = false) {
            if (this.dicts[dict]) {
                let entry = this.dicts[dict].find((obj) => {
                    return obj.value === input;
                });
                if (entry) {
                    if (short) {
                        return entry.short;
                    } else {
                        return entry.text;
                    }
                } else {
                    return "";
                }
            } else {
                return "";
            }
        },
        roleTypeLabel(type) {
            let labels = {
                lead: "Lead",
                supporting: "Supporting",
                day_player: "Day Player",
            };

            if (labels[type]) {
                return "(" + labels[type] + ")";
            } else {
                return "";
            }
        },
        niceDate(parseDate) {
            return moment(parseDate).format("DD-MMM-YYYY");
        },
        niceFileName(filename) {
            if (filename) {
                return filename.substring(33);
            } else {
                return "";
            }
        },
        getRole(roleId) {
            let self = this;
            let RoleObj = Parse.Object.extend("ActingRole");
            let roleQuery = new Parse.Query(RoleObj);
            roleQuery.include("castingLocation");
            roleQuery
                .get(roleId)
                .then(function (role) {
                    store.commit("app/SET_ROLE", role);
                    self.activeRole = role;
                    if (role.attributes.shortlistedTalent) {
                        self.getShortlisted(role);
                    }
                })
                .catch(function (err) {
                    console.log("Acting role fetch err", err.message);
                });
        },
        viewProfile(talentRow) {
            if (talentRow.fullEntry) {
                store.commit("app/SET_TALENT", talentRow.fullEntry);
            } else {
                store.commit("app/SET_TALENT", talentRow);
            }
            this.profileId = talentRow.id;
        },
        mapLanguages(userLangs) {
            let self = this;
            let processedLangs = [];
            if (userLangs) {
                userLangs.forEach(function (langCode) {
                    processedLangs.push(self.dictLabel(langCode, "languages"));
                });
            }
            return processedLangs;
        },
        changeView(mode) {
            if (mode === "grid") {
                this.gridView = true;
            } else {
                this.gridView = false;
            }
        },
        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`;
            this.infoModal.content = JSON.stringify(item, null, 2);
            this.$root.$emit("bv::show::modal", this.infoModal.id, button);
        },
        resetInfoModal() {
            this.infoModal.title = "";
            this.infoModal.content = "";
        },
        onFiltered() {
            // Trigger pagination to update the number of buttons/pages due to filtering
            //this.totalRows = filteredItems.length
            this.currentPage = 1;
        },
        getApplications() {
            // let currentUser = Parse.User.current();
            const rolePointer = {
                __type: "Pointer",
                className: "ActingRole",
                objectId: this.roleId,
            };
            // const userPointer = { "__type": "Pointer","className": "_User","objectId": currentUser.id};

            let Application = Parse.Object.extend("Application");
            let applicationQuery = new Parse.Query(Application);

            applicationQuery.equalTo("relatedRole", rolePointer);
            applicationQuery.include("relatedUser.relatedMeta");
            applicationQuery.include("votedFor");
            applicationQuery.include("votedAgainst");
            applicationQuery.descending("createdAt");

            applicationQuery
                .find()
                .then(function (result) {
                    store.commit("app/SET_APPLICANTS", result);
                })
                .catch(function (err) {
                    console.log("Application query error: ", err.message);
                });
        },
        getShortlisted(roleObj) {
            let userQuery = new Parse.Query(Parse.User);
            userQuery.containedIn("objectId", roleObj.attributes.shortlistedTalent);
            userQuery.include("relatedMeta");
            userQuery.find().then(function (users) {
                store.commit("app/ADD_SHORTLISTED", {
                    roleUsers: users,
                    roleId: roleObj.id,
                });
            });
        },
    },
    watch: {
        nextReload: function (val) {
            if (val) {
                setTimeout(() => {
                    this.reloadAuditions();
                }, val*1000);
            }
        },
        curRole: {
            deep: true,
            immediate: true,
            handler(newRole) {
                this.activeRole = newRole;
                store.commit("app/SET_LOCATIONS", []);
                if (newRole && newRole.id && this.roleId != newRole.id) {
                    this.roleId = newRole.id;
                    this.refreshTalent(newRole.id);
                    this.getApplications();
                    this.getShortlisted(newRole);
                    this.getSides(newRole.id);
                    this.getSootingLocations(newRole.id);
                    store.commit("app/SET_CASTING_LOCATION", null);
                }
            },
        },
        searchResults: {
            immediate: true,
            handler(newTalents) {
                this.prepareTalentData(newTalents, this.invitations);
            },
        },
        curProduction: {
            immediate: true,
            handler() {
                if (this.checkCompany && this.tabIndex != 0) {
                    this.tabIndex = 0;
                    this.$router.push({name: 'roleDetails', params: {roleId: this.roleId, tab: 'details'}});
                }
            },
        },

    },
};
</script>

<style>

.cs-pro-badge {
    position: absolute;
    top: -10px;
    right: -8px;
    scale: 0.85;
}

.list-badge.badge-gradient-red {
    scale: 0.6;
    position: absolute;
    top: 0px;
    right: -8px;
}


.modal-badge.badge-gradient-red {
    font-size: 0.25em;
}

.qf-aplicant-video .qf-video-talent {
    height: 100%;
    width: 100%;
}

.qf-shortlisted-video .qf-video-talent {
    width: 100%;
    height: 180px;
}

.qf-user-wrap + .qf-user-wrap {
    border-top: 1px solid rgba(255, 255, 255, 0.4);
}

.qf-user-details p {
    color: #b4b7bd;
    size: 12px;
}

.language-wrapp {
    background: #161d31;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: bold;
}

.qf-video-talent {
    height: 58px;
    width: 100px;
    border-radius: 5px;
}

.shooting-location,
.qf-sides {
    padding: 0.5rem 0;
}

.shooting-location + .shooting-location,
.qf-sides + .qf-sides {
    border-top: 1px solid #3b4253;
}

.shooting-location svg {
    margin-top: 3px;
}

.b-sidebar-header {
    background-color: #000013;
}

.myDisabled {
    cursor: default;
    opacity: 0.5;
}

.nav-link.myDisabled {
    cursor: default;
}

</style>
