<template>

    <validation-observer ref="talentValidation" v-slot="{ passes }">
        <b-modal
            :id="modalId"
            ref="invite-modal"
            :ok-title="okTitle"
            title="Add Talent to Audition"
            @ok="submitModal"
            @ok.prevent="passes()"
            @hidden="resetModal"
            @shown="getTalentSources"
        >

            <b-overlay
                variant="dark"
                :show="showSpinner"
                spinner-variant="light"
                blur="5px"
                opacity=".85"
                rounded="sm"
                class="stretch"
            >
            <b-row class="pb-1">
                <b-col cols="6">
                    <validation-provider
                        v-slot="{ errors }"
                        name="first name"
                        rules="required"
                    >
                        <label>First name <sup class="text-danger">*</sup></label>
                        <b-form-input
                            name="firstName"
                            id="firstName"
                            v-model="form.firstName"
                            placeholder="Talented"
                            type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-col>
                <b-col cols="6">
                    <validation-provider
                        v-slot="{ errors }"
                        name="last name"
                        rules="required"
                    >
                        <label>Last name <sup class="text-danger">*</sup></label>
                        <b-form-input
                            name="lastName"
                            id="lastName"
                            v-model="form.lastName"
                            placeholder="Person"
                            type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-col>
            </b-row>
            <b-row v-if="isNeededEmail" class="pb-1">
                <b-col cols="12">
                    <validation-provider
                        v-slot="{ errors }"
                        name="email"
                        rules="required|email"
                    >
                        <label>Email <sup class="text-danger">*</sup></label>
                        <b-form-input
                            name="email"
                            id="email"
                            v-model="form.email"
                            placeholder="email@mailserver.com"
                            type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-col>
            </b-row>

            <b-row class="pb-1">
                <b-col cols="8">

                    <label v-if="isNeededEmail">Talent Source (optional)</label>
                    <label v-else>Talent Source <sup class="text-danger">*</sup></label>
                    <Select2
                        id="talentSource"
                        ref="sourcePicker"
                        class="mb-1"
                        placeholder="Name of agent, manager, etc."
                        v-model="form.talentSourceArr"
                        :options="talentSourceChoices"
                        @select="selectSource"
                        :settings="{
                                multiple: true,
                                tags: true,
                                //tags: true,
                                //templateResult: companyPreview,
                                //templateSelection: selectionPreview,
                                //escapeMarkup: function(stuff){return stuff}
                             }"
                    >
                    </Select2>

                    <validation-provider
                        v-slot="{ errors }"
                        name="talentSource"
                        rules="required"
                        v-if="false"
                    >
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-col>
                <b-col cols="4">
                    <validation-provider
                        v-slot="{ errors }"
                        name="talentSource"
                        rules="required"
                    >
                        <label v-if="isNeededEmail">Talent ID (optional)</label>
                        <label v-else>Suggested Talent ID</label>
                        <b-form-input
                            name="talentSource"
                            id="talentSource"
                            v-model="form.sourceID"
                            type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-col>


            </b-row>

            <b-row class="pb-1">

                <b-col cols="12" v-if="isNeededEmail">
                    <b-form-checkbox
                        class="mr-0 mt-50"
                        name="is-rtl"
                        inline
                        v-model="form.inviteToRole"
                        :disabled="form.dummyProfile"

                    >Send e-mail to audition for this role</b-form-checkbox>
                </b-col>
            </b-row>
            <b-row class="pb-1">
                <b-col md="12">
                    <b-alert
                        v-if="!hasSides && form.inviteToRole"
                        variant="danger"
                        show
                    >
                        <div class="alert-body">
                            <span><strong>This role does not have any sides!</strong></span>
                        </div>
                    </b-alert>
                </b-col>

            </b-row>
            </b-overlay>

        </b-modal>

    </validation-observer>

</template>

<script>
import {
    //BForm,
    BModal,
    BFormInput,
    //BFormSelect,
    BFormCheckbox,
    BRow,
    BCol,
    BAlert,
    BOverlay,
    //BButton,
} from "bootstrap-vue";
import Select2 from 'v-select2-component';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Api2 from "@/services/api2";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Parse from "parse";
import store from "@/store";
export default {
    components: {
        //BForm,
        BModal,
        BFormInput,
        //BFormSelect,
        BFormCheckbox,
        BRow,
        BCol,
        BAlert,
        BOverlay,
        Select2,
        //BButton,
        ValidationProvider,
        ValidationObserver
    },
    name: "ModalInviteTalent",
    data() {
        return {
            form: {
                isTalent: true,
                sessionToken: '',
                firstName: "",
                lastName: "",
                email: "",
                inviteToRole: true,
                dummyProfile: false,
                roleData: null,
                talentSource: {
                    text: ''
                },
                talentSourceArr: '',
                sourceID: null,
            },
            showSpinner: false,
            talentSources: [],
        };
    },
    props: {
        modalId: {
            type: String,
            default: "modal-invite-talent",
        },
        talentMail: {
            type: String,
            default: "",
        },
        dummyMode: {
            type: Boolean,
            default: false,
        },
        currentRole: {
            type: Object,
            default: () => {},
            required: true,
        },
        currentProduction: {
            type: Object,
            default: () => {},
            required: true,
        },
        sides: {
            type: Array,
            default: () => [],
            required: true,
        },
        isNeededEmail: {
          type: Boolean,
          default: true,
        },
    },
    mounted() {
        if(this.dummyMode){
            this.form.dummyProfile = true;
            this.form.inviteToRole = false;
        }
        this.getTalentSources();

    },
    methods: {
        pad(num, size = 3) {
            num = num.toString();
            while (num.length < size) num = "0" + num;
            return num;
        },
        initials(words) {
             return words
                .replace(/\b(\w)\w+/g, '$1')
                .replace(/\s/g, '')
                .replace(/\.$/, '')
                .toUpperCase();
        },
        selectSource(choice){
            this.form.talentSourceArr = [];
            let choiceObj = {}
            if(choice.selected){
                if(!choice.value){
                    choiceObj = {
                        id: null,
                        text: choice.text,
                        value: choice.text,
                        increment: 1,
                    }
                }else {
                    choiceObj = choice;
                }

                //this.form.email = choiceObj.text;
                this.form.talentSourceArr = [choiceObj.value];
                this.form.talentSource = choiceObj;
            }else {
                this.form.talentSourceArr = [];
                this.form.talentSource = {
                    id: null,
                    text: '',
                    increment: 0,
                };
            }
            if(choiceObj.increment){
                this.form.sourceID = this.initials(choiceObj.text)+'-'+this.pad(choiceObj.increment);
                //this.form.email = this.initials(choiceObj.text)+'-'+this.pad(choiceObj.increment)+'@t.cineself.com';
            }

            console.log("talentSourceArr obj", this.form.talentSourceArr);
            console.log("Choice obj", choiceObj);
        },

        getTalentSources() {
            this.curUser = Parse.User.current();
            let query = new Parse.Query("TalentSource");
            query.containedIn("availableFor", [this.curUser.id, this.currentProduction.id]);
            query.find().then((talentSources) => {
                this.talentSources = talentSources;
            });
        },
        resetModal() {
            this.form.firstName = "";
            this.form.lastName = "";
            this.form.email = "";
            this.form.inviteToRole = true;
            this.form.dummyProfile = false;
            this.form.roleData = null;
            this.showSpinner = false;
            this.form.talentSource = {
                id: null,
                text: '',
                increment: 0,
            };
            this.form.sourceID = null;
            this.form.talentSourceArr = [];
            this.$refs.talentValidation.reset();
            this.getTalentSources();
        },
        cancelModal() {
            this.$bvModal.hide(this.modalId);
            this.resetModal();
        },
        updateInviteMode() {
            if(this.form.dummyProfile || this.isNeededEmail) {
                this.form.inviteToRole = false;
            }
        },
        submitModal() {
            let self = this;
            this.$refs.talentValidation
                .validate()
                .then((success) => {
                    if (success) {
                        this.sendInvite();
                    }
                })
                .catch(function (err) {
                    self.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Check all fields for valid data!",
                            icon: "EditIcon",
                            variant: "danger",
                        },
                    });
                    console.log("validation errors", err);
                });
        },


        postUserData(){
            let self = this;
            this.form.curProdId = this.currentProduction.id;
            Api2.sendData('createTalent', this.form)
                .then((response) => {
                    self.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Talent invited to Cineself successfully!",
                            icon: "CheckIcon",
                            variant: "success",
                        },
                    });
                    let userAssembly = {
                        userId: response.data.id,
                        firstName: self.form.firstName,
                        lastName: self.form.lastName,
                        email: self.form.email,
                    }
                    console.log("userAssembly", userAssembly);

                    this.$emit("invited", userAssembly);
                    setTimeout(() => {
                        this.cancelModal();
                    }, 300);

                })
                .catch((err) => {
                    console.log(err.response.data.message);
                    self.$toast({
                        component: ToastificationContent,
                        props: {
                            //title: "Unable to invite talent!",
                            title: err.response.data.message,
                            icon: "TimesIcon",
                            variant: "danger",
                        },
                    });
                });
        },

        sendInvite() {
            let self = this;
            this.curUser = Parse.User.current();
            this.form.sessionToken = this.curUser.attributes.sessionToken;

            this.form.roleData =
                {
                    id: this.currentRole.id,
                    name: this.currentRole.attributes.name,
                    description: this.currentRole.attributes.synopsis,
                    prodName: this.currentProduction.attributes.name,
                    sides: this.sides,
                };

            if(this.currentRole.attributes.castBefore){
                this.form.roleData.castBefore = this.niceDate(this.currentRole.attributes.castBefore);
            }

            /*
            if(this.currentRole && this.currentRole.attributes.characterIllustration){
                this.form.roleData.cover = this.currentRole.attributes.characterIllustration._url;
            } else
            */

            if (this.currentProduction && this.currentProduction.attributes.coverImage) {
                this.form.roleData.cover = this.currentProduction.attributes.coverImage._url;
            } else {
                this.form.roleData.cover = this.defaultLogo('png');
            }
            this.showSpinner = true;

            this.form.firstName = this.form.firstName.trim();
            this.form.lastName = this.form.lastName.trim();
            this.form.email = this.form.email.trim();

            if(!this.form.talentSource.id) {
                let TalentSource = Parse.Object.extend("TalentSource");
                let talentSource = new TalentSource();
                talentSource.set("name", this.form.talentSource.text);
                talentSource.set("increment", 1);
                talentSource.set("availableFor", [self.curUser.id, self.curProduction.id]);
                talentSource.save().then((talentSource) => {
                    self.form.talentSource.id = talentSource.id;
                    self.form.talentSource.increment = 1;
                    self.form.talentSourceArr = [talentSource.id];
                    if(!self.form.sourceID){
                        self.form.sourceID = this.initials(this.form.talentSource.text)+'-'+this.pad(this.form.talentSource.increment);
                    }
                    //this.form.email = this.initials(this.form.talentSource.text)+'-'+this.pad(this.form.talentSource.increment)+'@t.cineself.com';
                    self.postUserData();
                });
            }else {
                self.postUserData();
            }

        },
    },
    computed: {
        curProduction() {
          return store.getters["app/currentProduction"];
        },
        okTitle() {
          if(this.dummyMode) {
              return "Add";
          } else {
              return "Invite";
          }
        },
        hasSides() {
            return this.sides.length > 0;
        },
        talentSourceChoices() {
            let choices = [];
            this.talentSources.forEach((talentSource) => {
                choices.push({
                    id: talentSource.id,
                    value: talentSource.id,
                    text: talentSource.attributes.name,
                    increment: talentSource.attributes.increment,
                });
            });
            return choices;
        },
    },
    watch: {
        talentMail: function (val) {
            this.form.email = val;
        },
        // form: {
        //     handler: function (val) {
        //         if(val.talentSourceArr.length > 0){
        //             console.log("Before: ", val.talentSourceArr);
        //             debugger;
        //             if(val.talentSourceArr.length > 1) {
        //                 val.talentSourceArr.splice(0, val.talentSourceArr.length - 1);
        //             }
        //             this.form.talentSource = val.talentSourceArr[0];
        //             console.log("New talent Source: ", val.talentSourceArr);
        //         }
        //     },
        //     deep: true,
        // },
    },

}
</script>

<style scoped>

</style>

<style>
span.select2-selection__arrow {
    display: none;
}
.b-overlay-wrap.position-relative.stretch {
    margin: -20px;
    padding: 20px;
}
</style>
