<template>
    <div class="h-100 p-relative">
        <div class="qf-search-filters">
            <div class=" p-2 m-0">

              <validation-observer ref="zoomIntegrationValidation">
                  <div>
                    <b-row class="pb-1">
                      <b-col cols="12">
                        <b-form-group label="Zoom API Key:">
                          <validation-provider
                              v-slot="{ errors }"
                              name="api key"
                              rules="required"
                          >
                            <b-form-input
                                id="idZoomApiKey"
                                v-model="integrationForm.zoomApiKey"
                                name="zoomApiKey"
                                placeholder="Zoom API Key"
                                type="text"
                            />
                            <a href="https://community.zoom.com/t5/Marketplace/How-do-I-get-API-Key-amp-API-Secret/m-p/28307#M405"
                               target="_blank">Need help?</a>

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group label="Zoom Secret Key:">

                          <validation-provider
                              v-slot="{ errors }"
                              name="secret key"
                              rules="required"
                          >
                            <div class="d-flex justify-content-lg-between align-items-center">
                              <b-form-input
                                  id="idZoomSecretKey"
                                  v-model="integrationForm.zoomSecretKey"
                                  name="zoomSecretKey"
                                  placeholder="Zoom Secret Key"
                                  type="text"
                              />
                            </div>
                            <a href="https://community.zoom.com/t5/Marketplace/How-do-I-get-API-Key-amp-API-Secret/m-p/28307#M405"
                               target="_blank">Need help?</a>

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>

                        After generating the credentials above you can set up your webhook:
                        <ol>
                          <li>Go to <a class="font-weight-bold" href="https://marketplace.zoom.us/"
                                       target="_blank">https://marketplace.zoom.us/</a> and sign in
                          </li>
                          <li>After you are signed in go to <a class="font-weight-bold"
                                                               href="https://marketplace.zoom.us/user/build"
                                                               target="_blank">https://marketplace.zoom.us/user/build</a>
                          </li>
                          <li>Choose the billing company for the current production</li>
                          <li>Click on "Feature" in the left vertical menu</li>
                          <li>Turn on "Event Subscriptions"</li>
                          <li>Click on "+ Add Event Subscription"</li>
                          <li>In the input field "Event notification endpoint URL" add: "{{ webhookUrl }}"</li>
                          <li>Then click on "+ Add Events" -> "Recording" -> "All Recordings have completed" ->
                            "Done"
                          </li>
                          <li>Click on "Save"</li>
                        </ol>
                      </b-col>
                    </b-row>
                  </div>
              </validation-observer>

                <b-button variant="primary" class="mb-1 w-100" @click="submitZoom()">{{ saveLabel }}</b-button>
                <b-button
                    variant="outline-primary"
                    class="mb-1 w-100"
                    @click="cancelModal()"
                >CANCEL
                </b-button>

                <hr v-if="false" class="qf-separator mb-2" />

            </div>
        </div>

    </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import {required, email,} from '@validations';
import {
    //BForm,
    //BFormFile,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BFormSelect,
    // BFormSelectOption,
    // BFormFile,
    //BFormCheckbox,
    BButton,
    // BBadge,
    // BFormTextarea,
    // BFormDatepicker,
    //BAvatar,
    // BImg,
} from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
// import Parse from 'parse';
import store from "@/store";


export default {
    name: "ModalEditCompany",
    components: {
        //BForm,
        //BFormFile,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        // BFormSelect,
        // BFormSelectOption,
        // BFormDatepicker,
        // BFormFile,
        //BFormCheckbox,
        BButton,
        // BBadge,
        // BFormTextarea,
        ValidationProvider,
        ValidationObserver,
        //BAvatar,
        // BImg,
        // Select2
    },
    props:{
      apiKey:{
        type: String,
        default: '',
        required: true
      },
      secretKey:{
        type: String,
        default: '',
        required: true
      },
      webhookUrl: {
        type: String,
        default: '',
        required: true
      }
    },
    data() {
        return {
            required,
            email,
            previewImgSrc: false,
            newRoleCover: null,
            prodId: null,
            integrationForm: {
              zoomApiKey: '',
              zoomSecretKey: '',
            },
        }
    },

    mounted() {
        this.integrationForm.zoomApiKey = this.apiKey;
        this.integrationForm.zoomSecretKey = this.secretKey;
    },
    computed: {
        roleToEdit() {
            return store.getters['app/currentRole'];
        },
        curProduction() {
            return store.getters['app/currentProduction'];
        },
        saveLabel() {
            if (this.apiKey && this.secretKey) {
                return "SAVE CHANGES";
            } else {
                return "SET UP INTEGRATION";
            }
        },

    },
    methods: {
        resetForm(){
                this.integrationForm = {
                  zoomApiKey: '',
                  zoomSecretKey: '',
                }
        },
        cancelModal() {
            this.$root.$emit('bv::toggle::collapse', 'cu-zoomIntegration');
        },
        submitZoom() {
          let self = this;
          this.$refs.zoomIntegrationValidation
              .validate()
              .then((success) => {
                if (success) {
                  self.curProduction.attributes.billingCompany.set('zoomApiKey', self.integrationForm.zoomApiKey);
                  self.curProduction.attributes.billingCompany.set('zoomSecretKey', self.integrationForm.zoomSecretKey);
                  self.curProduction.attributes.billingCompany.save().then(function () {
                    self.$toast({
                      component: ToastificationContent,
                      props: {
                        title: "Zoom credentials have been saved successfully!",
                        icon: "EditIcon",
                        variant: "success",
                      },
                    });
                  });
                  // TODO change zoom integration status
                  this.$store.commit('app/SET_PRODUCTION', self.curProduction);
                  self.cancelModal();
                }
              })
              .catch(function (err) {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Check all fields for valid data!",
                    icon: "EditIcon",
                    variant: "danger",
                  },
                });
                console.log("validation errors", err);
              });
        },

    },

    watch: {
      apiKey: function (val) {
        this.integrationForm.zoomApiKey = val;
      },
      secretKey: function (val) {
        this.integrationForm.zoomSecretKey = val;
      }
    }
}
</script>

<style scoped>
.qf-cover-preview {
    width: 30%;
}

.qf-cover-preview img {
    width: 100%;
}

</style>
