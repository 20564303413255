<template>
    <div class="h-100 p-relative">
        <div class="qf-search-filters">
            <div class=" p-2 m-0">
                <b-form-group label="Main attributes" class="pb-1">
                    <b-row>
                        <b-col>
                            <label for="talentGender" class="pt-1">Gender</label>
                            <b-form-select
                                id="talentGender"
                                v-model="form.gender"
                                :options="dicts.genders"
                            >
                                <!--<template #first>-->
                                    <!--<b-form-select-option value="">Any</b-form-select-option>-->
                                <!--</template>-->
                            </b-form-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <div class="pt-1 custom-control custom-switch">
                                <input type="checkbox"
                                       class="custom-control-input"
                                       id="showShowreel"
                                       v-model="form.onlyWithShowreel">
                                <label class="custom-control-label" for="showShowreel">Show only candidates with video showreel</label>
                            </div>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group label="Appearance" class="pb-1">
                    <b-row>
                        <b-col>
                            <label for="ethnicity" class="pt-1">Ethnicity</label>
                            <b-form-select
                                id="ethnicity"
                                v-model="form.ethnicity"
                                :options="dicts.ethnicities"
                            >
                                <template #first>
                                    <b-form-select-option value="" >Any</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col>
                            <label for="hairColor" class="pt-1">Hair Color</label>
                            <b-form-select
                                id="hairColor"
                                v-model="form.hairColor"
                                :options="dicts.hairColors"
                            >
                                <template #first>
                                    <b-form-select-option value="" >Any</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <label for="eyeColor" class="pt-1">Eye Color</label>
                            <b-form-select
                                id="eyeColor"
                                v-model="form.eyeColor"
                                :options="dicts.eyeColors"
                            >
                                <template #first>
                                    <b-form-select-option value="" >Any</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col>
                            <label for="bodyType" class="pt-1">Body Type</label>
                            <b-form-select
                                id="bodyType"
                                v-model="form.bodyType"
                                :options="dicts.bodyTypes"
                            >
                                <template #first>
                                    <b-form-select-option value="" >Any</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <label for="ageMin" class="pt-1">Min Age</label>
                            <div class="d-flex justify-content-lg-between align-items-center">
                                <b-form-input
                                    id="ageMin"
                                    v-model="form.ageMin"
                                    type="number"
                                    placeholder="25 yrs" class="text-center" />
                            </div>
                        </b-col>
                        <b-col>
                            <label for="ageMax" class="pt-1">Max Age</label>
                            <b-form-input
                                id="ageMax"
                                v-model="form.ageMax"
                                type="number"
                                placeholder="45 yrs" class="text-center" />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <label for="heightMin" class="pt-1">Min Height</label>
                            <b-form-input
                                id="heightMin"
                                v-model="form.heightMin"
                                placeholder="170cm"
                                class="text-center" />
                        </b-col>
                        <b-col>
                            <label for="heightMax" class="pt-1">Max Height</label>
                            <b-form-input
                                id="heightMax"
                                v-model="form.heightMax"
                                placeholder="200cm"
                                class="text-center" />
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-form-group label="Abilities" class="pb-1">
                    <div>
                        <label for="skills" class="pt-1">Skills</label>
                        <Select2
                            id="skills"
                            ref="roleSkillPicker"
                            class="mb-1"
                            v-model="form.skills"
                            :options="remapOptions(dicts.skills)"
                            :settings="{multiple: true}"
                        />
                        <div class="qf-badge-group" v-if="false">
                            <b-badge v-for="(oneSkill,index) in form.skills"
                                     :key="index"
                                     variant="primary"
                                     @click="removePick(index, 'skills')"

                            >{{ badgeText(oneSkill, 'skills') }} <feather-icon icon="XIcon" size="21" /></b-badge>
                        </div>
                    </div>

                    <div>
                        <label for="languages" class="pt-1">Languages</label>
                        <Select2
                            id="languages"
                            ref="roleSkillPicker"
                            class="mb-1"
                            v-model="form.languages"
                            :options="remapOptions(dicts.languages)"
                            :settings="{multiple: true}"
                        />
                    </div>
                </b-form-group>
                <div>
                    <b-form-group>
                        <b-form-checkbox class="custom-control-primary" v-model="form.useGeolocation">
                            Restrict search by geolocation
                        </b-form-checkbox>
                    </b-form-group>
                    <div v-if="form.useGeolocation">
                        <b-row>
                            <b-col v-if="form.manualMode == false" cols="8">
                                <label for="selectLoc">Select Shooting Location</label>
                                <b-form-select
                
                                        id="selectLoc"
                                        v-model="form.selectedLocation"
                                        :options="shootingLocations"
                                >
                                    <template #first>
                                        <b-form-select-option :value="null" disabled>-- Select an option --</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-col>
                            <b-col v-if="form.manualMode == true" cols="8">
                                <label for="selectLoc">Type location manually </label>
                                <vue-google-autocomplete
                                        id="map"
                                        ref="googleAddress"
                                        classname="form-control"
                                        placeholder="Please type shooting location"
                                        v-on:placechanged="getAddressData"
                                        v-model="googleDefault"
                                        types="geocode"
                                >
                                </vue-google-autocomplete>
                            </b-col>
                            <b-col class="custom-control custom-switch">
                                <b-col>
                                    <label>Manual Mode</label>
                                    <input type="checkbox"
                                           class="custom-control-input"
                                           id="manualSwitch"
                                           v-model="form.manualMode">
                                    <label class="custom-control-label" for="manualSwitch"></label>
                                </b-col>
                            </b-col>
                        </b-row>
                        <b-form-group class="pt-1">
                            <label for="maxDistance">Max Distance</label>
                            <b-form-input id="maxDistance" placeholder="200 km" v-model="form.maxDistance"/>
                        </b-form-group>
                    </div>
                </div>
                <div class="card pt-1 pl-2 pr-2 m-0">
                    <b-button variant="primary" class="mb-1" @click="doSearch()">APPLY SEARCH FILTERS</b-button>
                    <b-button variant="outline-primary" class="mb-1" @click="loadDefaults()">LOAD ROLE DEFAULTS</b-button>
                    <a class="text-right p-0 ml-auto" @click="resetAll()">Clear All Filters</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//import { VueAutosuggest } from 'vue-autosuggest';
import store from '@/store';
//import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import {
    //BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BFormCheckbox,
    BButton,
    BBadge,
    //BAvatar,
} from 'bootstrap-vue'
import Parse from "parse";
import Select2 from "v-select2-component";
export default {
    components: {
        //BForm,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BFormSelectOption,
        BFormCheckbox,
        BButton,
        BBadge,
        //VueAutosuggest,
        //VueBootstrapTypeahead,
        VueGoogleAutocomplete,
        //BAvatar,
        //ChatLog,
        //QfVideo,
        Select2
    },
    computed: {
        shootingLocations(){
            let locations = store.getters['app/shootingLocations'];

            let roleLocations = [];
            if(locations){
                locations.forEach(function(roleLoc){
                    roleLocations.push({value: roleLoc, text: roleLoc.attributes.address + " in " + roleLoc.attributes.city + ", " + roleLoc.attributes.country})
                });
            }

            return roleLocations;
        },
        curRole(){
            return store.getters['app/currentRole'];
        },
    },
    data() {
        return {
            googleDefault: "",
            chatInputMessage: null,
            genderSelected: null,
            dicts: {
                skills: [],
                bodyTypes: [],
                roleTypes: [],
                ethnicities: [],
                hairColors: [],
                eyeColors: [],
                languages: [],
                genders: [],
            },
            form: {
                manualMode: false,
                
                ageMax: null,
                ageMin: null,
                heightMax: null,
                heightMin: null,

                hairColor: '',
                eyeColor: '',
                bodyType: '',
                ethnicity: '',
                gender: '',

                selectedLang: '',
                languages: [],

                selectedSkill: '',
                skills: [],

                onlyWithShowreel: false,

                geoCoordinates: null,
                useGeolocation: false,
                selectedLocation: null,
                maxDistance: null,
            },
            
            skillSuggest: [],
            filteredOptions: [],
            limit: 10,
            skillSelected: null,
        }
    },
    mounted() {
        this.dicts = store.getters['dict/getRoleDicts'];
        //this.locations = store.getters['app/shootingLocations'];
    },
    methods: {
        remapOptions(options){
            if(options){
                options.forEach(function(oneOption, index){
                    options[index] = {id: oneOption.value, value: oneOption.value, text: oneOption.text}
                });
            }
            return options;
        },
        getAddressData(addressData){
            if(addressData){
                this.form.geoCoordinates = new Parse.GeoPoint(addressData.latitude, addressData.longitude);
            }
        },
        resetAll(){
          this.form = {
              ageMax: null,
              ageMin: null,
              heightMax: null,
              heightMin: null,

              hairColor: '',
              eyeColor: '',
              bodyType: '',
              ethnicity: '',
              gender: '',

              selectedLang: '',
              languages: [],

              selectedSkill: '',
              skills: [],

              onlyWithShowreel: false,
          };
        },
        loadDefaults(){
            let self = this;
            if(this.curRole){
                let matchingFields = [
                    {key: 'ageMax', type: ''},
                    {key: 'ageMin', type: ''},
                    {key: 'heightMax', type: ''},
                    {key: 'heightMin', type: ''},
                    {key: 'hairColor', type: ''},
                    {key: 'eyeColor', type: ''},
                    {key: 'bodyType', type: ''},
                    {key: 'gender', type: ''},
                    {key: 'skills', type: ''},
                    {key: 'languages', type: ''},
                    {key: 'ethnicity', type: ''},
                ];
                matchingFields.forEach(function(oneField){
                   if(self.curRole.attributes[oneField.key]){
                       self.form[oneField.key] = self.curRole.attributes[oneField.key]
                   }else {
                       self.form[oneField.key] = null;
                   }
                });
            }
        },
        doSearch(){
            let metaObj = Parse.Object.extend('TalentMeta');
            let metaQuery = new Parse.Query(metaObj);
            let curUser = Parse.User.current();

            let userQuery1 = new Parse.Query('User');
            let userQuery2 = new Parse.Query('User');

            userQuery1.equalTo('userType', 'talent');
            userQuery1.equalTo('isPrivate', false);

            userQuery2.equalTo('userType', 'talent');
            userQuery2.equalTo('isPrivate', true);
            userQuery2.containedIn('whitelisted', [curUser.id]);

            let compoundUserQuery = Parse.Query.or(userQuery1,userQuery2);

            if(this.form.ageMax) {
                metaQuery.lessThanOrEqualTo('age', parseInt(this.form.ageMax));
            }
            if(this.form.ageMin) {
                metaQuery.greaterThanOrEqualTo('age', parseInt(this.form.ageMin));
            }

            if(this.form.heightMax) {
                metaQuery.lessThanOrEqualTo('height', parseInt(this.form.heightMax));
            }
            if(this.form.heightMin) {
                metaQuery.greaterThanOrEqualTo('height', parseInt(this.form.heightMin));
            }

            if(this.form.hairColor){
                metaQuery.equalTo('hairColor', this.form.hairColor);
            }

            if(this.form.eyeColor){
                metaQuery.equalTo('eyeColor', this.form.eyeColor);
            }

            if(this.form.bodyType){
                metaQuery.equalTo('build', this.form.bodyType);
            }

            if(this.form.gender){
                metaQuery.equalTo('gender', this.form.gender);
            }

            if(this.form.ethnicity){
                metaQuery.equalTo('ethnicity', this.form.ethnicity);
            }

            if(this.form.skills.length){
                metaQuery.containsAll('skills', this.form.skills);
            }

            if(this.form.languages.length){
                metaQuery.containsAll('language', this.form.languages);
            }

            if(this.form.onlyWithShowreel){
                metaQuery.exists('primaryShowreel');
            }
            
            if(this.form.useGeolocation &&
                this.form.selectedLocation &&
                this.form.selectedLocation.attributes.coordinates &&
                this.form.maxDistance){
                metaQuery.withinKilometers('latestLocation', this.form.selectedLocation.attributes.coordinates, parseInt(this.form.maxDistance));
            }
            if(this.form.useGeolocation && this.form.geoCoordinates && this.form.maxDistance){
                metaQuery.withinKilometers('latestLocation', this.form.geoCoordinates, parseInt(this.form.maxDistance));
            }

            metaQuery.descending('createdAt');
            metaQuery.include('relatedUser.relatedMeta.primaryShowreel');
            metaQuery.matchesQuery('relatedUser', compoundUserQuery);

            metaQuery.find().then(function(results){
                let talentResults = [];
                if(results){
                    results.forEach(function(oneResult){
                        talentResults.push(oneResult.attributes.relatedUser);
                    });
                }
                store.commit('app/SET_TALENT_RESULTS', talentResults);
                console.log("User search results: ", results);
            }).catch(function (err) {
                console.log("Parse error", err.message);
            });

        },
        removePick(index, field){
            this.form[field].splice(index, 1);
            //console.log(this.form.genres);
        },
        badgeText(genreKey, field){
            let entry = this.dicts[field].find(o => o.value === genreKey);
            if(entry){
                return entry.text;
            }else{
                return '';
            }
        },
        selectHandler(selection, field, picker){
            if (typeof (this.form[field]) == 'undefined'){
                this.form[field] = [];
            }
            //console.log("Selection: ", this.form.genreSelection);
            this.form[field].push(selection.value);
            this.$refs[picker].inputValue = ''
        },
        dictLabel(input, dict, short=false){
            if(this.dicts[dict]){
                let entry = this.dicts[dict].find(obj => {
                    return obj.value === input
                });
                if(entry){
                    if(short){
                        return entry.short;
                    }else{
                        return entry.text;
                    }
                }else{
                    return '';
                }
            }else {
                return ''
            }
        },
        onInputChange(text) {
            if (text === '' || text === undefined) {
                return false;
            }
            //
            // /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
            // const filteredDevelopers = this.skillSuggest.data[0].developers.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
            // const filteredDesigner = this.skillSuggest.data[0].designers.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
            // const filteredData = filteredDevelopers.concat(filteredDesigner)
            // this.filteredOptions = [{
            //     data: filteredData,
            // }]
        },
    },
}
</script>

<style scoped>
</style>
