<template>
    <b-overlay
        variant="dark"
        :show="showSpinner"
        spinner-variant="light"
        blur="5px"
        opacity=".85"
        rounded="sm"
    >
	<div class="h-100 p-relative">

		<div class="qf-search-filters px-2 pt-1 pb-2">

                <div class="card m-0">
                    <validation-observer ref="emailInput">
                        <b-form-group label="Talent selection" class="pb-1">

                            <b-row v-if="showTalentSearch">
                                <b-col cols="6">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="talent email"
                                        rules="required"
                                    >
                                        <label for="memberEmail" class="pt-1">Search for talent by <strong>e-mail</strong> or their <strong>talent ID: </strong><sup class="text-danger">*</sup></label>
                                        <b-form-input name="name"
                                                      id="memberEmail"
                                                      v-model="userEmail"
                                                      placeholder="peter@mail.com or BV-033"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-col>
                                <b-col cols="6">
                                    <div class="mb-2">
                                        <label for="addNew" class="pt-1">You only have the talent names? Use the button below</label>
                                        <b-button
                                            id="addNew"
                                            variant="primary"
                                            class="mb-1 mt-05"
                                            size="sm"
                                            @click="openInviteModalWithName"
                                        >
                                            <!--                          v-b-modal.inviteTalentModal-->
                                            <feather-icon icon="PlusIcon" size="12"/>
                                            Add Talent Wihtout Email
                                        </b-button>
                                    </div>
                                </b-col>
                            </b-row>

                            <div class="results-container">

                                    <div :key="`suggest`" class="mb-2 mt-0 text-center" v-if="showSuggestInvite && showTalentSearch && userEmail && !validatorMail[0]">

                                        <p v-if="isValidEmail">A talent profile with this e-mail does not exist on the platform.
                                            Would you like to invite them or create a system profile?</p>
                                        <p v-if="!isValidEmail" class="text-warning text-left">A talent profile with such ID or part of their e-mail does not exist on the platform!</p>
                                        <p v-if="!isValidEmail" class="text-secondary text-left font-small-3"> You may use the button above if you would like to setup a new profile.</p>
                                        <b-button
                                            variant="primary"
                                            class="mb-1"
                                            @click="openInviteModal"
                                            v-if="isValidEmail"
                                        >
                                            <feather-icon icon="PlusIcon"/>
                                            CREATE TALENT PROFILE
                                        </b-button>

                                    </div>

                                    <div :key="`results`" class="mb-2 mt-2" v-if="showExisting">
                                        <b-row>
                                            <b-col cols="12" v-for="oneUser in foundUsers" :key="oneUser.username">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <div class="d-flex align-items-center" >
                                                        <b-avatar size="50" class="mr-1" :src="avatar(oneUser.fullEntry)"/>
                                                        <h3 class="producer-name">
                                                            <div>{{ oneUser.full_name }}</div>
                                                            <p class="font-small-3 text-secondary" v-if="oneUser.sourceID">{{ oneUser.sourceID }}</p>
                                                            <p class="font-small-3" v-else>{{ oneUser.username }}</p>
                                                        </h3>
                                                    </div>
                                                    <div>
                                                        <b-button
                                                            v-if="selectedUser"
                                                            variant="disabled" @click="deselectUser()" size="sm">
                                                            <feather-icon icon="XIcon"/>
                                                            REMOVE
                                                        </b-button>
                                                        <b-button
                                                            v-if="!selectedUser"
                                                            variant="primary" @click="selectUser(oneUser.fullEntry)" size="sm">
                                                            <feather-icon icon="CheckIcon"/>
                                                            SELECT
                                                        </b-button>

                                                    </div>
                                                </div>
                                            </b-col>
                                        </b-row>
                                    </div>

                            </div>
                        </b-form-group>
                    </validation-observer>
                    <validation-observer :ref="`videoFile`">
                        <b-form-group label="Audition Recording" class="pb-1">
                            <label for="auditionRecording">Audition recording File (.mov, .mp4) <strong>Max 400MB</strong></label>
                            <validation-provider
                                v-slot="{ errors }"
                                name="auditionRecording"
                                rules="required"
                            >
                                <b-form-file
                                    v-model="files.video"
                                    id="auditionRecording"
                                    accept=".mov, .mp4"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                            <label for="memberEmail" class="pt-1">Add a label to the audition</label>
                            <b-form-input type="text"
                                          id="auditionLabel"
                                          v-model="form.auditionLabel"
                                          placeholder="E.g. Take 2">

                            </b-form-input>
                        </b-form-group>
                    </validation-observer>

                    <b-button
                        variant="primary"
                        class="mb-1"
                        :disabled="this.form.userId === null || this.files.video === null"
                        @click="submitFile()">UPLOAD AUDITION</b-button>
                    <b-button
                        variant="outline-primary"
                        class="mb-1"
                        @click="cancelModal()"
                    >CANCEL</b-button>

                </div>

		</div>

	</div>

    <template #overlay>
        <div class="d-flex flex-column align-items-center justify-content-center">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
            <h4 class="text-center mt-2">Uploading video file...</h4>
            <p class="text-center">Please be patient, depending on the size and length of the video this might take up to several minutes.</p>
            <b-progress max="100" variant="success" show-progress class="w-75">
              <b-progress-bar :value="progressPercentage" :label="`${progressPercentage}%`"></b-progress-bar>
            </b-progress>
        </div>
    </template>

        <modal-invite-talent
            v-if="currentRole && currentProduction"
            :modal-id="`inviteTalentModal`"
            :talent-mail="userEmail"
            :current-role="currentRole"
            :current-production="currentProduction"
            :sides="sides"
            :isNeededEmail="isNeededEmail"
            :dummy-mode="true"
            @invited="fetchNewUserData"
        ></modal-invite-talent>

    </b-overlay>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import {required,} from '@validations';
import {
	BFormGroup,
	BButton,
	BFormFile,
    BFormInput,
    BAvatar,
    BOverlay,
    BSpinner,
    BCol,
    BRow,
    BProgress,
    BProgressBar
} from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import ModalInviteTalent from "@/modals/ModalInviteTalent";
import store from "@/store";
import Api2 from "@/services/api2";
import Parse from "parse";


export default {
	name: "ModalAddAudition",
	components: {
		BFormGroup,
		BButton,
		BFormFile,
        BFormInput,
        BAvatar,
        BOverlay,
        BSpinner,
        ModalInviteTalent,
        BCol,
        BRow,
        BProgress,
        BProgressBar,
		ValidationProvider,
		ValidationObserver,
	},
	data() {
		return {
			required,
            locationId: 0,
            userEmail: '',
            showExisting: false,
            showResult: false,
            showTalentSearch: true,
            showSuggestInvite: true,
            awaitingSearch: false,
            showSpinner: false,
			form: {
                userId: null,
                roleId: null,
                auditionLabel: '',
			},
            files: {
                video: null,
            },
            foundUsers: [],
            foundUser: null,
            validatorMail: [],
            selectedUser: null,
      isNeededEmail: false,
		}
	},

    props: {
        currentProduction: {
            type: Object,
            default: () => {},
            required: true,
        },
        sides: {
            type: Array,
            default: () => [],
            required: true,
        },
    },

	mounted() {},
	computed: {
    progressPercentage(){
      return store.getters['app/uploadProgresses'].fileUpload;
    },
		currentRole() {
			return store.getters['app/currentRole'];
		},
    isValidEmail() {
      return this.testEmail(this.userEmail);
    }
	},
	methods: {
    testEmail(email) {
        // eslint-disable-next-line no-useless-escape
        const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return res.test(String(email).toLowerCase());
    },
    openInviteModal() {
      this.isNeededEmail = true;
      this.$bvModal.show(`inviteTalentModal`);
    },
    openInviteModalWithName(){
      this.isNeededEmail = false;
      this.$bvModal.show(`inviteTalentModal`);
    },
    resetForm() {
            this.form = {
                userId: null,
                roleId: null,
                auditionLabel: '',
            }
            this.files = {
                video: null,
            }
            this.userEmail = '';

            this.showExisting = false;
            this.showResult = false;
            this.showTalentSearch = true;
            this.showSuggestInvite = true;
            this.awaitingSearch = false;
            this.showSpinner = false;
            this.validatorMail = [];
            this.selectedUser = null;

            this.$refs.emailInput.reset();
            this.$refs.videoFile.reset();
        },
        fetchNewUserData(createData) {
            this.showSpinner = true;
            let self = this;

            let query = new Parse.Query(Parse.User);
            query.equalTo('objectId', createData.userId);
            query.includeAll();
            query.first().then((user) => {
                self.selectedUser = user;
                self.foundUser = {};
                self.foundUser.full_name = createData.firstName + ' ' + createData.lastName;
                self.foundUser.fullEntry = user;
                self.foundUser.username = createData.email;
                self.foundUser.sourceID = user.attributes.sourceID;
                if(user.attributes.relatedSource){
                    self.foundUser.relatedSourceName = user.attributes.relatedSource.attributes.name;
                }

                self.form.userId = createData.userId;

                self.foundUsers = [];
                self.foundUsers.push(self.foundUser);

                self.showExisting = true;
                self.showSuggestInvite = false;

                self.awaitingSearch = false;
                self.showResult = false;
                self.showTalentSearch = false;

                self.showSpinner = false;
                console.log('Fetching new user data', createData);
            });


        },
        deselectUser() {
            this.selectedUser = null;
            this.showExisting = false;
            this.showTalentSearch = true;
            this.form.userId = null;
            this.userEmail = '';
            this.$refs.emailInput.reset();
        },
        selectUser(userObj){
            this.form.userId = userObj.id;
            this.selectedUser = userObj;
            this.userEmail = userObj.attributes.username;

            this.showTalentSearch = false;
            this.showResult = true;
        },
        avatar(user) {
            if (user && user.attributes.avatar) {
                return user.attributes.avatar._url;
            } else {
                return null;
            }
        },
        // getMember() {
        //     let self = this;
        //     let parseUsers = Parse.User;
        //     let memberQuery = new Parse.Query(parseUsers);
        //
        //     memberQuery.contains('email', this.userEmail);
        //     memberQuery.equalTo('userType', 'talent');
        //     memberQuery.equalTo('userType', 'prospectTalent');
        //     memberQuery.find().then(function (results) {
        //         if(results.length > 0){
        //             self.foundUser = results[0];
        //             self.showResult = true;
        //         }
        //         self.awaitingSearch = false;
        //     }).catch(function (err) {
        //         console.log("Search error: ", err);
        //         self.awaitingSearch = false;
        //         self.showResult = false;
        //     })
        // },

        cancelModal() {
            this.$root.$emit('bv::toggle::collapse', 'cu-addAudition');
            this.resetForm();
        },

		submitFile(){
			let self = this;
			this.$refs[`videoFile`].validate().then(success => {
				if (success) {
					self.saveFile();
				}
			}).catch(function (err) {
				self.$toast({
					component: ToastificationContent,
					props: {
						title: 'Check all fields for valid data!',
						icon: 'EditIcon',
						variant: 'danger',
					},
				});
				console.log('validation errors', err);
			});
		},
		saveFile(){
			let self = this;
            this.form.roleId = this.currentRole.id;

            this.showSpinner = true;
			Api2.sendData('processVideo', this.form, this.files).then(function (newAudition) {
				store.commit('app/ADD_APPLICANT', newAudition );

				self.$toast({
					component: ToastificationContent,
					props: {
						title: 'Audition successfully uploaded! Now processing...',
						icon: 'CheckIcon',
						variant: 'success',
					},
				});
                self.showSpinner = false;
				self.files.video = null;
                self.cancelModal();
                self.$emit('reload');

			}).catch(function (err) {
                self.showSpinner = false;
                self.files.video = null;
                self.cancelModal();

                let errMsg = "Unable to process video file. Please try again later.";

                if(err && err.response) {
                    errMsg = 'Video processing ERROR: '+err.response.data.message
                    console.log('Video processing ERROR:', err.response.data);
                }

				self.$toast({
					component: ToastificationContent,
					props: {
						title: errMsg,
						icon: 'XIcon',
						variant: 'danger',
					},
				});

			});

		},
	},
    watch: {
        userEmail: function (emailString) {
            this.showExisting = false;
            this.showResult = false;
            let self = this;
            if(emailString && !self.awaitingSearch){
                this.$refs.emailInput.validate().then((success) => {
                    if (success) {
                        self.awaitingSearch = true;
                        setTimeout(() => {
                            if (emailString !== '') {
                                let curTeam = store.getters['app/talentListResults'];
                                let userExists = [];
                                if (curTeam) {
                                    userExists = curTeam.filter(obj => {
                                        return obj.username.toLowerCase().indexOf(emailString.toLowerCase()) >= 0 ||
                                            (obj.sourceID && obj.sourceID.toLowerCase().indexOf(emailString.toLowerCase()) >= 0) ||
                                            //obj.full_name.indexOf(emailString) >= 0 ||
                                            obj.username  === emailString
                                    });
                                }

                                if (userExists.length > 0) {
                                    this.foundUser = userExists[0];
                                    self.foundUsers = userExists.slice(0,3);
                                    this.showExisting = true;
                                    this.showSuggestInvite = false;

                                    this.awaitingSearch = false;
                                    this.showResult = false;
                                } else {

                                    this.showExisting = false;
                                    this.showSuggestInvite = true;
                                }
                            }
                            self.awaitingSearch = false;
                        }, 500);
                    } else {
                        self.showSuggestInvite = false;
                    }
                }).catch((err) =>  {
                    console.log("Validation error!", err);
                });
            }



        },
    },
}
</script>

<style scoped>

.b-overlay-wrap {
    height: 100%;
    width: 100%;
}

</style>
