<template>
    <div class="h-100 p-relative">
        <div class="qf-search-filters px-2 pt-1 pb-2">
            <div class="card m-0">
                <validation-observer :ref="`locationValidation`+locationId">
                    <b-form-group label="Timeframe" class="pb-1">
                        <b-row>
                            <b-col cols="6">
                                <label for="startDate" class="pt-1">Work Start Date</label>
                                <div class="d-flex justify-content-lg-between align-items-center">
                                    <b-form-datepicker
                                        id="startDate"
                                        name="startDate"
                                        v-model="form.startDate"
                                        :min="new Date()"
                                        :max="form.endDate"
                                        :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                                        start-weekday="1"
                                    />
                                </div>
                            </b-col>
                            <b-col cols="6">
                                <label for="endDate" class="pt-1">Work Finish Date</label>
                                <div class="d-flex justify-content-lg-between align-items-center">
                                    <b-form-datepicker
                                        id="endDate"
                                        name="endDate"
                                        v-model="form.endDate"
                                        :min="form.startDate"
                                        :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                                        start-weekday="1"
                                    />
                                </div>
                            </b-col>
                            <b-col cols="12" class="pt-2" v-if="singleDay">
                                <b-alert variant="warning" show>
                                    <div class="p-1">
                                        Work starts and ends on the same day!
                                    </div>
                                </b-alert>
                            </b-col>
                        </b-row>
                    </b-form-group>
                </validation-observer>
                <b-form-group label="Work Days" class="pb-1" v-if="!singleDay">
                    <b-row>
                        <b-col>
                            <label for="workingDates" class="pt-1">Add a Date</label>
                            <div class="d-flex justify-content-lg-between align-items-center">
                                <b-form-datepicker
                                    id="workingDates"
                                    name="workingDates"
                                    v-model="form.currentWorkingDate"
                                    :min="form.startDate"
                                    :max="form.endDate"
                                    :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                                    start-weekday="1"
                                />
                                <b-button variant="primary" @click="addWorkingDate()" :disabled="!form.currentWorkingDate">ADD</b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-form-group>
                <b-form-group label="Added in schedule" class="pb-1"  v-if="!singleDay && form.allWorkingDates.length">
                    <b-row>
                        <b-col>
                            <b-list-group>
                                <b-list-group-item v-for="(date, index) in form.allWorkingDates"
                                                   :key="index"
                                                   class="d-flex justify-content-between w-100">

                                    <span>
                                    {{ listDate(date)}}
                                    </span>
                                    <feather-icon icon="TrashIcon"
                                                  @click="removeWorkingDate(index)"
                                                    class="feather-icon mini-icon pointer text-primary">
                                    </feather-icon>
                                </b-list-group-item>
                            </b-list-group>
                        </b-col>
                    </b-row>
                </b-form-group>

                <b-button variant="primary" class="mb-1" @click="submitDates()">SAVE DATES</b-button>
                <b-button
                    variant="outline-primary"
                    class="mb-1"
                    @click="cancelModal()"
                >CANCEL
                </b-button>

            </div>
        </div>

    </div>
</template>

<script>
import {
    // ValidationProvider,
    ValidationObserver
} from 'vee-validate';
import {required,} from '@validations';
import {
    BRow,
    BCol,
    // BFormInput,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BAlert,
    BButton,
    BFormDatepicker,
} from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
// import Parse from 'parse';
import store from "@/store";
import moment from "moment/moment";

export default {
    name: "ModalAddSides",
    components: {
        BRow,
        BCol,
        // BFormInput,
        BListGroup,
        BListGroupItem,
        BFormGroup,
        BAlert,
        BButton,
        BFormDatepicker,
        // ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            required,
            locationId: 0,
            form: {
                startDate: null,
                endDate: null,
                currentWorkingDate: null,
                allWorkingDates : [],
            },
        }
    },

    mounted() {
        this.form.startDate = this.currentRole.attributes.startWork;
        this.form.endDate = this.currentRole.attributes.endWork;
    },
    computed: {
        singleDay() {
            return new Date(this.form.startDate).getDate() === new Date(this.form.endDate).getDate();
        },
        currentRole() {
            return store.getters['app/currentRole']
        }
    },
    methods: {
        listDate(parseDate) {
            return moment(parseDate).format("Do, DD-MMM-YYYY");
        },
        removeWorkingDate(index){
            this.form.allWorkingDates.splice(index, 1);
        },
        sortWorkDays(){
            this.form.allWorkingDates.sort((a,b) => {
                return new Date(a).getTime() - new Date(b).getTime();
            })
        },
        addWorkingDate() {
            if(this.form.currentWorkingDate && !this.form.allWorkingDates.includes(this.form.currentWorkingDate)){
                this.form.allWorkingDates.push(this.form.currentWorkingDate);
                this.sortWorkDays();
                this.form.currentWorkingDate = null;
            }
        },

        cancelModal() {
            this.$root.$emit('bv::toggle::collapse', 'cu-addDates');
        },
        submitDates() {
            const self = this;
            if(this.form.startDate){
                this.currentRole.set('startWork', new Date( this.form.startDate));
            }
            if(this.form.endDate){
                this.currentRole.set('endWork', new Date(this.form.endDate));
            }
            if(this.singleDay) {
                this.currentRole.set('workDays', []);
            } else {
                this.currentRole.set('workDays', this.form.allWorkingDates);
            }

            this.currentRole.save().then(() => {
                self.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Dates saved!',
                        icon: 'EditIcon',
                        variant: 'success',
                    },
                });
                store.commit('app/UPDATE_ROLE', self.currentRole);
                //TODO - Check if setting role to this object breaks anything later on
                store.commit('app/SET_ROLE', self.currentRole);

                self.cancelModal();
            }).catch(function (err) {
                self.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Check all fields for valid data!',
                        icon: 'EditIcon',
                        variant: 'danger',
                    },
                });
                console.log('validation errors', err);
            });
        },
    },
    watch: {
        currentRole: {
            immediate: true,
            handler(newRole) {
                if(newRole.id){
                    this.form.startDate = newRole.attributes.startWork;
                    this.form.endDate = newRole.attributes.endWork;
                    if(newRole.attributes.workDays){
                        this.form.allWorkingDates = newRole.attributes.workDays;
                    } else {
                        this.form.allWorkingDates = [];
                    }
                    this.sortWorkDays();
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
