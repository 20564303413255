<template>
    <div>
        <div class="qf-grid" v-if="!listMode">
            <b-row>
                <div v-for="(oneAudition,index) in filteredApplicants" :key="index"
                     class="qf-applicant-auditions"
                    >
                    <b-card no-body class="overflow-hidden p-1">
                        <qf-video
                                :image-url="applicationThumb(oneAudition)"
                                :video-url="applicationVideo(oneAudition)"
                                size="medium"
                        />
                        <div class="d-flex align-items-center mt-1">
                            <b-avatar :src="applicantAvatar(oneAudition)" class="mr-1" size="26px" :text="applicantInitials(oneAudition)" variant="secondary"></b-avatar>
                            <h5 class="mb-0">{{applicantNames(oneAudition)}}</h5>
                        </div>
                        <div class="qf-user-details mt-1"
                             v-b-toggle.reviewAudition
                             @click="setActiveAudition(oneAudition)"
                        >
                            <p class="mb-0">
                                <span v-if="applicantMetaAge(oneAudition)">{{applicantMetaAge(oneAudition)}} yrs</span>
                                <span v-if="applicantMetaGender(oneAudition)"> | {{applicantMetaGender(oneAudition)}}</span>
                                <span v-if="applicantMetaHigh(oneAudition)"> | {{applicantMetaHigh(oneAudition)}} cm</span></p>
                            <br/>
                            <!--                        <p class="mb-1">Sofia, Bulgaria (153km)</p>-->
                        </div>
                        <div class="qf-vote-wrapp">
                            <div class="qf-positive-vote-wrapp d-flex">
                                <feather-icon icon="ThumbsUpIcon"
                                              size="18"
                                />
                                <p class="qf-counter mb-0">{{votesFor(oneAudition)}}</p>
                            </div>
                            <div class="qf-negative-vote-wrapp d-flex">
                                <feather-icon icon="ThumbsDownIcon"
                                              size="18"
                                />
                              <!--                                              class="align-text-center "-->
                                <p class="qf-counter mb-0">{{votesAgainst(oneAudition) }}</p>
                            </div>
                          <b-progress class="mt-2" max="100">
                            <b-progress-bar :value="100 * (votesForCoefficient(oneAudition))" variant="success"></b-progress-bar>
                            <b-progress-bar :value="100 * (votesAgainstCoefficient(oneAudition))" variant="danger"></b-progress-bar>
                          </b-progress>
                        </div>
                    </b-card>
                </div>
            </b-row>
        </div>
        <div class="qf-list" v-if="listMode">
            <b-card no-body class="px-2">
                <div    v-for="(oneAudition,index) in filteredApplicants"
                        :key="index"
                >
                    <div v-if="isVisible(oneAudition)" class="border-bottom d-flex align-items-center qf-user-wrap py-2" :class="oneAudition.attributes.isArchived ? 'qf-archived':''">
                        <div class="d-flex align-items-center user-width"
                             v-b-toggle.reviewAudition
                             @click="setActiveAudition(oneAudition)">
                            <b-avatar v-if="isShortlisted(oneAudition)"
                                      badge-variant=""
                                      badge-top
                                      badge-offset="-0.5em"
                                      v-b-tooltip.hover
                                      title="Shortlisted for role"
                                      :src="applicantAvatar(oneAudition)"
                                      class="mr-1" size="56px" :text="applicantInitials(oneAudition)">
                                <template #badge>
                                    <div class="shortlisted-mark">
                                        <feather-icon icon="CheckIcon"/>
                                    </div>
                                </template>
                            </b-avatar>
                            <b-avatar v-else :src="applicantAvatar(oneAudition)" class="mr-1" size="56px" :text="applicantInitials(oneAudition)"></b-avatar>

                            <div class="qf-user-details ">
                                <h5 class="mb-0">{{applicantNames(oneAudition)}}</h5>
                                <p class="font-small-2 mt-0 mb-0 text-secondary"
                                   v-if="applicantSourceID(oneAudition)"
                                   v-b-tooltip.hover
                                   :title="`Soruce: `+applicantSourceNames(oneAudition)"
                                >{{applicantSourceID(oneAudition)}}</p>
                                <p class="font-small-3">{{oneAudition.attributes.auditionLabel}}</p>

                            </div>
                        </div>
                        <div class="qf-aplicant-video d-flex align-items-center">
                            <qf-video :image-url="applicationThumb(oneAudition)"
                                      :video-url="applicationVideo(oneAudition)"
                                      size="small"
                                      :avatar-url="applicantAvatar(oneAudition)"
                                      :user-names="oneAudition.attributes.relatedUser.attributes.firstName + ' ' + oneAudition.attributes.relatedUser.attributes.lastName"
                                      :disabled="oneAudition.attributes.videoStatus === 'pending'"
                            />
                        </div>
                        <div class="qf-vote-wrapp user-details-widthml-5 qf-vote-width">
                            <div class="qf-positive-vote-wrapp d-flex">
                                <feather-icon icon="ThumbsUpIcon"
                                              size="18"
                                              @click="vote('up', oneAudition)"
                                              :class="thumbClasses('up', oneAudition)"/>
                                <span class="qf-counter mb-0">{{votesFor(oneAudition)}}</span>
                            </div>
                            <div class="qf-negative-vote-wrapp d-flex">
                                <span class="qf-counter mb-0">{{votesAgainst(oneAudition)}}</span>
                                <feather-icon icon="ThumbsDownIcon"
                                              size="18"
                                              @click="vote('down', oneAudition)"
                                              :class="thumbClasses('down', oneAudition)"/>
                            </div>
                            <b-progress class="mt-2" max="100">
                                <b-progress-bar :value="100 * (votesForCoefficient(oneAudition))" variant="success"></b-progress-bar>
                                <b-progress-bar :value="100 * (votesAgainstCoefficient(oneAudition))" variant="danger"></b-progress-bar>
                            </b-progress>
                            <div class="qf-positive-vote-wrapp">
                                <b-avatar-group size="20px"
                                                class="d-flex"
                                                v-if="oneAudition.attributes.votedFor" >
                                    <b-avatar
                                        v-for="voter in oneAudition.attributes.votedFor"
                                        :key="voter.id"
                                        v-b-tooltip.hover
                                        class="pull-up"
                                        :title="voter.attributes.firstName + ' ' + voter.attributes.lastName"
                                        :src="voterAvatar(voter)"
                                    ></b-avatar>
                                </b-avatar-group>
                            </div>
                            <div class="qf-negative-vote-wrapp">
                                <b-avatar-group class="d-flex justify-content-end"
                                                size="20px"
                                                v-if="oneAudition.attributes.votedAgainst">
                                    <b-avatar
                                        v-for="voter in oneAudition.attributes.votedAgainst"
                                        :key="voter.id"
                                        v-b-tooltip.hover
                                        class="pull-up"
                                        :title="voter.attributes.firstName + ' ' + voter.attributes.lastName"
                                        :src="voterAvatar(voter)"
                                    ></b-avatar>
                                </b-avatar-group>
                            </div>
                        </div>
                        <div class="pl-1">
                            <b-dropdown
                                right
                                size="sm"
                                variant="flat-primary"
                                class="dropdown-icon-wrapper qf-options">
                                <template #button-content>
                                    <feather-icon class="text-success" data-cy="moreOptions" icon="MoreVerticalIcon" size="18"/>
                                </template>
                                <b-dropdown-item v-if="!isShortlisted(oneAudition) && !isArchived(oneAudition)" @click="toShortlist(oneAudition)" data-cy="removeMember" variant="outline-outline-{success}">
                                    Shortlist
                                </b-dropdown-item>
                                <b-dropdown-item v-if="isShortlisted(oneAudition) && !isArchived(oneAudition)" v-b-modal.remove-modal @click="shortlistModal(oneAudition)" data-cy="removeMember" variant="outline-outline-{success}">
                                    Remove from Shortlist
                                </b-dropdown-item>
                                <b-dropdown-item data-cy="scheduleLiveAud" v-if="!isArchived(oneAudition) && alreadyScheduled(oneAudition) === false" @click="openLiveModal(oneAudition)" variant="outline-outline-{success}">
                                    Schedule Zoom Audition
                                </b-dropdown-item>
                                <b-dropdown-item data-cy="rescheduleLiveAud" v-if="!isArchived(oneAudition) && alreadyScheduled(oneAudition) === true" @click="openLiveModal(oneAudition)" variant="outline-outline-{success}">
                                    Reschedule Zoom Audition
                                </b-dropdown-item>
                                <b-dropdown-item data-cy="archiveAud" v-if="!isArchived(oneAudition)" @click="archiveAudition(oneAudition)" variant="outline-outline-{success}">
                                    Archive Audition
                                </b-dropdown-item>
                                <b-dropdown-item data-cy="unarchiveAud" v-if="isArchived(oneAudition)" @click="unarchiveAudition(oneAudition)" variant="outline-outline-{success}">
                                    Remove from Archived
                                </b-dropdown-item>
                                <b-dropdown-item data-cy="deleteAud" @click="deleteAudition(oneAudition)" variant="outline-outline-{success}">
                                    Delete Audition
                                </b-dropdown-item>
                                <!--                      <b-dropdown-item data-cy="scheduleLiveAud" v-if="alreadyScheduled(oneAudition) === false && zoomIntegration" @click="openLiveModal(oneAudition)" v-b-modal.sidebar-scheduleLive variant="outline-outline-{success}">-->
                                <!--                        Schedule Live Audition-->
                                <!--                      </b-dropdown-item>-->
                                <!--                      <b-dropdown-item data-cy="rescheduleLiveAud" v-if="alreadyScheduled(oneAudition) === true && zoomIntegration" @click="openLiveModal(oneAudition)" v-b-modal.sidebar-scheduleLive variant="outline-outline-{success}">-->
                                <!--                        Reschedule Live Audition-->
                                <!--                      </b-dropdown-item>-->
                                <b-dropdown-item v-b-modal.modal-recast v-if="!isShortlisted(oneAudition) && !isArchived(oneAudition)" @click="openModal(oneAudition)" data-cy="recast" variant="outline-outline-{success}">
                                    Recast for another role
                                </b-dropdown-item>
                                <b-dropdown-item v-if="isShortlisted(oneAudition) && !isArchived(oneAudition)" disabled data-cy="recast" variant="outline-outline-{success}">
                                    Recast for another role
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </div>

                </div>
            </b-card>
        </div>



        <!-- MODALS -->
        <b-modal
            id="remove-modal"
            ok-variant="danger"
            ok-title="Remove"
            cancel-title="Close"
            cancel-variant="outline-secondary"
            modal-class="modal-danger"
            size="sm"
            button-size="sm"
            centered
            title="Remove from shortlisted"
            @ok="removeShortlist()"
        >
          <b-card-text>
            Are you sure you want to remove {{auditionToRemove.attributes.relatedUser.attributes.firstName}} {{auditionToRemove.attributes.relatedUser.attributes.lastName}}'s audition from shortlisted?
          </b-card-text>
        </b-modal>
        <b-modal
            id="modal-recast"
            ref="recast-modal"
            title="Recast For Another Role"
            ok-title="Recast"
            @ok="saveNewRole(talentToRecast)"
        >
          <div>
            <label>Select from the roles below:</label>
            <b-form-select
                id="recastRole"
                name="recastRole"
                :options="allRoles"
                v-model="newRoleId"
            >
              <template #first>
                <b-form-select-option value="null" disabled
                >Pick one</b-form-select-option
                >
              </template>
            </b-form-select>
          </div>
        </b-modal>
      <validation-observer ref="auditionValidation" v-slot="{ passes }">
        <b-modal
        id="sidebar-scheduleLive"
        ref="schedule-modal"
        title="Schedule Live Audition"
        :ok-title="saveLabel"
        @ok="submitInvitation"
        @ok.prevent="passes()"
        data-cy="submitInvitation"
        >
        <div>
          <b-row class="pb-1">
            <b-col cols="12">
              <validation-provider
                  v-slot="{ errors }"
                  name="meeting name"
                  rules="required"
              >
                <label>Meeting name:</label>
                <b-form-input
                    name="zoomName"
                    id="idZoomName"
                    v-model="zoomName"
                    placeholder="Zoom meeting name"
                    type="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="pb-1">
            <b-col cols="6">
              <validation-provider
                  v-slot="{ errors }"
                  name="audition date"
                  rules="required"
              >
                <label>Pick a date:</label>
                <b-form-datepicker
                    id="idScheduleAudition"
                    name="scheduleAudition"
                    v-model="form.auditionDate"
                    :min="new Date()"
                    :date-format-options="{
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  weekday: 'short',
                }"
                    :localе="`en-UK`"
                    :start-weekday="1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <validation-provider
                  v-slot="{ errors }"
                  name="audition time"
                  rules="required"
              >
                <label>Set a time:</label>
                <b-form-timepicker
                    id="idScheduleTimeAudition"
                    name="scheduleTimeAudition"
                    v-model="form.auditionTime"
                    :min="new Date()"
                    locale="en"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="pb-1">
            <b-col cols="6">
              <validation-provider
                  v-slot="{ errors }"
                  name="meeting duration"
                  rules="required"
              >
                <label>Meeting duration (minutes):</label>
                <b-form-input
                    name="zoomDuration"
                    id="idZoomDuration"
                    v-model="form.zoomDuration"
                    placeholder="30"
                    type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <validation-provider
                  v-slot="{ errors }"
                  name="meeting password"
                  rules="required"
              >
                <label>Meeting password:</label>
                <b-form-input
                    name="zoomPassword"
                    id="idZoomPassword"
                    v-model="form.zoomPassword"
                    placeholder="123456"
                    type="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label>Note:</label>
              <b-form-textarea
                  id="idNote"
                  name="note"
                  v-model="form.note"
                  placeholder="Add a note to your invitation for a live audition"
                  rows="3"
              >
              </b-form-textarea>
            </b-col>
          </b-row>
        </div>
    </b-modal>
      </validation-observer>

        <validation-observer ref="zoomIntegrationValidation" v-slot="{ passes }">
        <b-modal
          id="sidebar-zoomIntegration"
          ref="zoom-modal"
          title="Set Up Zoom"
          ok-title="Save"
          @ok="submitZoom"
          @ok.prevent="passes()"
          data-cy="submitInvitation"
      >
        <div>
          <b-row class="pb-1">
            <b-col cols="12">
              <b-form-group label="Zoom API Key:">
                <validation-provider
                    v-slot="{ errors }"
                    name="api key"
                    rules="required"
                >
                  <b-form-input
                      name="zoomApiKey"
                      id="idZoomApiKey"
                      v-model="integrationForm.zoomApiKey"
                      placeholder="Zoom API Key"
                      type="text"
                  />
                  <a href="https://community.zoom.com/t5/Marketplace/How-do-I-get-API-Key-amp-API-Secret/m-p/28307#M405" target="_blank">Need help?</a>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Zoom Secret Key:">

                <validation-provider
                    v-slot="{ errors }"
                    name="secret key"
                    rules="required"
                >
                  <div class="d-flex justify-content-lg-between align-items-center">
                  <b-form-input
                      name="zoomSecretKey"
                      id="idZoomSecretKey"
                      v-model="integrationForm.zoomSecretKey"
                      placeholder="Zoom Secret Key"
                      type="text"
                  />
                  </div>
                  <a href="https://community.zoom.com/t5/Marketplace/How-do-I-get-API-Key-amp-API-Secret/m-p/28307#M405" target="_blank">Need help?</a>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              After generating the credentials above you can set up your webhook:
              <ol >
                <li>Go to <a class="font-weight-bold" href="https://marketplace.zoom.us/" target="_blank">https://marketplace.zoom.us/</a> and sign in</li>
                <li>After you are signed in go to <a class="font-weight-bold" href="https://marketplace.zoom.us/user/build" target="_blank">https://marketplace.zoom.us/user/build</a></li>
                <li>Choose the billing company for the current production</li>
                <li>Click on "Feature" in the left vertical menu</li>
                <li>Turn on "Event Subscriptions"</li>
                <li>Click on "+ Add Event Subscription"</li>
                <li>In the input field "Event notification endpoint URL" add: "{{ webhookUrl }}"</li>
                <li>Then click on "+ Add Events" -> "Recording" -> "All Recordings have completed" -> "Done" </li>
                <li>Click on "Save"</li>
              </ol>
            </b-col>
          </b-row>
        </div>
      </b-modal>
        </validation-observer>

        <b-sidebar
            id="reviewAudition"
            right
            backdrop
            shadow
            width="450px"
            sidebar-class="sidebar-lg"
            bg-variant="white"
        >
            <template #header="{ hide }">
                <div class="d-flex justify-content-between align-items-center content-sidebar-header w-100">
                    <div>
                        <feather-icon
                            class="ml-1 cursor-pointer"
                            icon="XIcon"
                            size="16"
                            @click="hide"
                        />
                    </div>
                </div>
            </template>
            <modal-review-audition></modal-review-audition>
        </b-sidebar>
    </div>
</template>

<script>
import QfVideo from "../layouts/components/QfVideo";
import store from "@/store";
import { required } from "@validations";
import ModalReviewAudition from "@/modals/ModalReviewAudition";
import {
  BSidebar,
  BCard,
  //BBadge,
  //BIcon,
  BRow,
  BAvatar,
  BAvatarGroup,
  BProgress,
  BProgressBar,
  BModal,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BFormSelectOption,
  BCardText,
  BCol,
  BFormDatepicker,
  BFormTimepicker,
  BFormTextarea,
  BFormInput,
  BFormGroup,
  VBToggle,
  VBTooltip, VBModal,
//    BButton,
} from 'bootstrap-vue'
import Parse from "parse";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import Api from "@/services/api";
import Api2 from "@/services/api2";
import moment from "moment-timezone";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        ModalReviewAudition,
        BSidebar,
        BCard,
        BFormGroup,
        //BBadge,
        //BIcon,
        BRow,
        BAvatar,
        BAvatarGroup,
        BProgress,
        BProgressBar,
        QfVideo,
        BDropdown,
        BDropdownItem,
        BModal,
        BFormSelect,
        BFormSelectOption,
        BCardText,
        BCol,
        BFormDatepicker,
        BFormTimepicker,
        BFormTextarea,
        BFormInput
//        BButton,
    },
    data() {
      return {
        required,
        shortlistedForRole: [],
        auditionToSchedule: {
          attributes: {}
        },
        auditionToRemove: {
          attributes: {
            relatedUser: {
              attributes: {}
            }
          }
        },
        talentToRecast: {
          attributes: {}
        },
        oneAudition: {
          attributes: {}
        },
        newRoleId: "",
        form: {
          auditionDate: null,
          auditionTime: null,
          note: null,
          zoomLink: "",
          zoomPassword: "",
          zoomDuration: "",
        },
        integrationForm: {
          zoomApiKey: '',
          zoomSecretKey: '',
        },
        webhookUrl: process.env.VUE_APP_API_URL + "zoom",
        curUser: null,
      }
    },
    directives: {
        'b-toggle': VBToggle,
        'b-tooltip': VBTooltip,
        'b-modal': VBModal,
    },
    props: {
        showArchived: {
          type: Boolean,
          default(){
            return false;
          }
        },
        shortlistedOnly: {
            type: Boolean,
            default(){
                return false;
            }
        },
        applicants: {
            type: Array,
        },
        listMode: {
            type: Boolean,
            default(){
                return true;
            }
        },
    },
    mounted() {
      this.curUser = Parse.User.current();
    },
    computed: {
      curProd() {
        return store.getters['app/currentProduction'];
      },
      zoomSecretKey(){
        if(this.curProd && this.curProd.attributes.billingCompany){
          return this.curProd.attributes.billingCompany.attributes.zoomSecretKey;
        }else{
          return '';
        }
      },
      zoomApiKey(){
        if(this.curProd && this.curProd.attributes.billingCompany){
          return this.curProd.attributes.billingCompany.attributes.zoomApiKey;
        }else{
          return '';
        }
      },
      zoomIntegration(){
        return this.zoomApiKey && this.zoomSecretKey;
      },
      liveAudition() {
          if (
              this.liveAuditions &&
              this.auditionToSchedule.attributes &&
              this.auditionToSchedule.attributes.relatedUser
          ) {
            return this.liveAuditions.find(
                (o) =>
                    o.attributes.relatedUser.id ===
                    this.auditionToSchedule.attributes.relatedUser.id
            );
          } else {
            return {};
          }
        },
        saveLabel() {
          if (this.liveAudition) {
            return "Reschedule";
          } else {
            return "Schedule";
          }
        },
        liveAuditions() {
          return store.getters["app/allLiveAuditions"];
        },
        members(){
            return store.getters['app/allMembers']
        },
        curRole() {
            return store.getters['app/currentRole'];
        },
        allRoles() {
          let roles = store.getters["app/allRoles"];
          let prodRoles = [];
          if (roles) {
            roles.forEach(function (prodRole) {
              prodRoles.push({
                value: prodRole.id,
                text: prodRole.attributes.name,
              });
            });
          }
          return prodRoles;
        },
        filteredApplicants(){
          let self = this;
            if(this.shortlistedOnly){
                if(this.applicants.length){
                    if(self.curRole.attributes.shortlistedTalent){
                        let filteredResults = this.applicants.filter(
                            function(obj){
                                return self.curRole.attributes.shortlistedTalent.includes(obj.attributes.relatedUser.id);
                            }
                        );
                        return filteredResults;
                    }else {
                        return this.applicants;
                    }
                }else {
                    return [];
                }
            } else {
                return this.applicants;
            }
          /*
            let self = this;
            if(this.showArchived){
              if(this.applicants.length){
                let filteredResults = this.applicants.filter((o) => {
                    debugger;
                  return o.attributes.isArchived;
                });
                return filteredResults;
              }else {
                return [];
              }
            }else else{
              return returnBasic();
            }
            function returnBasic(){
              return self.applicants.filter((o) => {
                return !o.attributes.isArchived;
              });
            }

           */

        },
        zoomName(){
          return `Live Audition: ${this.auditionToSchedule.attributes.relatedUser.attributes.firstName} for the role of ${this.curRole.attributes.name}`;
        },
    },
    methods: {
        isVisible(oneAudition){
            if(
                (this.showArchived && oneAudition.attributes && oneAudition.attributes.isArchived) ||
                (oneAudition.attributes && !oneAudition.attributes.isArchived)
            ){
                return true;
            }else {
                return false;
            }
        },
        deleteAudition(audition){
            let self = this;
            let payload = {
                auditionId: audition.id,
                sessionToken: self.curUser.attributes.sessionToken
            }

            Api2.sendData('deleteAudition', payload).then(() => {
                self.$emit('reload');
                self.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Audition deleted successfully!",
                        icon: "Trash2Icon",
                        variant: "success",
                    }
                });
            });
        },
        isArchived(oneAudition){
          return oneAudition.attributes.isArchived;
        },
        archiveAudition(audition){
          audition.set('isArchived', true);
          audition.save().then(() => {
              this.$toast({
                  component: ToastificationContent,
                  props: {
                      title: "Audition archived!",
                      icon: "ArchiveIcon",
                      variant: "success",
                  }
              });
              this.$emit('reload');
          });
          this.$emit('reload');
        },
        unarchiveAudition(audition){
          audition.set('isArchived', false);
          audition.save().then(() => {
              this.$toast({
                  component: ToastificationContent,
                  props: {
                      title: "Audition restored!",
                      icon: "CheckIcon",
                      variant: "success",
                  },
              });
              this.$emit('reload');
          });

        },
        submitZoom(){
          let self = this;
          this.$refs.zoomIntegrationValidation
              .validate()
              .then((success) => {
                if (success) {
                  self.curProd.attributes.billingCompany.set('zoomApiKey', self.integrationForm.zoomApiKey);
                  self.curProd.attributes.billingCompany.set('zoomSecretKey', self.integrationForm.zoomSecretKey);
                  self.curProd.attributes.billingCompany.save().then(function(){
                    self.$toast({
                      component: ToastificationContent,
                      props: {
                        title: "Zoom credentials have been saved successfully!",
                        icon: "EditIcon",
                        variant: "success",
                      },
                    });
                  });
                  this.$store.commit('app/SET_PRODUCTION', self.curProd);
                  this.$refs['zoom-modal'].hide();
                }
              })
              .catch(function (err) {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Check all fields for valid data!",
                    icon: "EditIcon",
                    variant: "danger",
                  },
                });
                console.log("validation errors", err);
              });
        },
        votesForCoefficient(oneAudition) {
          if (oneAudition.attributes.votedFor && oneAudition.attributes.votedFor.length) {
            let voteForCoefficient = oneAudition.attributes.votedFor.length;
            return voteForCoefficient;
          }
          else {
            return 0;
          }
        },
        votesAgainstCoefficient(oneAudition) {
          if(oneAudition.attributes.votedAgainst && oneAudition.attributes.votedAgainst.length) {
            let voteAgainstCoefficient = oneAudition.attributes.votedAgainst.length;
            return voteAgainstCoefficient;
          }
          else {
            return 0;
          }
        },
        alreadyScheduled(selectedAudition){
          if (
              selectedAudition.attributes.relatedUser &&
              this.liveAuditions.find(
                  (o) => o.attributes.relatedUser.id === selectedAudition.attributes.relatedUser.id
              ) &&
              this.liveAuditions.find(
                  (o) => o.attributes.relatedRole.id === selectedAudition.attributes.relatedRole.id
              )
          ) {
            return true;
          } else {
            return false;
          }
        },
        openLiveModal(selectedAudition){
          const billingCompany = this.curProd.attributes.billingCompany;
          if(billingCompany && billingCompany.attributes.zoomApiKey && billingCompany.attributes.zoomSecretKey){
            this.auditionToSchedule = selectedAudition;
            this.$bvModal.show('sidebar-scheduleLive');
          }else{
            this.$bvModal.show('sidebar-zoomIntegration')
          }
        },
        submitInvitation() {
          //ev.preventDefault();

          let self = this;
          this.$refs.auditionValidation
              .validate()
              .then((success) => {
                if (success) {
                  self.sendAuditionInvite();
                  this.$refs['schedule-modal'].hide();
                }
              })
              .catch(function (err) {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Check all fields for valid data!",
                    icon: "EditIcon",
                    variant: "danger",
                  },
                });
                console.log("validation errors", err);
              });
        },
        async sendAuditionInvite() {
          let formData = new FormData();
          this.form.zoomName = this.zoomName;
          this.form.zoomApiKey = this.zoomApiKey;
          this.form.zoomSecretKey = this.zoomSecretKey;
          formData.append("action", "createZoomMeeting");
          formData.append("data", JSON.stringify(this.form));
          let res = await Api().post("helpers/api.php", formData);

          // debugger;
          let self = this;
          let AuditionObj = Parse.Object.extend("LiveAudition");
          let newAudition = new AuditionObj();

          const newRolePointer = {
            __type: "Pointer",
            className: "ActingRole",
            objectId: this.curRole.id,
          };
          const userPointer = {
            __type: "Pointer",
            className: "_User",
            objectId: this.auditionToSchedule.attributes.relatedUser.id,
          };

          if (this.liveAudition && this.liveAudition.id) {
            newAudition.set("objectId", this.liveAudition.id);
          }
          newAudition.set("relatedRole", newRolePointer);
          newAudition.set("relatedUser", userPointer);
          if (this.form.note) {
            newAudition.set("note", this.form.note);
          }
          newAudition.set("zoomLink", res.data.join_url);
          newAudition.set("zoomUuid", res.data.uuid);
          newAudition.set("zoom", res.data);
          let dateForAud = this.form.auditionDate.substring(0, 10);
          newAudition.set(
              "auditionDate",
              new Date(dateForAud + "T" + this.form.auditionTime + "Z")
          );

          newAudition
              .save()
              .then(function (newAuditionSaved) {
                newAuditionSaved
                    .fetchWithInclude("relatedUser")
                    .then(function (updatedAudItem) {
                      formData = new FormData();
                      let mailData = {
                        user: updatedAudItem.attributes.relatedUser,
                        role: newAuditionSaved,
                        audition: updatedAudItem,
                        zoomLink: res.data.join_url
                      };
                      formData.append("action", "sendLiveInvite");
                      formData.append("data", JSON.stringify(mailData));
                      //debugger;
                      Api()
                          .post("helpers/api.php", formData)
                          .then(function () {
                            self.$toast({
                              component: ToastificationContent,
                              props: {
                                title: "Live audition has been scheduled successfully!",
                                icon: "EditIcon",
                                variant: "success",
                              },
                            });
                          });

                      if (!self.liveAudition) {
                        store.commit("app/SET_LIVE_AUDITION", updatedAudItem);
                        store.commit("app/UPDATE_LIVE_AUDITIONS", updatedAudItem);
                      } else {
                        store.commit("app/UPDATE_LIVE_AUDITIONS", updatedAudItem);
                      }
                    });
                self.$root.$emit("bv::toggle::collapse", "sidebar-scheduleLive");
                self.$refs.auditionValidation.reset();

              })
              .catch(function (err) {
                console.log(err);
                console.log("Audition save err: ", err.message);
              });
      },
        shortlistModal(selectedAudition){
          this.auditionToRemove = selectedAudition;
        },
        removeShortlist(){
          this.shortlistedForRole = this.curRole.attributes.shortlistedTalent;
          let curAuditionIndex = this.shortlistedForRole.indexOf(this.auditionToRemove.attributes.relatedUser.id);
          console.log("selected audition index in array: ", curAuditionIndex)
          this.curRole.remove('shortlistedTalent', this.auditionToRemove.attributes.relatedUser.id);
          this.curRole.save().then(function(saveResult){

            store.commit('app/REMOVE_SHORTLISTED', curAuditionIndex);
            store.commit('app/UPDATE_ROLE', saveResult);

          }).catch(function(err){
            console.log("Err on save: ", err);
          });
        },
        openModal(selectedAudition){
          this.talentToRecast = selectedAudition;
        },
        saveNewRole(selectedAudition) {
          let self = this;
          //                const newRole = Parse.Object.extend("Application");
          const newRolePointer = {
            __type: "Pointer",
            className: "ActingRole",
            objectId: this.newRoleId,
          };

          selectedAudition.set("relatedRole", newRolePointer);

          selectedAudition
              .save()
              .then(function (updatedApplication) {
                store.commit("app/SET_AUDITION", updatedApplication);
                store.commit("app/UPDATE_APPLICANTS", updatedApplication);
                store.commit("app/REMOVE_APPLICANT", updatedApplication);
                console.log("Updated audition: ", updatedApplication);
                self.$bvModal.hide('modal-recast');
                self.newRoleId = null;
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title:
                        "The actor has been invited to a live audition successfully!",
                    icon: "EditIcon",
                    variant: "success",
                    duration: 5000,
                  },
                });
              })
              .catch(function (err) {
                console.log("err on save", err);
              });
        },
        isShortlisted(audition){
          if( audition.attributes &&
              audition.attributes.relatedUser &&
              this.curRole &&
              this.curRole.attributes.shortlistedTalent &&
              this.curRole.attributes.shortlistedTalent.includes(audition.attributes.relatedUser.id)) {
            return true;
          }
          else{
            return false;
          }
        },
        toShortlist(selectedAudition){
          this.oneAudition = selectedAudition;
          this.makeShortlisted(selectedAudition);
        },
        makeShortlisted(selectedAudition) {
          let self = this;
          let applicant = selectedAudition.attributes.relatedUser;
          let curRole = store.getters["app/currentRole"];
          curRole.addUnique("shortlistedTalent", applicant.id);
          curRole
              .save()
              .then(function () {
                let curTalents = store.getters["app/shortlistedTalents"];
                curTalents[curRole.id].push(applicant);
                //store.commit('app/SET_ROLE');
                store.commit("app/ADD_SHORTLISTED", {
                  roleUsers: curTalents,
                  roleId: curRole.id,
                });
                store.commit("app/REFRESH_ROLES");
              })
              .catch(function (err) {
                self.$toast({
                  component: ToastificationContent,
                  props: {
                    title: err.message,
                    icon: "XIcon",
                    variant: "danger",
                  },
                });
              });
        },
        alreadyVoted(selectedAudition) {
          let curUser = Parse.User.current();
          let voteUp = selectedAudition.attributes.votedFor;
          //let voteAgainst = selectedAudition.attributes.votedAgainst
          if (selectedAudition.attributes.votedFor && selectedAudition.attributes.votedFor.length && selectedAudition.attributes.votedFor.length > 0 && voteUp.find((obj) => {
            return obj.id === curUser.id;
          })) {
            // voteUp = selectedAudition.attributes.votedFor.find((obj) => {
            //   return obj.id === curUser.id;
            // });

            if (voteUp) {
              return "up";
            } else {
              return false;
            }
          } else if (selectedAudition.attributes.votedAgainst && selectedAudition.attributes.votedAgainst.length && selectedAudition.attributes.votedAgainst.length > 0) {
            let voteAgainst = selectedAudition.attributes.votedAgainst.find(
                (obj) => {
                  return obj.id === curUser.id;
                }
            );
            if (voteAgainst) {
              return "down";
            } else {
              return false;
            }
          } else {
            return false;
          }
        },
        thumbClasses(thumbDirection, selectedAudition) {
          if (thumbDirection === this.alreadyVoted(selectedAudition) && this.alreadyVoted(selectedAudition) === 'up') {
            return "text-success mr-05 cursor-pointer";
          }
          else if (thumbDirection === this.alreadyVoted(selectedAudition) && this.alreadyVoted(selectedAudition) === 'down') {
            return "text-danger mr-05 cursor-pointer";
          }
          else {
            return "text-secondary mr-05 cursor-pointer";
          }
        },
        vote(voteType, selectedAudition) {
          store.commit('app/SET_AUDITION', selectedAudition);
          let curUser = Parse.User.current();
          if (this.alreadyVoted(selectedAudition)) {
            selectedAudition.remove("votedAgainst", curUser);
            selectedAudition.remove("votedFor", curUser);
          }
          else {
            if (voteType === "up" && this.alreadyVoted(selectedAudition) !== "up") {
              selectedAudition.remove("votedAgainst", curUser);
              selectedAudition.addUnique("votedFor", curUser);
              console.log(this.alreadyVoted((selectedAudition)));
            } else if (voteType === "down" && this.alreadyVoted(selectedAudition) !== "down") {
              selectedAudition.remove("votedFor", curUser);
              selectedAudition.addUnique("votedAgainst", curUser);
              console.log(this.alreadyVoted(selectedAudition));
            }
          }

          selectedAudition
              .save()
              .then(function (updatedApplication) {
                store.commit("app/SET_AUDITION", updatedApplication);
                store.commit("app/UPDATE_APPLICANTS", updatedApplication);
                console.log("Updated audition: ", updatedApplication);
              })
              .catch(function (err) {
                console.log("err on save", err);
              });
        },
        voterAvatar(voter){
           if(voter.attributes.avatar){
               return voter.attributes.avatar._url;
           }
           else{
               return this.defaultLogo('png');
           }
        },
        setActiveAudition(audition){
            //console.log("Active audition: ", audition);
            store.commit('app/SET_AUDITION', audition);
        },
        votesPercentage(audition){
            let voteRatio = 0;
            if(audition.attributes.votedFor && audition.attributes.votedFor.length){
                voteRatio = 100;
            }
            if(audition.attributes.votedFor && audition.attributes.votedFor.length && audition.attributes.votedAgainst && audition.attributes.votedAgainst.length){
                voteRatio = (audition.attributes.votedFor.length / audition.attributes.votedAgainst.length)*100;
            }
            return voteRatio;
        },
        applicationThumb(audition){
            if(audition.attributes.attachmentThumb){
                return audition.attributes.attachmentThumb._url;
            }else{
                return this.defaultLogo('png');
            }
        },
        applicationVideo(audition){
            if(audition.attributes.auditionFile){
                return audition.attributes.auditionFile._url;
            }else{
                return '';
            }
        },
        votesFor(audition){
            let votesFor = 0;
            if(audition.attributes.votedFor){
                votesFor = audition.attributes.votedFor.length;
                return votesFor;
            }
            else{
                return '0'
            }
        },
        votesAgainst(audition){
            let votesAgainst = 0;
            if(audition.attributes.votedAgainst){
                votesAgainst = audition.attributes.votedAgainst.length;
                return votesAgainst;
            }
            else{
                return '0'
            }
        },
        applicantNames(audition){
            if(audition.attributes.relatedUser){
                return audition.attributes.relatedUser.attributes.firstName + " " + audition.attributes.relatedUser.attributes.lastName;
            }else{
                return "";
            }
        },
        applicantSourceNames(audition){
            if(audition.attributes.relatedUser && audition.attributes.relatedUser.attributes.relatedSource){
                return audition.attributes.relatedUser.attributes.relatedSource.attributes.name;
            }else{
                return "";
            }
        },
        applicantSourceID(audition){
            if(audition.attributes.relatedUser ){
                return audition.attributes.relatedUser.attributes.sourceID;
            }else{
                return "";
            }
        },
        applicantInitials(audition){
            if(audition.attributes.relatedUser){
                return audition.attributes.relatedUser.attributes.firstName.substring(0,1) + audition.attributes.relatedUser.attributes.lastName.substring(0,1);
            }else{
                return "";
            }
        },
        applicantAvatar(audition){
            if(audition.attributes.relatedUser && audition.attributes.relatedUser.attributes.avatar){
                return audition.attributes.relatedUser.attributes.avatar._url;
            }else{
                return "";
            }
        },
        applicantVideo(audition){
            if(audition.attributes.relatedUser && audition.attributes.auditionFile){
                return audition.attributes.auditionFile._url;
            }else{
                return "";
            }
        },
        applicantMetaAge(audition){
            if(audition.attributes.relatedUser && audition.attributes.relatedUser.attributes.relatedMeta){
                return audition.attributes.relatedUser.attributes.relatedMeta.attributes.age;
            }else{
                return "";
            }
        },
        applicantMetaHigh(audition){
            if(audition.attributes.relatedUser && audition.attributes.relatedUser.attributes.relatedMeta){
                return audition.attributes.relatedUser.attributes.relatedMeta.attributes.height;
            }else{
                return "";
            }
        },
        applicantMetaGender(audition){
            if(audition.attributes.relatedUser && audition.attributes.relatedUser.attributes.relatedMeta){
                return audition.attributes.relatedUser.attributes.relatedMeta.attributes.gender;
            }else{
                return "";
            }
        },
        getShortlisted(roleObj) {
          let userQuery = new Parse.Query(Parse.User);
          userQuery.containedIn("objectId", roleObj.attributes.shortlistedTalent);
          userQuery.include("relatedMeta");
          userQuery.find().then(function (users) {
            store.commit("app/ADD_SHORTLISTED", {
              roleUsers: users,
              roleId: roleObj.id,
            });
          });
          //store.commit("app/REFRESH_ROLES");
        },
    },
    watch: {
      curRole: {
        deep: true,
        immediate: true,
        handler(newRole) {
          this.oneAudition.attributes.relatedRole = newRole;
          if (newRole) {
            this.getShortlisted(newRole);
          }
        },
      },
      liveAudition: {
        immediate: true,
        handler(newLiveAudition) {
          if (newLiveAudition && newLiveAudition.attributes) {
            //debugger;
            this.form = JSON.parse(JSON.stringify(newLiveAudition.attributes));
            this.form.auditionTime = this.form.auditionDate.substring(11, 19);
          } else {
            //this.liveAuditionId = null;
            this.form = {
              zoomDuration: 30,
              auditionDate: moment(new Date()).format('YYYY-MM-D'),
              auditionTime: "08:00",
              note: null,
              zoomLink: "",
              //zoomName: this.applicantNames + ' as '+newLiveAudition.attributes.relatedRole.attributes.name
            };
          }
        },
      },
    }
}


</script>

<style scoped>
.qf-archived {
  opacity: 0.5;
}
.qf-vote-wrapp .progress {
    margin-bottom: 8px;
}
.qf-aplicant-video {
    max-width: 107px;
    min-width: 107px;
}
.qf-applicant-auditions{
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 319px;
    min-width: 246px;
    padding: 0 15px;
}
.qf-vote-width{
    max-width: 300px;
    min-width: 150px;
}
.qf-counter{
    margin: 0 3px
}
.user-width{
    min-width: 200px;
    width: 200px;
}
</style>
