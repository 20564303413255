<template>
    <div class="h-100 p-relative">
        <section class="chat-app-window qf-chat px-2">
            <div class="active-chat">
                <div class="chat-navbar sticky-top pt-1">
                    <header class="chat-header d-flex justify-content-between mb-2">
                        <div class="d-flex align-items-center">
                            <b-avatar :src="applicantAvatar" :text="applicantInitials" class="mr-1"
                                      size="56px"></b-avatar>
                            <div class="qf-user-details" v-b-toggle.cu-viewProfile @click="viewProfile()">
                                <h5 class="mb-0">{{applicantNames}}</h5>
                                <p class="mb-0"
                                   v-if="applicantAge || applicantGender "
                                >{{applicantAge}} yrs | {{applicantGender}} | {{applicantHeight}} cm</p>
                                <p class="mb-0" v-if="false">Plovdiv, Bulgaria (153km)</p>
                            </div>
                        </div>
<!--                        <div class="d-flex align-items-center">-->
<!--                            <feather-icon @click="vote('up')"-->
<!--                                          icon="ThumbsUpIcon"-->
<!--                                          size="18"-->
<!--                                          :class="thumbClasses('up')"/>-->
<!--                            -->
<!--                            <feather-icon @click="vote('down')"-->
<!--                                          icon="ThumbsDownIcon"-->
<!--                                          size="18"-->
<!--                                          :class="thumbClasses('down')"/>-->
<!--                        -->
<!--                        </div>-->
                    </header>
                    <qf-video v-if="zoomVideo"
                              :image-url="zoomThumb"
                              :video-url="zoomVideo"
                              size="medium"
                              :avatar-url="applicantAvatar"
                              :user-names="applicantNames"
                    />
                    <div v-else>
                        <p>
                            <feather-icon icon="CalendarIcon" class="text-success mr-1" size="18"/> {{niceDate(zoomDate)}}
                        </p>
                        <p>
                            <feather-icon icon="LinkIcon" class="text-success mr-1" size="18"/>
                            <a>{{zoomLink}}</a>
                        </p>
                    </div>
                </div>
                <section class="user-chats scroll-area mt-1 border-top">
                    <div class="chats">
                        <div class="chats">
                            <div v-for="(oneComment, index) in auditionComments"
                                 :key="oneComment.id"
                                 :class="commentClasses(oneComment)">
                                <div class="chat-avatar">
                                    <b-avatar size="36"
                                              v-if="inSameThread(index, auditionComments)"
                                              :src="commenterAvatar(oneComment)"/>
                                </div>
                                <div class="chat-body">
                                    <div class="chat-body-group">
                                        <div v-if="inSameThread(index, auditionComments)" class="chat-body-top">
                                            <div class="time">{{commentTime(oneComment)}}</div>
                                            <div class="name">{{commenterNames(oneComment)}}</div>
                                        </div>
                                        <div class="chat-content" v-html="commentWithLinks(oneComment)"></div>
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                </section>
                <b-form class="chat-app-form" @submit.prevent="addComment">
                    <b-form-input
                            class="mr-1"
                            v-model="chatInputMessage"
                            placeholder="Type your message"
                    />
                    <b-button variant="primary" type="submit">SEND</b-button>
                </b-form>
            </div>
        </section>
    </div>
</template>

<script>
    //import { VueAutosuggest } from 'vue-autosuggest';
    //import ChatLog from "@/layouts/components/ChatLog";
    import QfVideo from "@/layouts/components/QfVideo";
    import ToastificationContent from "@core/components/toastification/ToastificationContent";
    import store from "@/store";
    import Parse from "parse";
    import moment from "moment-timezone";
    import {
        BForm,
        BFormInput,
        BButton,
        BAvatar,
        VBTooltip,
        VBToggle
    } from 'bootstrap-vue'
    export default {
        components: {
            BForm,
            BFormInput,
            BButton,
            BAvatar,
            QfVideo,
        },
        directives: {
            'b-tooltip': VBTooltip,
            'b-toggle': VBToggle
        },
        data() {
            return {
                newRoleId: '',
                chatInputMessage: null,
                liveAuditions: [],
                auditionComments: [],
            }
        },
        computed: {
            selectedAudition(){
                return store.getters['app/currentLiveAudition'];
            },
            applicantAge(){
                if (this.selectedAudition.attributes.relatedUser && this.selectedAudition.attributes.relatedUser.attributes.relatedMeta) {
                    return this.selectedAudition.attributes.relatedUser.attributes.relatedMeta.attributes.age;
                } else {
                    return "";
                }
            },
            applicantGender(){
                if (this.selectedAudition.attributes.relatedUser && this.selectedAudition.attributes.relatedUser.attributes.relatedMeta) {
                    return this.selectedAudition.attributes.relatedUser.attributes.relatedMeta.attributes.gender;
                } else {
                    return "";
                }
            },
            applicantHeight(){
                if (this.selectedAudition.attributes.relatedUser && this.selectedAudition.attributes.relatedUser.attributes.relatedMeta) {
                    return this.selectedAudition.attributes.relatedUser.attributes.relatedMeta.attributes.height;
                } else {
                    return "";
                }
            },
            zoomDate(){
                if (this.selectedAudition.attributes.auditionDate) {
                    return this.selectedAudition.attributes.auditionDate;
                } else {
                    return "";
                }
            },
            zoomVideo(){
                if (this.selectedAudition.attributes.zoomRecording) {
                    return this.selectedAudition.attributes.zoomRecording._url;
                } else {
                    return '';
                }
            },
            zoomThumb() {
                if (this.selectedAudition.attributes.zoomRecordingThumbnail) {
                    return this.selectedAudition.attributes.zoomRecordingThumbnail._url;
                } else {
                    return this.defaultLogo("png");
                }
            },
            zoomLink(){
                if (this.selectedAudition.attributes.zoomLink) {
                    return this.selectedAudition.attributes.zoomLink;
                } else {
                    return '';
                }
            },
            applicationThumb(){
                if (this.selectedAudition.attributes.attachmentThumb) {
                    return this.selectedAudition.attributes.attachmentThumb._url;
                } else {
                    return this.defaultLogo('png');
                }
            },
            applicantNames(){
                if (this.selectedAudition.attributes.relatedUser) {
                    return this.selectedAudition.attributes.relatedUser.attributes.firstName + " " + this.selectedAudition.attributes.relatedUser.attributes.lastName;
                } else {
                    return "";
                }
            },
            applicantInitials(){
                if (this.selectedAudition.attributes.relatedUser) {
                    return this.selectedAudition.attributes.relatedUser.attributes.firstName.substring(0, 1) + this.selectedAudition.attributes.relatedUser.attributes.lastName.substring(0, 1);
                } else {
                    return "";
                }
            },
            applicantAvatar(){
                if (this.selectedAudition.attributes.relatedUser && this.selectedAudition.attributes.relatedUser.attributes.avatar) {
                    return this.selectedAudition.attributes.relatedUser.attributes.avatar._url;
                } else {
                    return "";
                }
            },
        },
        methods: {
            viewProfile(){
                store.commit('app/SET_TALENT', this.selectedAudition.attributes.relatedUser);
                this.profileId = this.selectedAudition.attributes.relatedUser.id;
            },
            niceDate(parseDate) {
                return moment(parseDate).tz('UTC').format("Do MMM HH:mm");
            },
            cancelModal() {
                this.$root.$emit('bv::toggle::collapse', 'liveAudition');
            },
            addComment(){
                if (this.chatInputMessage) {
                    let self = this;
                    let currentUser = Parse.User.current();

                    let commentObj = Parse.Object.extend('Comment');
                    let newComment = new commentObj();

                    const liveAuditionPointer = {
                        "__type": "Pointer",
                        "className": "LiveAudition",
                        "objectId": this.selectedAudition.id
                    };
                    const userPointer = {"__type": "Pointer", "className": "_User", "objectId": currentUser.id};
                    
                    newComment.set('relatedLiveAudition', liveAuditionPointer);
                    newComment.set('relatedUser', userPointer);
                    newComment.set('content', this.chatInputMessage);

                    newComment.save().then(function (createdComment) {

                        let commentQuery = new Parse.Query(createdComment);
                        commentQuery.include('relatedUser');
                        commentQuery.get(createdComment.id).then(function (createdComment) {
                            self.auditionComments.push(createdComment);
                            self.chatInputMessage = null;
                        });
                    }).catch(function (err) {
                        self.$toast({
                            component: ToastificationContent,
                            props: {
                                title: err.message,
                                icon: 'XIcon',
                                variant: 'danger',
                            },
                        });
                    });

                }
            },
            inSameThread(index, allComments){
                if (index && allComments[index - 1].attributes.relatedUser.id === allComments[index].attributes.relatedUser.id) {
                    return false;
                } else {
                    return true;
                }
            },
            commentWithLinks(commentObj){
                let text = commentObj.attributes.content;
                var urlRegex = /(https?:\/\/[^\s]+)/g;
                return text.replace(urlRegex, function (url) {
                    return '<a target="_blank" href="' + url + '">' + url + '</a>';
                })
            },
            commentClasses(commentObj){
                let currentUser = Parse.User.current();
                if (commentObj.attributes.relatedUser.id === currentUser.id) {
                    return "chat";
                } else {
                    return "chat chat-left"
                }
            },
            commenterNames(commentObj){
                let currentUser = Parse.User.current();
                if (commentObj.attributes.relatedUser) {
                    if (commentObj.attributes.relatedUser.id === currentUser.id) {
                        return 'You';
                    } else {
                        return commentObj.attributes.relatedUser.attributes.firstName + " " + commentObj.attributes.relatedUser.attributes.lastName;
                    }
                } else {
                    return "";
                }
            },
            commenterAvatar(commentObj){
                if (commentObj.attributes.relatedUser && commentObj.attributes.relatedUser.attributes.avatar) {
                    return commentObj.attributes.relatedUser.attributes.avatar._url;
                } else {
                    return "";
                }
            },
            commentTime(commentObj){
                return this.niceDate(commentObj.createdAt);
            },
            onInputChange(text) {
                if (text === '' || text === undefined) {
                    return
                }
            },
        },
        watch: {
            selectedAudition: {
                immediate: true,
                deep: true,
                handler(newValue){
                    if (newValue.id) {
                        let self = this;

                        let curRole = store.getters['app/currentRole'];
                        let allLive = store.getters['app/allLiveAuditions'];
                        this.liveAuditions = allLive[curRole.id];
                        let commentObj = Parse.Object.extend('Comment');
                        let commentQuery = new Parse.Query(commentObj);
                        const applicationPointer = {
                            "__type": "Pointer",
                            "className": "LiveAudition",
                            "objectId": newValue.id
                        };
                        commentQuery.equalTo('relatedLiveAudition', applicationPointer);
                        commentQuery.include('relatedUser');
                        commentQuery.ascending("createdAt");
                        commentQuery.find().then(function (comments) {
                            self.auditionComments = comments;
                        }).catch(function (err) {
                            console.log("Comments query", err.message);
                        });
                        
                        
                    }
                }
            }
        }
    }
</script>

<style>
    .thumbActive {
        opacity: 0.5;
    }
    .thumbInactive {
        opacity: 1;
    }
    .chat-navbar {
        background: none;
    }
    
    .chat-body .chat-content a {
        color: #FFFFFF;
        text-decoration: underline;
    }
</style>
