var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"talentValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-modal',{ref:"invite-modal",attrs:{"id":_vm.modalId,"ok-title":_vm.okTitle,"title":"Add Talent to Audition"},on:{"ok":[_vm.submitModal,function($event){$event.preventDefault();return passes()}],"hidden":_vm.resetModal,"shown":_vm.getTalentSources}},[_c('b-overlay',{staticClass:"stretch",attrs:{"variant":"dark","show":_vm.showSpinner,"spinner-variant":"light","blur":"5px","opacity":".85","rounded":"sm"}},[_c('b-row',{staticClass:"pb-1"},[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"first name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("First name "),_c('sup',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"name":"firstName","id":"firstName","placeholder":"Talented","type":"text"},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Last name "),_c('sup',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"name":"lastName","id":"lastName","placeholder":"Person","type":"text"},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(_vm.isNeededEmail)?_c('b-row',{staticClass:"pb-1"},[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Email "),_c('sup',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"name":"email","id":"email","placeholder":"email@mailserver.com","type":"text"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),_c('b-row',{staticClass:"pb-1"},[_c('b-col',{attrs:{"cols":"8"}},[(_vm.isNeededEmail)?_c('label',[_vm._v("Talent Source (optional)")]):_c('label',[_vm._v("Talent Source "),_c('sup',{staticClass:"text-danger"},[_vm._v("*")])]),_c('Select2',{ref:"sourcePicker",staticClass:"mb-1",attrs:{"id":"talentSource","placeholder":"Name of agent, manager, etc.","options":_vm.talentSourceChoices,"settings":{
                            multiple: true,
                            tags: true,
                            //tags: true,
                            //templateResult: companyPreview,
                            //templateSelection: selectionPreview,
                            //escapeMarkup: function(stuff){return stuff}
                         }},on:{"select":_vm.selectSource},model:{value:(_vm.form.talentSourceArr),callback:function ($$v) {_vm.$set(_vm.form, "talentSourceArr", $$v)},expression:"form.talentSourceArr"}}),(false)?_c('validation-provider',{attrs:{"name":"talentSource","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                         var errors = ref.errors;
return [_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e()],1),_c('b-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":"talentSource","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                         var errors = ref.errors;
return [(_vm.isNeededEmail)?_c('label',[_vm._v("Talent ID (optional)")]):_c('label',[_vm._v("Suggested Talent ID")]),_c('b-form-input',{attrs:{"name":"talentSource","id":"talentSource","type":"text"},model:{value:(_vm.form.sourceID),callback:function ($$v) {_vm.$set(_vm.form, "sourceID", $$v)},expression:"form.sourceID"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"pb-1"},[(_vm.isNeededEmail)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-checkbox',{staticClass:"mr-0 mt-50",attrs:{"name":"is-rtl","inline":"","disabled":_vm.form.dummyProfile},model:{value:(_vm.form.inviteToRole),callback:function ($$v) {_vm.$set(_vm.form, "inviteToRole", $$v)},expression:"form.inviteToRole"}},[_vm._v("Send e-mail to audition for this role")])],1):_vm._e()],1),_c('b-row',{staticClass:"pb-1"},[_c('b-col',{attrs:{"md":"12"}},[(!_vm.hasSides && _vm.form.inviteToRole)?_c('b-alert',{attrs:{"variant":"danger","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_c('strong',[_vm._v("This role does not have any sides!")])])])]):_vm._e()],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }