<template>
  <div>
    <b-card no-body class="overflow-hidden px-2">
      <b-row
        class="d-flex align-items-center border-bottom py-2"
        v-for="(liveAudition, index) in liveAuditions"
        :key="index"
      >
        <b-col md="2">
          <div
            class="d-flex align-items-center qf-user-details-wrapp"
            v-b-toggle.liveAudition
            @click="setActiveLiveAudition(liveAudition)"
          >
            <b-avatar
              class="mr-1"
              size="56px"
              :src="applicantAvatar(liveAudition)"
              :text="initials(liveAudition)"
            >
            </b-avatar>
            <div class="qf-user-details">
              <h5 class="mb-0">
                {{ liveAudition.attributes.relatedUser.attributes.firstName }}
                {{ liveAudition.attributes.relatedUser.attributes.lastName }}
              </h5>
            </div>
          </div>
        </b-col>
        <b-col md="3">
          <div class="flex-grow-1 d-flex flex-column align-items-center">
            <p class="mb-0"><b>Scheduled meeting:</b></p>
            <p class="mb-0">
              {{ niceDate(liveAudition.attributes.auditionDate) }}
            </p>
          </div>
        </b-col>
        <b-col md="3" v-if="liveAudition.attributes.zoomRecording">
          <div class="qf-aplicant-video d-flex align-items-center">
            <qf-video
              :image-url="applicationThumb(liveAudition)"
              :video-url="applicantVideo(liveAudition)"
              :avatar-url="applicantAvatar(liveAudition)"
              :user-names="
                liveAudition.attributes.relatedUser.attributes.firstName +
                ' ' +
                liveAudition.attributes.relatedUser.attributes.lastName
              "
              size="small"
            />
          </div>
        </b-col>
        <b-col v-else md="3">
          <div
            v-if="dateComparison(liveAudition.attributes.auditionDate) < 0"
            class="flex-grow-1 d-flex flex-column align-items-center"
          >
              <p class="mb-0">
                  <a :href="liveAudition.attributes.zoomLink" target="_blank" rel="nofollow"><b>Zoom link</b></a>
              </p>
          </div>
          <div v-else class="flex-grow-1 d-flex flex-column align-items-center">
            No recording yet
          </div>
        </b-col>
        <b-col md="2">
          <div class="qf-vote-wrapp user-details-widthml-5 qf-vote-width">
            <div class="qf-positive-vote-wrapp d-flex">
              <feather-icon
                icon="ThumbsUpIcon"
                size="18"
                @click="vote('up', liveAudition)"
                :class="thumbClasses('up', liveAudition)"
              />
              <span class="qf-counter mb-0">{{ votesFor(liveAudition) }}</span>
            </div>
            <div class="qf-negative-vote-wrapp d-flex">
              <span class="qf-counter mb-0 mr-05">{{
                votesAgainst(liveAudition)
              }}</span>
              <feather-icon
                icon="ThumbsDownIcon"
                size="18"
                @click="vote('down', liveAudition)"
                :class="thumbClasses('down', liveAudition)"
              />
            </div>
            <b-progress class="mt-2" max="100">
              <b-progress-bar :value="100 * (votesForCoefficient(liveAudition))" variant="success"></b-progress-bar>
              <b-progress-bar :value="100 * (votesAgainstCoefficient(liveAudition))" variant="danger"></b-progress-bar>
            </b-progress>
            <div class="qf-positive-vote-wrapp">
              <b-avatar-group
                size="20px"
                class="d-flex"
                v-if="liveAudition.attributes.votedFor"
              >
                <b-avatar
                  v-for="voter in liveAudition.attributes.votedFor"
                  :key="voter.id"
                  v-b-tooltip.hover
                  class="pull-up"
                  :title="
                    voter.attributes.firstName + ' ' + voter.attributes.lastName
                  "
                  :src="voterAvatar(voter)"
                ></b-avatar>
              </b-avatar-group>
            </div>
            <div class="qf-negative-vote-wrapp">
              <b-avatar-group
                class="d-flex justify-content-end"
                size="20px"
                v-if="liveAudition.attributes.votedAgainst"
              >
                <b-avatar
                  v-for="voter in liveAudition.attributes.votedAgainst"
                  :key="voter.id"
                  v-b-tooltip.hover
                  class="pull-up"
                  :title="
                    voter.attributes.firstName + ' ' + voter.attributes.lastName
                  "
                  :src="voterAvatar(voter)"
                ></b-avatar>
              </b-avatar-group>
            </div>
          </div>
        </b-col>
        <b-col md="1">
          <div class="d-flex justify-content-end">
            <div
              @click="talentPick(liveAudition)"
              class="cursor-pointer select-crown"
              :class="alreadyPicked(liveAudition) ? 'selected-role' : null"
            >
              <b-img height="12" :src="icons.crown"></b-img>
            </div>
          </div>
        </b-col>
        <b-col md="1">
          <div class="d-flex justify-content-end mr-1">
            <b-button
              variant="outline-success"
              size="sm"
              class="btn-icon rounded-circle"
            >
              <feather-icon
                icon="XIcon"
                v-b-modal.delete-live-audition-modal
                @click="openLocationModal(liveAudition)"
                class="text-success"
              ></feather-icon>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-sidebar
      id="liveAudition"
      right
      backdrop
      shadow
      width="450px"
      sidebar-class="sidebar-lg"
      bg-variant="white"
    >
      <template #header="{ hide }">
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            w-100
          "
        >
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
      </template>
      <modal-live-audition></modal-live-audition>
    </b-sidebar>
    <b-modal
      id="delete-live-audition-modal"
      ok-variant="danger"
      ok-title="Delete"
      cancel-title="Close"
      cancel-variant="outline-secondary"
      modal-class="modal-danger"
      size="sm"
      button-size="sm"
      centered
      title="Delete Live Audition"
      @ok="removeLiveAudition(auditionToDelete)"
    >
      <b-card-text>
        Are you sure you want to delete
        {{ auditionToDelete.attributes.relatedUser.attributes.firstName }}
        {{ auditionToDelete.attributes.relatedUser.attributes.lastName }}'s live
        audition?
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment-timezone";
import QfVideo from "../layouts/components/QfVideo";
import ModalLiveAudition from "@/modals/ModalLiveAudition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
  BCardText,
  BCard,
  BImg,
  BCol,
  BRow,
  BAvatar,
  BAvatarGroup,
  BProgress,
  BProgressBar,
  //BBadge,
  BButton,
  BSidebar,
  VBToggle,
  VBTooltip,
} from "bootstrap-vue";
import store from "@/store";
import Parse from "parse";

export default {
  components: {
    BCardText,
    BCard,
    BImg,
    BCol,
    BRow,
    BAvatar,
    BAvatarGroup,
    BProgress,
    BProgressBar,
    //BBadge,
    BButton,
    BSidebar,
    ModalLiveAudition,
    QfVideo,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      votesAgainstRatio: 0,
      votesForRatio: 0,
      activeProduction: {
        attributes: {},
      },
      auditionToDelete: {
        attributes: {
          relatedUser: {
            attributes: {},
          },
        },
      },
      roleId: "",
      icons: {
        crown: require("@/assets/images/icons/crown.svg"),
      },
    };
  },
  props: {},

  mounted() {
    this.roleId = this.$route.params.roleId;
    this.getLiveAuditions(this.roleId);
  },

  computed: {
    liveAuditions() {
      return store.getters["app/allLiveAuditions"];
    },
    curProduction() {
      return store.getters["app/currentProduction"];
    },
    curRole() {
      return store.getters["app/currentRole"];
    },
    curLiveAudition() {
      return store.getters["app/currentLiveAudition"];
    },
  },
  methods: {
    alreadyVoted(selectedLiveAudition) {
      let curUser = Parse.User.current();
      let voteUp = selectedLiveAudition.attributes.votedFor
      if (selectedLiveAudition.attributes.votedFor && selectedLiveAudition.attributes.votedFor.length && selectedLiveAudition.attributes.votedFor.length > 0 && voteUp.find((obj) => {
        return obj.id === curUser.id;
      })) {

        if (voteUp) {
          return "up";
        } else {
          return false;
        }
      }
      else if (selectedLiveAudition.attributes.votedAgainst && selectedLiveAudition.attributes.votedAgainst.length && selectedLiveAudition.attributes.votedAgainst.length > 0) {
        let voteAgainst = selectedLiveAudition.attributes.votedAgainst.find(
            (obj) => {
              return obj.id === curUser.id;
            }
        );
        if (voteAgainst) {
          return "down";
        } else {
          return false;
        }
      }
      else {
        return false;
      }
    },
    thumbClasses(thumbDirection, selectedLiveAudition) {
      if (thumbDirection === this.alreadyVoted(selectedLiveAudition) && this.alreadyVoted(selectedLiveAudition) === 'up') {
        return "text-success mr-05 cursor-pointer";
      }
      else if (thumbDirection === this.alreadyVoted(selectedLiveAudition) && this.alreadyVoted(selectedLiveAudition) === 'down') {
        return "text-danger mr-05 cursor-pointer";
      }
      else {
        return "text-secondary mr-05 cursor-pointer";
      }
    },
    vote(voteType, selectedLiveAudition) {
      store.commit('app/SET_LIVE_AUDITION', selectedLiveAudition);
      let curUser = Parse.User.current();
      if (this.alreadyVoted(selectedLiveAudition)) {
        selectedLiveAudition.remove("votedAgainst", curUser);
        selectedLiveAudition.remove("votedFor", curUser);
      }
      else {
        if (voteType === "down" && this.alreadyVoted(selectedLiveAudition) !== "down") {
          selectedLiveAudition.remove("votedFor", curUser);
          selectedLiveAudition.addUnique("votedAgainst", curUser);
        }
        else if (voteType === "up" && this.alreadyVoted(selectedLiveAudition) !== "up") {
          selectedLiveAudition.remove("votedAgainst", curUser);
          selectedLiveAudition.addUnique("votedFor", curUser);
        }
      }

      selectedLiveAudition
          .save()
          .then(function (updatedApplication) {
            store.commit("app/SET_LIVE_AUDITION", updatedApplication);
            store.commit("app/UPDATE_LIVE_AUDITIONS", updatedApplication);
            console.log("Updated live audition: ", updatedApplication);
          })
          .catch(function (err) {
            console.log("err on save", err);
          });
    },
    voterAvatar(voter){
      if(voter.attributes.avatar){
        return voter.attributes.avatar._url;
      }
      else{
        return this.defaultLogo('png');
      }
    },
    openLocationModal(liveAudition) {
      this.auditionToDelete = liveAudition;
    },
    removeLiveAudition(liveAudition) {
      liveAudition
        .destroy()
        .then(function (deletedLiveAudition) {
          console.log(deletedLiveAudition);
          store.commit("app/REMOVE_LIVE_AUDITION", liveAudition);
        })
        .catch(function (err) {
          console.log("Parse error", err.message);
        });
    },
    alreadyPicked(liveAudition) {
      if (this.curRole.attributes.talentPick) {
        let talentId = this.curRole.attributes.talentPick.id
          ? this.curRole.attributes.talentPick.id
          : this.curRole.attributes.talentPick.objectId;
        if (talentId === liveAudition.attributes.relatedUser.id) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    talentPick(liveAudition) {
      let self = this;
      const nomineePointer = {
        __type: "Pointer",
        className: "_User",
        objectId: liveAudition.attributes.relatedUser.id,
      };

      this.curRole.set("talentPick", nomineePointer);

      this.curRole
        .save()
        .then(function (updatedRole) {
          console.log("Talent picked", updatedRole);
          store.commit("app/SET_ROLE", updatedRole);
          store.commit("app/UPDATE_ROLE", updatedRole);
          store.commit("app/UPDATE_LIVE_AUDITIONS", liveAudition);
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "The actor was successfully chosen for the role!",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch(function (err) {
          console.log("Save error: ", err.message);
        });
    },
    dateComparison(auditionDate) {
      return moment(auditionDate).diff(moment());
      // return moment().diff(moment(auditionDate));
    },
    setActiveLiveAudition(audition) {
      store.commit("app/SET_LIVE_AUDITION", audition);
    },
    votesForCoefficient(liveAudition) {
      if (liveAudition.attributes.votedFor && liveAudition.attributes.votedFor.length) {
        let voteForCoefficient = liveAudition.attributes.votedFor.length;
        return voteForCoefficient;
      }
      else {
        return 0;
      }
    },
    votesAgainstCoefficient(liveAudition) {
      if(liveAudition.attributes.votedAgainst && liveAudition.attributes.votedAgainst.length) {
        let voteAgainstCoefficient = liveAudition.attributes.votedAgainst.length;
        return voteAgainstCoefficient;
      }
      else {
        return 0;
      }
    },
    votesFor(liveAudition) {
      let votesFor = 0;
      if (liveAudition.attributes.votedFor) {
        votesFor = liveAudition.attributes.votedFor.length;
        return votesFor;
      } else {
        return "0";
      }
    },
    votesAgainst(liveAudition) {
      let votesAgainst = 0;
      if (liveAudition.attributes.votedAgainst) {
        votesAgainst = liveAudition.attributes.votedAgainst.length;
        return votesAgainst;
      } else {
        return "0";
      }
    },
    applicantAvatar(liveAudition) {
      if (
        liveAudition.attributes.relatedUser &&
        liveAudition.attributes.relatedUser.attributes.avatar
      ) {
        return liveAudition.attributes.relatedUser.attributes.avatar._url;
      } else {
        return "";
      }
    },
    initials(liveAudition) {
      if (liveAudition.attributes.relatedUser.attributes.firstName) {
        return (
          liveAudition.attributes.relatedUser.attributes.firstName.substring(
            0,
            1
          ) +
          liveAudition.attributes.relatedUser.attributes.lastName.substring(
            0,
            1
          )
        );
      } else {
        return "";
      }
    },
    niceDate(parseDate) {
      return moment(parseDate).tz("UTC").format("Do MMM HH:mm");
    },
    getLiveAuditions() {
      const rolePointer = {
        __type: "Pointer",
        className: "ActingRole",
        objectId: this.roleId,
      };

      let LiveAudition = Parse.Object.extend("LiveAudition");
      let liveAuditionQuery = new Parse.Query(LiveAudition);

      liveAuditionQuery.equalTo("relatedRole", rolePointer);
      liveAuditionQuery.include("relatedUser.relatedMeta");

      liveAuditionQuery
        .find()
        .then(function (result) {
          store.commit("app/SET_LIVE_AUDITIONS", result);
        })
        .catch(function (err) {
          console.log("Live Audition query error: ", err.message);
        });
    },
    applicantVideo(audition) {
      if (
        audition.attributes.relatedUser &&
        audition.attributes.zoomRecording
      ) {
        return audition.attributes.zoomRecording._url;
      } else {
        return "";
      }
    },
    applicationThumb(audition) {
      if (audition.attributes.zoomRecordingThumbnail) {
        return audition.attributes.zoomRecordingThumbnail._url;
      } else {
        return this.defaultLogo("png");
      }
    },
  },
  watch: {
    curProduction: {
      immediate: true,
      handler(newVal) {
        this.activeProduction = newVal;
        if (newVal) {
          this.getLiveAuditions(newVal.id);
        }
      },
    },
    curRole: {
      immediate: true,
      handler(newValue) {
        this.activeRole = newValue;
        //                    if(!newValue){
        //                        this.activeRole.id = this.roleId;
        //                    }
        this.activeRole.id = newValue.id;
      },
    },
  },
};
</script>

<style scoped>
@media (min-width: 768px) {
  .small-video-wrapper {
    max-width: 135px;
  }
}
/*.progress-bar.bg-danger {*/
/*  width: 100%;*/
/*  position: absolute;*/
/*  height: 3px;*/
/*}*/
/*.progress-bar.bg-success {*/
/*  z-index: 1;*/
/*  position: absolute;*/
/*  height: 3px;*/
/*}*/
.qf-vote-wrapp .progress {
  /*position: relative;*/
  margin-bottom: 8px;
}
.qf-aplicant-video {
  max-width: 107px;
  min-width: 107px;
}
.qf-user-details-wrapp {
  min-width: 100px;
}
.qf-vote-wrapp .progress {
  margin-bottom: 8px;
  margin-top: 2px;
}
.select-crown {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #82868b;
  border-radius: 50%;
  border: 3px solid #273045;
}
.selected-role {
  background: linear-gradient(30deg, #ff4d00 0%, #ff9f43 100%);
}
</style>
