<template>
    <div class="h-100 p-relative">
        <section class="chat-app-window qf-chat px-2">
            <div class="active-chat">
                <div class="chat-navbar sticky-top pt-1">
                    <header class="chat-header d-flex justify-content-between mb-1">
                        <div
                            class="d-flex align-items-center"
                            v-b-toggle.cu-viewProfile
                            @click="viewProfile()"
                        >
                            <b-avatar
                                :src="applicantAvatar"
                                :text="applicantInitials"
                                class="mr-1"
                                size="56px"
                            ></b-avatar>
                            <div class="qf-user-details">
                                <h5 class="mb-0 text-primary">{{ applicantNames }}</h5>
                                <p class="font-small-4">{{applicantEmail}}</p>
                                <p
                                    class="mb-0"
                                    v-if="applicantAge || applicantGender || applicantHeight"
                                >
                                    {{ applicantAge }} yrs | {{ applicantGender }} |
                                    {{ applicantHeight }} cm
                                </p>
                                <p class="mb-0" v-if="false">Plovdiv, Bulgaria (153km)</p>
                            </div>
                        </div>
                        <!--            <div class="d-flex align-items-center">-->
                        <!--              <feather-icon-->
                        <!--                v-if="alreadyShortlisted && alreadyScheduled"-->
                        <!--                icon="CalendarIcon"-->
                        <!--                v-b-tooltip.hover-->
                        <!--                size="18"-->
                        <!--                title="Already scheduled!"-->
                        <!--                class="calendarInactive text-success mr-05 cursor-pointer"-->
                        <!--              />-->
                        <!--              <feather-icon-->
                        <!--                v-if="alreadyShortlisted && !alreadyScheduled"-->
                        <!--                icon="CalendarIcon"-->
                        <!--                v-b-tooltip.hover-->
                        <!--                v-b-modal.sidebar-schedule-->
                        <!--                size="18"-->
                        <!--                title="Schedule live audition"-->
                        <!--                class="text-primary mr-05 cursor-pointer"-->
                        <!--              />-->
                        <!--              <feather-icon-->
                        <!--                v-if="!alreadyShortlisted"-->
                        <!--                icon="RepeatIcon"-->
                        <!--                v-b-tooltip.hover-->
                        <!--                v-b-modal.sidebar-recast-->
                        <!--                size="18"-->
                        <!--                title="Recast for another role"-->
                        <!--                class="text-primary mr-05 cursor-pointer"-->
                        <!--              />-->
                        <!--            </div>-->
                    </header>
                    <div
                        v-if="alreadyScheduled"
                        class="
              d-flex
              justify-content-between
              align-items-center
              border-top border-bottom
              py-1
            "
                    >
                        Scheduled for:
                        {{ niceDate(this.liveAudition.attributes.auditionDate) }}
                        <!--            <feather-icon-->
                        <!--              icon="EditIcon"-->
                        <!--              v-b-tooltip.hover-->
                        <!--              v-b-modal.sidebar-schedule-->
                        <!--              size="18"-->
                        <!--              title="Reschedule live audition"-->
                        <!--              class="text-success"-->
                        <!--            ></feather-icon>-->
                    </div>
                    <qf-video
                        :image-url="applicationThumb"
                        :video-url="applicationVideo"
                        size="medium"
                        :avatar-url="applicantAvatar"
                        :user-names="applicantNames"
                    />
                </div>
                <section class="user-chats scroll-area mt-1 border-top">
                    <div class="text-center pt-2">
                        <h4>Team Comments</h4>
                        <p class="font-small-3">Visible to the production team only!</p>
                    </div>
                    <div class="chats border-top">
                        <div class="chats">
                            <div
                                v-for="(oneComment, index) in auditionComments"
                                :key="oneComment.id"
                                :class="commentClasses(oneComment)"
                            >
                                <div class="chat-avatar">
                                    <b-avatar
                                        size="36"
                                        v-if="inSameThread(index, auditionComments)"
                                        :src="commenterAvatar(oneComment)"
                                    />
                                </div>
                                <div class="chat-body">
                                    <div class="chat-body-group">
                                        <div
                                            v-if="inSameThread(index, auditionComments)"
                                            class="chat-body-top"
                                        >
                                            <div class="time">{{ commentTime(oneComment) }}</div>
                                            <div class="name">{{ commenterNames(oneComment) }}</div>
                                        </div>
                                        <div class="chat-content">
                                            <span class="content-text" v-html="commentWithLinks(oneComment)"></span>
                                            <span v-if="ownComment(oneComment)" class="deleteComment" >
                            <feather-icon icon="XIcon" @click="deleteComment(oneComment)"></feather-icon>
                        </span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <b-form class="chat-app-form" @submit.prevent="addComment">
                    <b-form-input
                        class="mr-1"
                        v-model="chatInputMessage"
                        placeholder="Leave your comment"
                    />
                    <b-button variant="primary" type="submit">SAVE</b-button>
                </b-form>
            </div>
        </section>
    </div>
</template>

<script>
import QfVideo from "@/layouts/components/QfVideo";
import store from "@/store";
import Parse from "parse";
import moment from "moment-timezone";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
    BForm,
    BFormInput,
    BButton,
    BAvatar,
    VBTooltip,
    VBToggle,
} from "bootstrap-vue";
export default {
    components: {
        BForm,
        BFormInput,
        BButton,
        BAvatar,
        QfVideo,
    },
    directives: {
        "b-tooltip": VBTooltip,
        "b-toggle": VBToggle,
    },
    data() {
        return {
            required,
            profileId: null,
            newRoleId: "",
            chatInputMessage: null,
            auditionComments: [],
            shortlistedForRole: [],
            scheduledForAudition: [],
            auditionUserPick: null,
        };
    },
    created() {
        // this.$http.get('/autosuggest/data')
        //     .then(res => { this.skillSuggest = res })
    },
    mounted() {

    },
    computed: {
        curRole() {
            return store.getters["app/currentRole"];
        },
        zoomName(){
            return `Live Audition: `;
        },
        liveAudition() {
            if (
                this.liveAuditions &&
                this.selectedAudition.attributes &&
                this.selectedAudition.attributes.relatedUser
            ) {
                return this.liveAuditions.find(
                    (o) =>
                        o.attributes.relatedUser.id ===
                        this.selectedAudition.attributes.relatedUser.id
                );
            } else {
                return {};
            }
        },
        saveLabel() {
            if (this.liveAudition) {
                return "Reschedule";
            } else {
                return "Schedule";
            }
        },
        selectedAudition() {
            return store.getters["app/currentAudition"];
        },
        liveAuditions() {
            return store.getters["app/allLiveAuditions"];
        },
        alreadyScheduled() {
            if (
                this.selectedAudition.attributes.relatedUser &&
                this.liveAuditions.find(
                    (oneLiveAudition) =>
                        oneLiveAudition.attributes.relatedUser.id ===
                        this.selectedAudition.attributes.relatedUser.id
                )
            ) {
                return true;
            } else {
                return false;
            }
        },
        alreadyShortlisted() {
            if (
                this.selectedAudition.attributes.relatedUser &&
                this.shortlistedForRole.find(
                    (o) => o.id === this.selectedAudition.attributes.relatedUser.id
                )
            ) {
                return true;
            } else {
                return false;
            }
        },
        applicantAge() {
            if (
                this.selectedAudition.attributes.relatedUser &&
                this.selectedAudition.attributes.relatedUser.attributes.relatedMeta
            ) {
                return this.selectedAudition.attributes.relatedUser.attributes
                    .relatedMeta.attributes.age;
            } else {
                return "";
            }
        },
        applicantHeight() {
            if (
                this.selectedAudition.attributes.relatedUser &&
                this.selectedAudition.attributes.relatedUser.attributes.relatedMeta
            ) {
                return this.selectedAudition.attributes.relatedUser.attributes
                    .relatedMeta.attributes.height;
            } else {
                return "";
            }
        },
        applicantGender() {
            if (
                this.selectedAudition.attributes.relatedUser &&
                this.selectedAudition.attributes.relatedUser.attributes.relatedMeta
            ) {
                return this.selectedAudition.attributes.relatedUser.attributes
                    .relatedMeta.attributes.gender;
            } else {
                return "";
            }
        },
        applicationVideo() {
            if (this.selectedAudition.attributes.auditionFile) {
                return this.selectedAudition.attributes.auditionFile._url;
            } else {
                return "";
            }
        },
        applicationThumb() {
            if (this.selectedAudition.attributes.attachmentThumb) {
                return this.selectedAudition.attributes.attachmentThumb._url;
            } else {
                return this.defaultLogo("png");
            }
        },
        applicantNames() {
            if (this.selectedAudition.attributes.relatedUser) {
                return (
                    this.selectedAudition.attributes.relatedUser.attributes.firstName +
                    " " +
                    this.selectedAudition.attributes.relatedUser.attributes.lastName
                );
            } else {
                return "";
            }
        },
        applicantEmail() {
            if (this.selectedAudition.attributes.relatedUser) {
                return this.selectedAudition.attributes.relatedUser.attributes.username;
            } else {
                return "";
            }
        },
        applicantInitials() {
            if (this.selectedAudition.attributes.relatedUser) {
                return (
                    this.selectedAudition.attributes.relatedUser.attributes.firstName.substring(
                        0,
                        1
                    ) +
                    this.selectedAudition.attributes.relatedUser.attributes.lastName.substring(
                        0,
                        1
                    )
                );
            } else {
                return "";
            }
        },
        applicantAvatar() {
            if (
                this.selectedAudition.attributes.relatedUser &&
                this.selectedAudition.attributes.relatedUser.attributes.avatar
            ) {
                return this.selectedAudition.attributes.relatedUser.attributes.avatar
                    ._url;
            } else {
                return "";
            }
        },
    },
    methods: {

        fetchComments(audition){
            let self = this;
            let allShortlisted = store.getters["app/shortlistedTalents"];
            //let allLiveAuditions = store.getters['app/allLiveAuditions'];
            this.shortlistedForRole = allShortlisted[this.curRole.id];
            //this.scheduledForAudition = this.liveAuditions[curRole.id];

            let commentObj = Parse.Object.extend("Comment");
            let commentQuery = new Parse.Query(commentObj);
            const applicationPointer = {
                __type: "Pointer",
                className: "Application",
                objectId: audition.id,
            };
            commentQuery.equalTo("relatedApplication", applicationPointer);
            commentQuery.include("relatedUser");
            commentQuery.ascending("createdAt");
            commentQuery
                .find()
                .then(function (comments) {
                    self.auditionComments = comments;
                })
                .catch(function (err) {
                    console.log("Comments query", err.message);
                });
        },
        deleteComment(comment){
            debugger;
            comment.destroy().then(()=>{
                this.fetchComments(this.selectedAudition);
            })
        },
        ownComment(comment){
            let curUser = Parse.User.current();
            if(comment.attributes.relatedUser.id === curUser.id){
                return true;
            }else{
                return false;
            }
        },
        viewProfile() {
            store.commit(
                "app/SET_TALENT",
                this.selectedAudition.attributes.relatedUser
            );
            this.profileId = this.selectedAudition.attributes.relatedUser.id;
        },
        cancelModal() {
            this.$root.$emit("bv::toggle::collapse", "reviewAudition");
        },
        addComment() {
            if (this.chatInputMessage) {
                let self = this;
                let currentUser = Parse.User.current();

                let commentObj = Parse.Object.extend("Comment");
                let newComment = new commentObj();

                const applicationPointer = {
                    __type: "Pointer",
                    className: "Application",
                    objectId: this.selectedAudition.id,
                };
                const userPointer = {
                    __type: "Pointer",
                    className: "_User",
                    objectId: currentUser.id,
                };

                newComment.set("relatedApplication", applicationPointer);
                newComment.set("relatedUser", userPointer);
                newComment.set("content", this.chatInputMessage);

                newComment
                    .save()
                    .then(function (createdComment) {
                        let commentQuery = new Parse.Query(createdComment);
                        commentQuery.include("relatedUser");
                        commentQuery.get(createdComment.id).then(function (createdComment) {
                            self.auditionComments.push(createdComment);
                            self.chatInputMessage = null;
                        });
                    })
                    .catch(function (err) {
                        self.$toast({
                            component: ToastificationContent,
                            props: {
                                title: err.message,
                                icon: "XIcon",
                                variant: "danger",
                            },
                        });
                    });
            }
        },
        inSameThread(index, allComments) {
            if (index && allComments[index - 1].attributes.relatedUser.id ===
                allComments[index].attributes.relatedUser.id
            ) {
                return false;
            } else {
                return true;
            }
        },
        commentWithLinks(commentObj) {
            let text = commentObj.attributes.content;
            let urlRegex = /(https?:\/\/[^\s]+)/g;
            let textWihLinks = text.replace(urlRegex, function (url) {
                return '<a target="_blank" href="' + url + '">' + url + "</a>";
            });
            return textWihLinks;
        },
        commentClasses(commentObj) {
            let currentUser = Parse.User.current();
            if (commentObj.attributes.relatedUser.id === currentUser.id) {
                return "chat";
            } else {
                return "chat chat-left";
            }
        },
        commenterNames(commentObj) {
            let currentUser = Parse.User.current();
            if (commentObj.attributes.relatedUser) {
                if (commentObj.attributes.relatedUser.id === currentUser.id) {
                    return "You";
                } else {
                    return (
                        commentObj.attributes.relatedUser.attributes.firstName +
                        " " +
                        commentObj.attributes.relatedUser.attributes.lastName
                    );
                }
            } else {
                return "";
            }
        },
        commenterAvatar(commentObj) {
            if (
                commentObj.attributes.relatedUser &&
                commentObj.attributes.relatedUser.attributes.avatar
            ) {
                return commentObj.attributes.relatedUser.attributes.avatar._url;
            } else {
                return "";
            }
        },
        niceDate(parseDate) {
            return moment(parseDate).tz("UTC").format("Do MMM HH:mm");
        },
        commentTime(commentObj) {
            return this.niceDate(commentObj.createdAt);
        },
        onInputChange(text) {
            if (text === "" || text === undefined) {
                return;
            }
            //
            // /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
            // const filteredDevelopers = this.skillSuggest.data[0].developers.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
            // const filteredDesigner = this.skillSuggest.data[0].designers.filter(item => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
            // const filteredData = filteredDevelopers.concat(filteredDesigner)
            // this.filteredOptions = [{
            //     data: filteredData,
            // }]
        },
    },
    watch: {
        selectedAudition: {
            immediate: true,
            deep: true,
            handler(newValue) {
                if (newValue.id) {
                    this.fetchComments(newValue);
                }
            },
        },
    },
};
</script>

<style>

.deleteComment {
    float: right;
    display: flex;
    vertical-align: middle;
    height: 10px;
    align-items: center;
    cursor: pointer;
}
.calendarInactive {
    opacity: 0.5;
}
.chat-navbar {
    background: none;
}

.chat-body .chat-content a {
    color: #ffffff;
    text-decoration: underline;
}
</style>
